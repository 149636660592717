<template>
  <div>
      <p class="welcome">Welcome back, {{ firstname }}</p>
      <div class="expirydate-active">
        <button class="btn btn-outline-primary btn-sm" v-if="current_plan != ''">{{ current_plan_name }}: ₦{{ current_plan.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</button>
          <div v-if="expiry_status != 1"> 
            <button class="btn btn-outline-success btn-sm" v-if="current_plan == ''"><i class="fa fa-exclamation-circle"></i> {{ "Users are advice to subscribe to a package to start earning" }}</button>
          </div> 
          <div>
            <button class="btn btn-outline-primary btn-sm">Wallet Bal: {{ wallet_balance.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</button> <span class="text-primary" style="cursor:pointer" @click="isAddfund"><i class="fa fa-plus"></i></span>
          </div>
       </div>
        <div class="row account-details">       
        <div class="col-sm-4">
            <div class="account-balance">
              <div class="wallet-detail">
                  <p>Total Earning</p>
                  <h4>₦{{ cummulativeEarnings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</h4>
                  <p class="acct-d">Cummulative Earnings</p>
              </div>
              <div class="wallet-icon">
                  <i class="fas fa-wallet"></i>
              </div>
            </div>
        </div>
        <div class="col-sm-4">
          <div class="my-shop">
            <div class="wallet-detail">
               <p>Task Earning</p>
               <h4 v-if="!task_loading">₦{{ task_earnings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</h4>
               <h4 v-if="task_loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
               </h4>
               <div class="total-task-earning">
                  <p class="acct-d">Total Task Earnings</p>
                  <span v-if="withdrawalCount == 3">
                    <p class="withd"  @click="reSubscribe()"> Re-Subscribe</p>
                  </span>
                  <span v-else-if="withdrawalCount < 3">
                    <p class="withd" v-if="isWithdrawal_time == 1" @click="fundWithdrawal()"><i class="fa fa-bank"></i> Withdraw</p>
                  </span>
               </div>
            </div>
            <div class="wallet-icon">
               <i class="fas fa-coins"></i>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="fund-account">
            <div class="wallet-detail">
              <p>Referral Earning</p>
              <h4 v-if="!referral_loading">₦{{ referral_earnings.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</h4>
              <h4 v-if="referral_loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
               </h4>
               <div class="total-task-earning">
                <input type="hidden" id="myInput" :value="'https://dailychamp.com/ref-register/'+referral_code">
                <p class="acct-d">Ref ID: {{ referral_code }} <span class="copy-refid" @click="copyToClipboard()"><i class="fa fa-copy"></i></span></p>
                <p class="withd" v-if="referral_withd == 1"  @click="referralWithdrawal()"><i class="fa fa-bank"></i> Withdraw</p>
             </div>
           </div>
           <div class="wallet-icon">
              <i class="fas fa-cash-register"></i>
           </div>
          </div>
        </div>
      <!-- </div> -->
      </div>
      <div class="row content-center">
        <div class="col-sm-2">
          <router-link to="/tasks">
          <div class="box1">
              <div class="box-icon">
                  <i class="fa fa-calculator"></i>
              </div>
              <div class="box-details">
                Tasks(<span v-if="!task_count_loading">{{ totalTasks }}</span>)
                <span v-if="task_count_loading">
                 <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </span>
              </div>
          </div>
        </router-link>
        </div>
        <div class="col-sm-2">
          <router-link to="/packages">
          <div class="box2">
            <div class="box-icon">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
            </div>
            <div class="box-details">
              Packages
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-sm-2">
          <div class="box3" @click="marketplace()">
            <div class="box-icon">
                <i class="fa fa-shopping-cart"></i>
            </div>
            <div class="box-details">
              Marketplace
            </div>
        </div>
        </div>
        <div class="col-sm-2">
          <router-link to="/referrals">
          <div class="box4">
            <div class="box-icon">
              <i class="fa fa-users" aria-hidden="true"></i>
            </div>
            <div class="box-details">
              Referrals(<span v-if="!referral_count_loading">{{ referral_count }}</span>)
                <span v-if="referral_count_loading">
                 <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </span>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-sm-2">
          <router-link to="/profile">
          <div class="box5">
            <div class="box-icon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            <div class="box-details">
            My Profile
            </div>
          </div>
        </router-link>
        </div>
        <div class="col-sm-2">
          <router-link to="/bank">
          <div class="box6">
              <div class="box-icon">
                  <i class="fa fa-bank"></i>
              </div>
              <div class="box-details">
                My Bank
              </div>
          </div>
        </router-link>
        </div>
      </div>
      <div class="row content-center1">
        <div class="col-sm-12">
          Transactions
          <div class="tab-row">
            <div class="tab1" @click="viewTab1">
              withdrawal
            </div>
            <div class="tab2" @click="viewTab2">
              payment history
            </div>
          </div>
          <div class="tab1-view" v-if="tab1_view">
            <div style="padding:0.2rem; font-size: 13px !important"><strong>Withdrawal history</strong></div>
            <div class="table-responsive table-bordered table-striped table-sm">
              <v-data-table
                :headers="header2"
                :items="desserts2"
                dense
                class="custom-table"
              >
              <template v-slot:item.withdrawal_status="{ item }">
                <span v-html="item.withdrawal_status"></span>
              </template>
              </v-data-table>
            </div>
          </div>
          <div class="tab2-view" v-if="tab2_view">
            <div style="padding:0.2rem; font-size: 13px"><strong>Payment history</strong></div>
            <div class="table-responsive table-bordered table-striped table-sm">
              <v-data-table
                :headers="header"
                :items="desserts"
                dense
                class="custom-table"
              ></v-data-table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAccountDetail">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                              <div class="account-content-h">
                                  account details
                              </div>
                              <div class="account-content-d">
                                <i class="fa fa-home"></i> <span>Bank</span><br>{{ bank_name }}
                                <hr>
                                <i class="far fa-calendar"></i> <span>Account number</span><br>{{ account_number }}
                                <hr>
                                <i class="fa fa-user"></i> <span>Beneficiary</span><br>{{ account_name }}
                              </div>
                              <button class="btn btn-secondary" @click="isAccountDetail = false"><i class="fa fa-long-arrow-left"></i> back</button>
                              <p>&nbsp;</p>
                          </div>
                      </div>
                      <div class="col-md-1">
                          
                      </div>
  
                  </div>
              </div>
          </div>
        </transition>
      </div>
      <div v-if="isAddfund">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                            
                              <div class="account-content-h">
                                  account details
                                  <span class="add-fund">To add money to your wallet, make a transfer to the account below. Fund should reflects instantly.</span>
                              </div>
                              <div class="account-content-d">
                                <i class="fa fa-home"></i> <span>Bank</span><br>{{ bank_name}}
                                <hr>
                                <i class="far fa-calendar"></i> <span>Account number</span><br> {{ account_number }}
                                <hr>
                                <i class="fa fa-user"></i> <span>Beneficiary</span><br>{{ account_name }}
                              </div>
                              <button class="btn btn-secondary" @click="isAddfund = false"><i class="fa fa-long-arrow-left"></i> back</button>
                              <p>&nbsp;</p>
                          </div>
                      </div>
                      <div class="col-md-1">                         
                      </div>
                  </div>
              </div>
          </div>
        </transition>
      </div>
      <div v-if="isWithdrawal">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                              <div class="account-content-h">
                                 fund withdrawal
                                 <span class="add-fund">Enter the amount you want to withdraw below</span>
                              </div>
                              <div class="account-content-d">
                                <span>Amount</span><br>
                                <input type="number" class="form-control" placeholder="Enter amount" v-model="amount">
                              </div>
                              <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="submit()" v-if="!loading">Submit</button>
                              <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              </button>
                              <button class="btn btn-secondary" @click="isWithdrawal = false"><i class="fa fa-long-arrow-left"></i> back</button>
                              <p>&nbsp;</p>
                          </div>
                      </div>
                      <div class="col-md-1">                         
                      </div>
                  </div>
              </div>
          </div>
        </transition>
      </div>
      <div v-if="isReferralWithdrawal">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                              <div class="account-content-h">
                                 referral withdrawal
                                 <span class="add-fund">Enter the amount you want to withdraw below</span>
                              </div>
                              <div class="account-content-d">
                                <span>Amount</span><br>
                                <input type="number" class="form-control" placeholder="Enter amount" v-model="amount">
                              </div>
                              <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="submit1()" v-if="!loading">Submit</button>
                              <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              </button>
                              <button class="btn btn-secondary" @click="isReferralWithdrawal = false"><i class="fa fa-long-arrow-left"></i> back</button>
                              <p>&nbsp;</p>
                          </div>
                      </div>
                      <div class="col-md-1">                         
                      </div>
                  </div>
              </div>
          </div>
        </transition>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <page-loader :is-loading="isLoading"></page-loader>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {
  
  name: "HomeVew",
  components: {
    PageLoader
  },

  data(){
    return {
        search: '',
        loading: false,
        isLoading: false,
        tab1_view: true,
        tab2_view: false,
        isWithdrawal: false,
        isReferralWithdrawal: false,
        tab1: true,
        tab2: false,
        phone_number: '',
        email_address: '',
        user_id: '',
        firstname: '',
        lastname: '',
        bank_name:'',
        account_name:'',
        account_number: '',
        isAccountDetail: false,
        isAddfund: false,
        current_plan: '',
        current_plan_name: '',
        weekly_income: '',
        planid: '',
        expiry_date: '',
        expiry_status: '',
        referral_code: '',
        task_earnings: '',
        referral_earnings: '',
        task_loading: false,
        referral_loading: false,
        task_count_loading: false,
        referral_count_loading: false,
        payment_history: false,
        payments:[],
        cummulative_earning: '',
        referral_count: 0,
        totalTasks: '',
        isWithdrawal_time: '',
        withdrawal_list: [],
        amount: '',
        active_referrals: [],
        referral_withd: '',
        header: [
          {
            align: 'start',
            value: 'package',
            sortable: false,
            text: 'Package',
          },
          { value: 'amount', text: 'Amount' },
          { value: 'paymentref', text: 'Payment Ref' },
          { value: 'date', text: 'Date' },
        ],
        header2: [
          {
            align: 'start',
            value: 'package',
            sortable: false,
            text: 'Package',
          },
          { value: 'amount', text: 'Amount' },
          { value: 'bank', text: 'Disbursement account' },
          { value: 'withdrawal_status', text: 'Withdrawal status' },
          { value: 'date', text: 'Date' },
        ],
        desserts: [],
        desserts2: [],
        withdrawalCount: 0,
        user_bank_detail: '',
        wallet_balance: 0
,    };
  },

  beforeMount(){
    this.user_id = localStorage.getItem("userid");
    //this.checkExpiryDate(this.user_id)
  },

  mounted(){
      this.firstname = localStorage.getItem("firstname");
      this.lastname = localStorage.getItem("lastname");
      this.phone_number = localStorage.getItem("phone");
      this.email_address = localStorage.getItem("email");
      this.user_id = localStorage.getItem("userid");
      this.referral_code = localStorage.getItem("referral_code");
      this.fetchCurrentPlan(this.user_id)
      this.fetchCurrentSubscription(this.user_id)
      this.fetchTaskEarning(this.user_id);
      this.fetchReferralEarning(this.user_id);
      this.fetchTasks();
      this.fetchReferrals();
      this.paymentHistory();
      this.fetchTime();
      this.withdrawalHistory()
      this.fetchUserBank(this.user_id)
      this.fetchWalletBalance(this.user_id)
  },

  created(){
        
  },

  computed: {
    cummulativeEarnings(){
      return this.task_earnings + this.referral_earnings;
    },
    indexSn() {
      return this.desserts.map((d, index) => ({
          ...d,
          sn: index + 1
      }))
    },
    isSubmitButtonDisabled() {
        return (
          this.amount === ''
        );
    }
  },

  methods: {

    async fetchWalletBalance(userid){
        try {

          const response =  await this.$http.post('/fetch-wallet-balance', {
            userid: userid,
          })

            this.wallet_balance =  new Intl.NumberFormat('en-NG', {
              style: 'currency',
              currency: 'NGN',
            }).format(response.data.balance);
            return;
            
          } catch (err) {
          console.log(err);
          }
      },

    async fetchUserBank(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-bank', {
                  userid: userid,
                })

                this.user_bank_detail = response.data.bank
                    
            } catch (err) {
              console.log(err);
          }
    },

    async fetchCurrentPlan(userid){
        try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-current-plan', {
              userid: userid,
            })
            this.isLoading = false

            let plan = response.data.users[0].amount;
            if (plan == null) {
              this.current_plan = ''
              this.current_plan_name = ''
              this.weekly_income = ''
              this.planid = ''
            }
            else {
              this.current_plan = plan
              this.current_plan_name = response.data.users[0].plan_name
              this.weekly_income = response.data.users[0].weekly_income
              this.planid = response.data.users[0].plansid
              this.countUserWithdrawal(this.user_id, this.planid)
            }

            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async fetchCurrentSubscription(userid){
        try {
            this.isLoading = true
            const response =  await this.$http.post('/fetch-subscriptions', {
              userid: userid,
            })
            this.isLoading = false

            this.expiry_date = response.data.subscription.expiry_date;
            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async checkExpiryDate(userid){
      try { 

              const response = await this.$http.post('/update-subscriptions', {
                userid: userid,
              })

              this.expiry_status = response.data.expiry_status

              return;  
                        
          } catch (err) {
          console.log(err);
          }
      
    },

    async fetchTaskEarning(userid){
        try {
            this.task_loading = true
            const response =  await this.$http.post('/task-earnings', {
              user_id: userid,
            })
            this.task_loading = false
            this.task_earnings = response.data.balance;
            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async fetchReferralEarning(userid){
        try {
            this.referral_loading = true
            const response =  await this.$http.post('/referral-earnings', {
              user_id: userid,
            })
            this.referral_loading = false

            this.referral_earnings = response.data.balance;
            return;
            
          } catch (err) {
          console.log(err);
          }
    },

    async fetchTasks() {
        try {

            this.task_count_loading = true
            const response =  await this.$http.post('/fetch-user-daily-task', {
              user_id: this.user_id
            })
            this.task_count_loading = false
            this.tasks = response.data.tasks
            if (this.task == '') {
              this.totalTasks = 0
            }
            else{
              this.totalTasks = this.tasks.length  
            }                      
        } catch (err) {
          console.log(err);
      }   
    },

    async fetchReferrals() {
      try {

            this.referral_count_loading = true
            const response =  await this.$http.post('/fetch-referrals', {
                referral_code: this.referral_code
            })
            this.referral_count_loading = false
            this.referrals = response.data.referrals
            this.referral_count = this.referrals.length
            if ((this.referral_count == 15) || (this.referral_count > 15)) {
              this.referrals.forEach(el =>{
                  if(el.plan_active_status == 1){
                    this.active_referrals.push(el.userid)
                  }
              })
              if((this.active_referrals.length == 10) || (this.active_referrals.length > 10)){
                this.referral_withd = 1
              }else{
                this.referral_withd = ''
              }

            }
                
        } catch (err) {
          console.log(err);
      }
    },

    async paymentHistory() {
      try {
            this.payment_history = true
            const response =  await this.$http.post('/payment-history', {
                userid: this.user_id
            })
            this.payment_history = false
            this.payments = response.data.payments;
            this.payments.forEach(el => {
            this.desserts.push({
              package: el.plan_name,
              amount: 'NGN'+el.amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
              paymentref: el.payment_ref,
              date: new Date(el.created_at).toDateString()
            })
          }); 
        } catch (err) {
          console.log(err);
      }
    },
    async fetchTime() {
      try {
            const response =  await this.$http.get('/fetch-time', {
            })
            this.isWithdrawal_time = response.data.time
                
        } catch (err) {
          console.log(err);
      }
    },
    fundWithdrawal(){
      //console.log(this.planid)
      if (this.user_bank_detail == '') {
        swal.fire({
          icon: 'warning',
          title: 'No Beneficiary Account',
          width: 450,
          height: 5,
          text: 'Please add your beneficiary account',
        })
        return;
      }
      if (this.planid == '') {
        swal.fire({
          icon: 'warning',
          title: 'Invalid Withdrawal',
          width: 450,
          height: 5,
          text: 'Please subscribe for package before you can withdraw',
        })
        return;
      }
      else {
        this.isWithdrawal = true
      }
      
    },
    reSubscribe(){
      this.$router.push("/packages")
    },
    referralWithdrawal(){
      this.isReferralWithdrawal = true
    },
    async submit(){
      try {
         
        if (this.task_earnings == 0) {
            swal.fire({
                icon: 'warning',
                title: 'Insufficient Earnings',
                width: 450,
                height: 5,
                text: "You're not yet eligible for withdrawal! ",
              })
              return;
          }
         if (this.task_earnings < this.weekly_income) {
            swal.fire({
                icon: 'warning',
                title: 'Insufficient Earnings',
                width: 450,
                height: 5,
                text: "You're not yet eligible for withdrawal! ",
              })
              return;
          }

          if (this.amount > this.weekly_income) {
            swal.fire({
                icon: 'warning',
                title: 'Overdrawn',
                width: 450,
                height: 5,
                text: 'Please enter your weekly earnings!',
              })
              return;
          }
          if (this.amount < this.weekly_income) {
            swal.fire({
                icon: 'warning',
                title: 'Overdrawn',
                width: 450,
                height: 5,
                text: 'Please enter your weekly earnings!',
              })
              return;
          }
            this.loading = true
            const response =  await this.$http.post('/fund-withdrawal', {
                user_id: this.user_id,
                plan_id: this.planid,
                amount: this.amount,
            })
            this.loading = false
            if(response.data.success){
                swal.fire({
                icon: 'success',
                title: 'Successful',
                width: 450,
                height: 5,
                text: response.data.message,
              })
              this.isWithdrawal = false
              this.withdrawalHistory()
              this.fetchTaskEarning(this.user_id)
              return;
            }
                
        } catch (err) {
          console.log(err);
        }
    },

    async submit1(){
      try {

        if (this.referral_earnings <= 0)  {
            swal.fire({
                icon: 'warning',
                title: 'Overdrawn',
                width: 450,
                height: 5,
                text: 'Withdrawal is not allowed!',
              })
              return;
          }

        if (this.amount > this.referral_earnings)  {
            swal.fire({
                icon: 'warning',
                title: 'Overdrawn',
                width: 450,
                height: 5,
                text: 'Please enter amount that is less than or exact of NGN' + this.referral_earnings ,
              })
              return;
          }
        
            this.loading = true
            const response =  await this.$http.post('/fund1-withdrawal', {
                user_id: this.user_id,
                plan_id: this.planid,
                amount: this.amount,
            })
            this.loading = false
            if(response.data.success){
                swal.fire({
                icon: 'success',
                title: 'Successful',
                width: 450,
                height: 5,
                text: response.data.message,
              })
              this.isReferralWithdrawal = false
              return;
            }
                
        } catch (err) {
          console.log(err);
        }
    },

    async countUserWithdrawal(userid, planid){
        try {
              this.isLoading = true

              const response =  await this.$http.post('/count-withdrawal', {
                userid: userid,
                planid: planid
              })

              this.isLoading = false

              this.withdrawalCount = response.data.withdrawal_count[0].withdrawalCount
              //console.log(this.withdrawalCount)
              return;
            
          } catch (err) {
          console.log(err);
          }
    },
    async withdrawalHistory() {
      try {
            this.desserts2 = []
            const response =  await this.$http.post('/fetch-withdrawal', {
                userid: this.user_id
            })

            let withd_status = '';
            this.withdrawal_list = response.data.withdrawal_list;
            this.withdrawal_list.forEach(el => {
            if(el.withdrawal_status == 1){
              withd_status = "<span class='text-warning'><strong>Pending</strong></span>";
            }else if(el.withdrawal_status == 2){
              withd_status = "<span class='text-success'><strong>Approved</strong></span>";
            }
            let bank_info = el.bank_name + ' - ' + el.account_name + ' - ' + el.account_number;
            this.desserts2.push({
              package: el.plan_name,
              amount: 'NGN'+el.debit.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
              bank: bank_info,
              withdrawal_status: withd_status,
              date: new Date(el.created_at).toDateString()
            })
          }); 
        } catch (err) {
          console.log(err);
      }
    },
    viewTab1(){
      this.tab1_view = true
      this.tab2_view = false
    },
    viewTab2(){
      this.tab1_view = false
      this.tab2_view = true
    },
    copyToClipboard() {
      var copyText = document.getElementById("myInput");
      navigator.clipboard.writeText(copyText.value).then(function() {
          //alert("Copied the text: " + copyText.value);
          alert("Referral link copied!");
      }).catch(function(err) {
          console.error('Failed to copy: ', err);
      });
    },
    marketplace(){
      this.$router.push("/shopping")
    }
  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

.btn-success{
  background: #800080c7;   
  border: solid 0px;
  margin-bottom: 10px;
}
.copy-refid{
  cursor: pointer;
}
.expirydate-active{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.box-details{
  font-size: 12px !important;
}
.welcome{
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #170e0fac;

}
.btn-outline-primary{
  background: #80008052;
  color: #000;
  border: 0px;
  font-family: Mulish;
  font-size: 12px;
  font-weight:700;
  cursor:default !important;
}
.btn-outline-success{
  //background: #80008052;
  color: #000;
  border: 0px;
  font-family: Mulish;
  font-size: 12px;
  cursor:default !important;
}
.account-details1 {
  display: flex;
  flex-direction: column;
  border: solid 1px #ccc;
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
  border-radius: 4px;
  padding: 0.4rem;
  margin: 4px;
  font-family: Mulish;
  font-size: 12px;
}
.wallet-bal{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.account-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
  border-radius: 4px;
  padding: 0.4rem;
  margin: 4px;
}
.account-balance{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color:#800080c7;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-family: Mulish;
  padding: 0.4rem;
  padding-left: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: solid 1px #00000052;

  p{
    font-weight: bolder;
  }
}
.custom-table{
  font-size: 10px !important;
}
.wallet-detail{
  padding-top: 1rem;
  line-height: 1.4px;
  font-size: 15px;
  h4{
    font-weight: 650;
    font-size: 30px;
  }
  .acct-d{
    line-height: 0.8;
    font-weight: 400;
  }
}
.total-task-earning{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.withd{
  border-radius: 10px;
  border: solid 1px #800080c7;
  background: #fff;
  color: #000;
  font-family: Mulish;
  font-size: 10px;
  padding: 0.4rem;
  margin-left: 20px;
  cursor: pointer;
}
.withd:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.wallet-icon{
  font-size: 60px
}
.fund-account{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #800080c7;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-family: Mulish;
  padding: 0.4rem;
  padding-left: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: solid 1px #00000052;
  p{
    font-weight: bolder;
  }
}
.my-shop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #800080c7;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-family: Mulish;
  padding: 0.4rem;
  padding-left: 2rem;
  border: solid 1px #00000052;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  p{
    font-weight: bolder;
  }
}
.content-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
  border-radius: 4px;
  padding: 0.5rem;
  margin: 4px;
}
.content-center1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
  border-radius: 4px;
  padding: 0.5rem;
  margin: 4px;
}
.box1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box-icon{
  padding-left: 0.4rem;
  font-size: 35px;
  color:#312F33;
}
.box-details{
  font-size: 14px;
  color:#170e0fac;
}
.box1:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box2:hover{
   transform: scale(1.1); 
   transition: transform 0.3s ease; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
   cursor: pointer;
 }
.box3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box3:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
 }
 .box4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box4:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.box5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box5:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.box6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Mulish;

  a{
    text-decoration: none;
    color: #000;
  }
}
.box6:hover{
  transform: scale(1.1); 
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.img{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.box-details{
  padding: 0.4rem;
  line-height: 1.4px;
  .title{
    font-family: Mulish;
    font-weight: 550;
    font-size: 10px;
    text-transform: capitalize;
    color: #ff4d63;
  }
  .description{
    font-family: Mulish;
    font-size: 12px;
  }
}
.continue{
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 0.4rem;
  font-size: 30px;
  color: rgb(237, 138, 108);
  margin-right: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 80%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Mulish;
  }

  h3 {
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      font-family: Mulish;
  }
}
.account-content{
  display: flex;
  flex-direction: column;
  justify-content: center;  
  margin-right: 8px;
}
.account-content-h{
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  width: 100%;
  height: 50px;   
  margin: 10px;
  padding-top: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}
.add-fund{
  font-size: 12px;
  text-decoration: none !important;
  text-transform: capitalize;
}

.account-content-d{
  padding-top: 1.3rem;
  font-size: 14px;
  margin-bottom: 10px;
}
.btn-secondary{
  text-transform: uppercase;
}

.modal-close {
  color: #000;
  line-height: 50px;
  font-size: 140%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 2;
  width: 70px;
  text-decoration: none;

  &:hover {
      color: black;
  }
}

.modal-close-mobile {
  color: #000;
  line-height: 50px;
  font-size: 140%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 2;
  width: 70px;
  text-decoration: none;

  &:hover {
      color: black;
  }

  display: none;
}

a {
  text-decoration: none;
}
.text-primary{
  color: #000 !important;
}
.tab-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #cccccc7e;
  font-size: 12px;
  font-weight: bold;
  font-family: Mulish;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
  border: solid 1px #ccc;
}
.tab1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #ccc;
  width: 50%;
  text-transform: uppercase;
  height: 40px;
  cursor: pointer;
}
.tab1:hover{
  border-bottom: solid 1px #000;
}
.tab2:hover{
  border-bottom: solid 1px #000;
}
.tab2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-transform: uppercase;
  height: 40px;
  cursor: pointer;
}
.tab1-view{
  display: flex;
  flex-direction: column;
}
.tab2-view{
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: Mulish;
}

@media screen and (max-width: 768px) {
  .welcome{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #170e0fac;
  }
  .content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
  }
  .account-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //border: solid 1px #ccc;
    box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
    border-radius: 4px;
    //padding: 0.3rem;
    margin: 4px;
  }
  .total-task-earning{
    display: flex;
    flex-direction: column;
  }
  .withd{
    border-radius: 10px;
    border: solid 1px #800080c7;
    background: #fff;
    color: #000;
    font-family: Mulish;
    font-size: 10px;
    padding: 0.4rem;
    margin-left: 40px;
  }
  .account-balance{
    margin-bottom: 10px;
  }
  .my-shop{
    margin-bottom: 10px;
  }

  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: Mulish;
      font-weight: 550;
      font-size: 8px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: Mulish;
      font-size: 12px;
    }
  }

  .box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box2 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box3 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box4 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box5 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    margin-top: -10px;
    color: rgb(237, 138, 108);
  }

  .modal-close-mobile {
    display: block;
  }
  .modal-close {
    display: none;
  }

  .account-content{ 
    margin: 10px;
    margin-right: 30px;
  }
  .tab-row{
    font-size: 10px;
  }

}

</style>