<template>
    <div class="container-fluid">
        <div id="splash-message" class="splash-message"></div>
            <div class="menu-content">
                <div class="account-head">
                    
                </div>
                <div class="verify-email">
                    <i class="fa fa-spinner"></i>
                </div>
                
            </div>
            
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import PageLoader from '../components/PageLoader.vue'
    
  export default {
    
    name: "MyAccounts",
    components: {
        //quillEditor,
        PageLoader
    },
  
    data(){
      return {
  
          isLoading: false,
          isLoading1: false,
          isLoading2: false,
          is_myaccount: true,
          is_myorder: false,
          isPaymentPlans: false,
          isTrackItem: false,
          isAddfund: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',         
 
      };
    },
  
    beforeMount(){
       
    },
  
    mounted(){
        this.verifyEmail(this.$route.params.email_id)
    },
  
    created(){
          
    },
  
    computed: {
      
    },
  
    methods: {
        
        async verifyEmail(email){
            try {
            this.isLoading2 = true
            const response =  await this.$http.post('/verify-email1', {
                email: email,
            })
            this.isLoading2 = false
            if(response.data.success){
                this.showSplashMessage("Verification successful!")   
            }
                
            } catch (err) {
            console.log(err);
            }
        },

        showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
            this.$router.push('/myaccounts')
        }, 2000); // Hide the message after 2 seconds
        },
    }  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  .cursor-verify{
    cursor: pointer;
    font-size: 10px;
    color: red;
    font-style: italic;
  }
  .add-fund{
    font-size: 10px
  }
  .container-fluid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 0.9rem;
    height: 80vh;
  }
  .account-menu{
    display: flex;
    flex-direction: column;
    height: 67vh;
    background: #fff;
    width: 20%;
    border-radius: 4px;
    margin: 5px;
  }
  .menu{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
    cursor: pointer;
    font-weight: 900;
    color: #000000b5;
  }
  .menu:hover{
    background: #cccccc98;
  }
  .menu1{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
  }
  
  .menu2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
   // border-top:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
    cursor: pointer;
    font-weight: 900;
    color: #000000b5;
  }
  .menu2:hover{
    background: #cccccc98;
  }
  .menu-content{
    display: flex;
    flex-direction: column;
    height: 67vh;
    //background: #fff;
    width: 100%;
    border-radius: 4px;
    margin: 5px;
  }
  .menu-content1{
    display: flex;
    flex-direction: column;
    height: 67vh;
    background: #fff;
    width: 80%;
    border-radius: 4px;
    margin: 5px;
  }
  .account-head{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    //border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 15px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .verify-email{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    font-family: Mulish;
    font-size: 150px;
    font-weight: 500;
    padding: 0.8rem;
    color: #107c2ee8;
  }
  .account-head1{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    //border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 15px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  thead{
    font-size: 10px;
  }
  .account-overview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .account-col1{
    display: flex;
    flex-direction: column;
    background: #fff;
    border: solid 1px #ccc;
    width: 80%;
    border-radius: 4px;
    margin: 5px;
  }
  .account-col2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    border-bottom:  solid 1px #ccc;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .account-col3{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .name{
    font-size: 14px;
    font-family: Mulish;
  }
  .email{
    font-size: 13px;
    font-weight: 500;
    font-family: Mulish;
    color: #00000076;
  }
  .phone{
    font-size: 13px;
    font-weight: 500;
    font-family: Mulish;
    color: #00000076;
  }
  .shipping{
    font-size: 14px;
    font-family: Mulish;
  }
  .address{
    font-size: 12px;
    font-family: Mulish;
    color: #00000076;
  }
  .fa-check-circle{
    color: #27963d;
  }
  .cursor{
    cursor: pointer;
    text-decoration: underline;
  }
  #progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: rgb(252, 103, 49);
    padding-left: 0px;
    margin-top: 0vh
}

#progressbar li {
    list-style-type: none;
    font-size: x-small;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: rgb(160, 159, 159)
}

#progressbar #step1:before {
    content: "";
    color: rgb(252, 103, 49);
    width: 5px;
    height: 5px;
    margin-left: 0px !important
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-left: 32%
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 32%
}

#progressbar #step4:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 0px !important
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #ddd;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh
}

#progressbar li:after {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 1px;
    z-index: 1
}
.progress-track {
    padding: 0 8%;
    width:100%;
    text-transform: capitalize;
    font-size: 8px !important;
}
.bar-width{
    width:100%;
    height: 10px;
    font-size: 9px;
    font-weight: bold;
}

#progressbar li:nth-child(2):after {
    margin-right: auto
}

#progressbar li:nth-child(1):after {
    margin: auto
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%
}

#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%
}

#progressbar li.active {
    color: black
}
#progressbar li.active:before,
#progressbar li.active:after {
    background: rgb(252, 103, 49)
}

  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 4px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    height: 200px;
    overflow-y: auto
  }

  .account-content-d1{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    height: 100px;
    overflow-y: auto
  }
  
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }
  
  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }
  
  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }
  
  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }
  
  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }
  
  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }
  
  .inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    padding: 0.4rem;
    overflow-y: auto;
  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 10%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
    border: solid 1px #ccc;
  }
  .empty-cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .cart-e{
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 14px;
  }
  .cart-e2{
    font-family: Mulish;
    font-size: 14px;
    margin-top: -20px;
  }
  .btn-shopping{
    background: #800080;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 14px;
    padding: 0.5rem;
    font-family: Mulish;
  }
  .fa-shopping-bag{
    font-size: 60px;
    color: #800080;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 12px;
    background: #fff;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }
  
  .btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #800080;
    color: #fff;
    border-radius: 4px;
  }
  .counter-action{
    font-size: 13px;
  }
  .login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .payment-terms{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 12px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
    text-transform: capitalize;
    span{
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .cancel-order{
    font-size: 9px;
    font-family: Mulish;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    //color: #800200;
  }
  .payment-schedule{
    cursor: pointer;
    width: 120px !important;
    border-radius: 4px;
    text-align: left;
    font-size: 9px;
    font-family: Mulish;
    font-weight: 500;
  }
  .payment-schedule:hover{
    color: #044427f8;
  }
  .cancel-order:hover{
    color: #4d1606fe;
  }
  .schedule{
    border-radius: 2px;
    border: solid 1px #8000804c;
    padding: 0.4rem;
    color: #800080;
    cursor: pointer;
  }
  .proceed{
    border-radius: 2px;
    padding: 0.4rem;
    background:#250325;
    color: #fff;
    cursor: pointer;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #800080;
        font-size: 15px;
      }
    .btn-remove:hover{
      color: #a5a3a3;
    }
  }
  
  .order-number{
    background: #800080; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .check-out{
    margin-top:60px; 
    a{
      text-decoration: none
    }
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100px; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
  }
  .product-name{
    margin-top:-10px;
    .cart-title{
        font-size: 14px;
        font-family: Mulish;
        font-weight: 700;
        color: #2b2929;
    }
    .brand{
       // margin-top: -10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 0.1px;
    }
    .brand1{
        //margin-top: -10px;
        font-size: 10px;
        line-height: 0.1px;
    }
    .in-stock{
        font-size: 12px;
        line-height: 1;
        margin-top: -10px;
        color: #a5a3a3;
      }
  }
  .product-price{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    div{
      margin: 5px;
    }
  }

  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #800080;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }
  
  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }
  
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
  }
  .delivery-status{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;
  
  }
  .delivery-return{
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
  }
  .d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
    padding: 0.3rem;
    margin-top: 30px;
    border-bottom: solid 1px #a5a3a37b;
  }
  .location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
  }
  
  @media screen and (max-width: 768px) {
    
    .account-menu{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 20%;
        border-radius: 4px;
        margin: 5px;
    }
    .menu{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        box-sizing: border-box;
        height: auto;
        border-bottom:  solid 1px #ccc;
        font-family: Mulish;
        font-size: 12px;
        padding: 0.8rem;
        cursor: pointer;
        font-weight: bold;
        color: #000000b5;
      }
      .menu2{
        display: none;
      }
    .icon-text{
        display: none;
    }
    .menu-content{
        display: flex;
        flex-direction: column;
        height: 67vh;
        background: #fff;
        width: 100%;
        border-radius: 4px;
        margin: 5px;
      }
      .menu-content1{
        display: flex;
        flex-direction: column;
        height: 67vh;
        background: #fff;
        width: 100%;
        border-radius: 4px;
        margin: 5px;
      }

    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
      .product-name{
        margin-top: 0px;
      }
      
      .column9{
        width: 100%;
        margin-top: 350%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-price{
      display: flex;
      flex-direction:row;
      justify-content: left;   
    }
    .payment-schedule{
      font-size: 10px;
    }
    .cancel-order{
      font-size: 10px;
    }
    .product-counter-remove{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0.6rem;
      
      font-family: Mulish;
      background: #fff;
      .btn-remove{
          text-transform: uppercase;
          color: #800080;
          font-size: 12px;
        }
      .btn-remove:hover{
        color: #a5a3a3;
      }
    }
    .btn-counter{
      padding: 6px;
      font-weight: bold;
      font-family: Mulish;
      font-size: 12px;
      margin: 2px;
      width: 12%;
      background: #800080;
      color: #fff;
      border-radius: 4px;
    }
  
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      height: 220px;
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }
  
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      margin-right: 8px;
      width: 106%;
      padding: 10px;
    }
  
  }
    

  
  </style>