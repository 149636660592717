<template>
    <div class="container-fluid">
        <center><p class="welcome"> Product</p></center>
        <div class="row account-details" v-if="addProduct">
          <div class="col-sm-1">  
          </div>
          <div class="col-sm-10">
            <i class="fa fa-eye" @click="view_products" style="cursor:pointer"> View Product</i>
            <form @submit.prevent="createBrand" enctype="multipart/form-data">
            <div class="inputs">
              
                <v-autocomplete
                  required
                  v-model="shop_name"
                  label="Business name *"
                  :items="shops"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  required
                  v-model="brand_name"
                  label="Brand *"
                  :items="brands"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  @input="selectCategory"
                  required
                  v-model="category_name"
                  label="Category *"
                  :items="categorys"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                required
                v-model="subcategory_name"
                label="Sub Category *"
                :items="subcategorys"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <div class="inputs">              
                <label for=""> Product name *</label>
                <input type="text" class="form-control inputs-field" placeholder="Enter product name" v-model="product_name" required>
            </div>
            <div class="inputs">              
              <label for=""> Price *</label>
              <input type="text" class="form-control inputs-field" placeholder="Enter product price" v-model="price" required>
            </div>
            <div class="inputs">              
                <label for=""> Product description *</label>
                <quill-editor class="quill-container" v-model="product_description" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product key features </label>
              <quill-editor class="quill-container" v-model="product_keyfeature" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product specification </label>
              <quill-editor class="quill-container" v-model="product_specification" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="image-input">
              <div class="inputs">              
                  <label for="">Upload main image *</label>
                  <input type="file" ref="fileInput" id="image_input"  class="file-input"  @change="pickFile"/>
              </div>
            </div>
           
            </form>
           
            <div class="inputs">
                <button id="submit2" class="btn btn-danger btn-block" :disabled="isProductEnabled"  @click="createProduct()" v-if="!loading">save</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <p>&nbsp;</p>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
        </div>
        <div class="row account-details" v-if="viewProduct">
            <div class="col-sm-12">
              <i class="fa fa-plus-square" @click="add_products" style="cursor:pointer"> Add Product</i>
              <div class="table table-responsive table-bordered table-sm">
                <h6>All Products</h6>
                <v-data-table
                  :headers="header"
                  :items="desserts"
                  dense
                >
                <template v-slot:item.businessname="{ item }">
                  <div class="a-text">{{ item.businessname }}</div>
                </template>  
                <template v-slot:item.brand="{ item }">
                  <div class="a-text">{{ item.brand }}</div>
                </template>  
                <template v-slot:item.category="{ item }">
                  <div class="a-text">{{ item.category }}</div>
                </template>  
                <template v-slot:item.subcategory="{ item }">
                  <div class="a-text">{{ item.subcategory }}</div>
                </template> 
                <template v-slot:item.productname="{ item }">
                  <div class="a-text">{{ item.productname }}</div>
                </template> 
                <template v-slot:item.price="{ item }">
                  <div class="a-text">&#8358;{{ item.price }}</div>
                </template>   
                <template v-slot:item.img="{ item }">
                  <img width="40" height="40" :src="img_url + 'products/' + item.img" alt="" style="border-radius: 4px">
                </template>  
                <template v-slot:item.right="{ item }">
                  <img width="40" height="40" :src="img_url + 'products/' + item.right" alt="" style="border-radius: 4px">
                </template>  
                <template v-slot:item.left="{ item }">
                  <img width="40" height="40" :src="img_url + 'products/' + item.left" alt="" style="border-radius: 4px">
                </template>  
                <template v-slot:item.back="{ item }">
                  <img width="40" height="40" :src="img_url + 'products/' + item.back" alt="" style="border-radius: 4px">
                </template>     
                <template v-slot:item.productid="{ item }">
                  <div style="display: flex; flex-direction: row">
                    <button class="btn btn-sm" @click="addVariations(item.productid)"><i class="fa fa-plus"></i> add</button>
                  </div>
                </template>
                <template v-slot:item.p_id="{ item }">
                  <div style="display: flex; flex-direction: row">
                    <button class="btn btn-sm" @click="upload1(item.product_id)"><i class="fa fa-upload"></i> right</button>
                    <button class="btn btn-sm" @click="upload2(item.product_id)"><i class="fa fa-upload"></i> left</button>
                    <button class="btn btn-sm" @click="upload3(item.product_id)"><i class="fa fa-upload"></i> back</button>
                  </div>
                </template>
                <template v-slot:item.product_id="{ item }">
                  <button @click="editProduct(item.product_id)"><i class="fa fa-edit"></i></button>
                </template>
                </v-data-table>
              </div>
            </div>
        </div>
        <div v-if="isVariations">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   Add variations
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                  <input type="hidden" class="form-control inputs-field" v-model="productid" required>
                                  <label for="">Variation </label>
                                  <input type="text" id="variation" class="form-control inputs-field" placeholder="Enter variations" v-model="variations" required>
                                  <label for="">Price</label>
                                  <input type="text" id="price" class="form-control inputs-field" placeholder="Enter price" v-model="vprice" >
                                </div>
                                <button id="submit2" class="btn btn-secondary"  @click="createVariation()" v-if="!is_submit">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="btn btn-default" @click="isVariations = false"><i class="fa fa-long-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <div v-if="isRightImage">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   Add Image Right View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                  <div class="inputs">              
                                    <label for="">Browse image *</label>
                                    <input type="file" ref="fileInput1" id="image_input1"  class="file-input"  @change="pickFile1"/>
                                    <input type="hidden" placeholder="" v-model="product_id">
                                  </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary"  @click="submit1()" v-if="!is_submit1">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit1">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button  @click="isRightImage = false"><i class="fa fa-long-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <div v-if="isLeftImage">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   Add Image Left View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                  <div class="inputs">              
                                    <label for="">Browse image *</label>
                                    <input type="file" ref="fileInput2" id="image_input2"  class="file-input"  @change="pickFile2"/>
                                    <input type="hidden" placeholder="" v-model="product_id">
                                  </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary"  @click="submit2()" v-if="!is_submit2">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit2">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button  @click="isLeftImage = false"><i class="fa fa-long-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <div v-if="isBackImage">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   Add Image Back View
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                  <div class="inputs">              
                                    <label for="">Browse image *</label>
                                    <input type="file" ref="fileInput3" id="image_input3"  class="file-input"  @change="pickFile3"/>
                                    <input type="hidden" placeholder="" v-model="product_id">
                                  </div>
                                </div>
                                <button id="submit2" class="btn btn-secondary"  @click="submit3()" v-if="!is_submit3">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit3">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button  @click="isBackImage = false"><i class="fa fa-long-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>

  import 'quill/dist/quill.snow.css';
  import { quillEditor } from 'vue-quill-editor';
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "ProductPage",
    components: {
        quillEditor,
        PageLoader
    },
  
    data(){
      return {
          
          isLoading: false,
          isRightImage: false,
          isLeftImage: false,
          isBackImage: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
          loading1: false,
          loading2: false,
          loading3: false,
          shop_name: '',
          brand_name: '',
          category_name: '',
          subcategory_name: '',
          product_name: '',
          product_description: '',
          product_keyfeature: '',
          product_specification: '',
          price: '',
          response: '',
          subcategory: [],
          categorys:[],
          shops: [],
          brands: [],
          products: [],
          subcategorys:[],
          selectedFile: '',
          selectedFile1: '',
          selectedFile2: '',
          selectedFile3: '',
          addProduct: false,
          viewProduct: true,
          editorOptions: {
            placeholder: 'Write something...',
        },
        isVariations: false,
        productid: '',
        variations: '',
        vprice: '',
        is_submit: false,
        is_submit1: false,
        is_submit2: false,
        is_submit3: false,
        img_url: process.env.VUE_APP_IMAGE_URL,
        header: [
            {
                align: 'start',
                value: 'businessname',
                sortable: false,
                text: 'Vendor',
            },
            { value: 'brand', text: 'Brand' },
            { value: 'category', text: 'Category' },
            { value: 'subcategory', text: 'SubCategory' },
            { value: 'productname', text: 'Product' },
            { value: 'img', text: 'Main Img' },
            { value: 'right', text: 'Right Img' },
            { value: 'left', text: 'Left Img' },
            { value: 'back', text: 'Back Img' },
            { value: 'price', text: 'Price' },
            { value: 'productid', text: 'Variation' },
            { value: 'p_id', text: 'Add image' },
            { value: 'product_id', text: 'Action' },
          ],
        desserts: [],
        user_role: '',
        product_id: '',
        
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.user_role = localStorage.getItem("user_role");

        if (this.user_role == 1) {
          this.fetchProducts()
          this.fetchshops()
        }
        else if (this.user_role == 3) {
          this.fetchMyProducts(this.user_id)
          this.fetchShopById()
        }

        this.fetchcategorys()
        this.fetchbrands()
    },
  
    created(){
          
    },
  
    computed: {
      isProductEnabled() {
        return (
            this.shop_name === '' ||
            this.brand_name === '' ||
            this.subcategory_name === '' ||
            this.category_name === '' ||
            this.product_name === '' ||
            this.product_description === '' ||
            this.price === ''
        );
      },
      indexSn() {
            return this.desserts.map((d, index) => ({
                ...d,
                sn: index + 1
            }))
      },
            
    },
  
    methods: {
      editProduct(product_id){
        this.$router.push('/edit-product/'+product_id)
      },
      async upload1(product_id){
        this.product_id = product_id
        this.isRightImage = true
      },

      async upload2(product_id){
        this.product_id = product_id
        this.isLeftImage = true
      },

      async upload3(product_id){
        this.product_id = product_id
        this.isBackImage = true
      },

      async submit1(){
      try {

            this.loading1 = true

            const formData = new FormData();
            formData.append('images', this.selectedFile1),
            formData.append('product_id', this.product_id),
           
            this.response = await this.$http.post('/upload-product1', formData

            );
            this.loading1 = false

            if( !this.response.data.success ){
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })
                return;
            }
            else{
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })     
                this.isRightImage = false
                this.fetchMyProducts(this.user_id)
                return;

            }
            } catch (err) {
            console.log(err);
            }
      },

      async submit2(){
        try {

            this.loading2 = true

            const formData = new FormData();
            formData.append('images', this.selectedFile2),
            formData.append('product_id', this.product_id),
           
            this.response = await this.$http.post('/upload-product2', formData

            );
            this.loading2 = false

            if( !this.response.data.success ){
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })
                
                return;
            }
            else{
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })     
                this.isLeftImage = false
                this.fetchMyProducts(this.user_id)
                return;
            }
            } catch (err) {
            console.log(err);
            }
      },

      async submit3(){
      try {

            this.loading3 = true

            const formData = new FormData();
            formData.append('images', this.selectedFile3),
            formData.append('product_id', this.product_id),
           
            this.response = await this.$http.post('/upload-product3', formData

            );
            this.loading3 = false

            if( !this.response.data.success ){
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })
               
                return;
            }
            else{
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                })     
                this.isBackImage = false
                this.fetchMyProducts(this.user_id)
                return;
            }
            } catch (err) {
            console.log(err);
            }
      },

       addVariations(productid){
          this.productid = productid
          this.isVariations = true
        },

        async createProduct() {
            try {

                    this.loading = true

                    const formData = new FormData();
                    formData.append('images', this.selectedFile),
                    formData.append('shop_id', this.shop_name),
                    formData.append('brand_id', this.brand_name),
                    formData.append('category_id', this.category_name),
                    formData.append('subcategory_id', this.subcategory_name),
                    formData.append('product_name', this.product_name),
                    formData.append('product_description', this.product_description),
                    formData.append('product_keyfeature', this.product_keyfeature),
                    formData.append('product_specification', this.product_specification),
                    formData.append('product_price', this.price),
                    formData.append('addedby',  this.user_id),

                     this.response = await this.$http.post('/create-product', formData
    
                    );
                    this.loading = false

                    if( !this.response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        return;
                    }
                    else{
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
 
                        this.shop_name = '' 
                        this.brand_name = ''
                        this.subcategory_name = ''
                        this.category_name = ''
                        this.product_name = ''
                        this.product_description = ''
                        this.product_keyfeature = ''
                        this.product_specification = ''
                        this.price = ''              
                        this.fetchMyProducts()
                        return;
                    }
            } catch (err) {
              console.log(err);
            }
        },
        async createVariation() {
            try {
               this.is_submit = true
               const response =  await this.$http.post('/create-variation', {
                product_id: this.productid,
                variation: this.variations,
                price: this.vprice,
                addedby: this.user_id
            })
              this.is_submit = false

            swal.fire({
                icon: 'success',
                title: 'Success',
                width: 450,
                height: 5,
                text: response.data.message,
            })
                 
            } catch (err) {
              console.log(err);
          }
        },
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })
                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchshops() {
            try {
               const response =  await this.$http.get('/fetch-all-shops', {
            })
                response.data.shops.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },
        async fetchShopById() {
            try {
 
               const response =  await this.$http.post('/fetch-shop-byuserid', {
                  userid: this.user_id
                })

                response.data.data.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
                response.data.brands.forEach(el => {
                    this.brands.push({
                        text: el.brand_name,
                        value: el.brandid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async selectCategory(){
            try {

                this.subcategorys = [] // reset list

               const response =  await this.$http.post('/fetch-all-subcategories-byid', {
                category_id: this.category_name
            })
                response.data.categories.forEach(el => {
                    this.subcategorys.push({
                        text: el.subcategory_name,
                        value: el.subcategoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchProducts() {
            try {
              this.isLoading = true
               this.desserts = []
               const response =  await this.$http.get('/fetch-all-products', {
             })
             this.isLoading = false
                this.products = response.data.products
                this.products.forEach(el => {
                        this.desserts.push({
                          businessname: el.shop_name,
                          brand: el.brand_name,
                          category: el.category_name,
                          subcategory: el.subcategory_name,
                          productname: el.product_name,
                          img: el.product_image,
                          price: el.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                          productid: el.productid,
                          product_id: el.productid,
                          p_id: el.productid,
                          right: el.right_image,
                          left: el.left_image,
                          back: el.back_image,
                        })
                    });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchMyProducts(userid) {
            try {
               
               this.isLoading = true
               this.desserts = []
               const response =  await this.$http.post('/fetch-product-byuserid', {
                userid: userid
             })
             this.isLoading = false
                this.products = response.data.products
                this.products.forEach(el => {
                        this.desserts.push({
                          businessname: el.shop_name,
                          brand: el.brand_name,
                          category: el.category_name,
                          subcategory: el.subcategory_name,
                          productname: el.product_name,
                          img: el.product_image,
                          price: el.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }),
                          productid: el.productid,
                          product_id: el.productid,
                          p_id: el.productid,
                          right: el.right_image,
                          left: el.left_image,
                          back: el.back_image,
                        })
                    });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async removeCategory(categoryid){
        let con = confirm("          You're about to remove this category. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/delete-subcategory', {
                subcategory_id: categoryid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchProducts()
                return;

              } catch (err) {
              console.log(err);
              }
          }
        },

        pickFile() {
            this.selectedFile = this.$refs.fileInput.files[0];            
        },
        pickFile1() {
            this.selectedFile1 = this.$refs.fileInput1.files[0];            
        },
        pickFile2() {
            this.selectedFile2 = this.$refs.fileInput2.files[0];            
        },
        pickFile3() {
            this.selectedFile3 = this.$refs.fileInput3.files[0];            
        },
        view_products(){
            this.viewProduct = true,
            this.addProduct = false
        },
        add_products(){
            this.viewProduct = false,
            this.addProduct = true
        }

    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .quill-container {
    height: 200px; /* Set the desired height here */
  }
.a-text{
    font-size: 11px;
    font-family: Mulish;
}
.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
}
.shop-detail{
    font-size: 12px;
    font-family: Mulish;
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: Mulish;
  background: #800080; 
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0.9rem;
    margin: 4px;
    background: #fff;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .image-input{
    margin-top: 10px;
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: Mulish;
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: Mulish;
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  .shop-name{
    //font-weight: 550;
    color: #000;
    font-family: Mulish;
  }
  @media screen and (max-width: 768px) {
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: Mulish;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .image-input{
      margin-top: 120px;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: Mulish;
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: Mulish;
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 12px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>