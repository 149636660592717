<template>
    <div>
        <!-- top navbar -->
        <nav class="navbar navbar-expand-lg
        navbar-light navbar-bgcolor">
            <a class="navbar-brand" href="#"><img src="logo.png" alt="Logo"> Daily Champ</a>
           
            <div class="dropdown">
                <button class="dropbtn avater">{{ firstname }} {{ lastname }} <span>{{ name_initial }}</span></button>
                <div class="dropdown-content">
                  <div v-if="user_role == 1">
                    <router-link to="/shop">Shop</router-link>
                    <router-link to="/brand">Brand</router-link>
                    <router-link to="/category">Category</router-link>
                    <router-link to="/subcategory">SubCategory</router-link>
                    <router-link to="/product">Product</router-link>
                    <div style="cursor: pointer" @click="logout()">Logout <i class="fa fa-sign-out"></i></div>
                  </div>
                  <div v-if="user_role == 2">
                    <router-link to="/myorders">My Orders</router-link>
                    <div style="cursor: pointer" @click="logout()">Logout <i class="fa fa-sign-out"></i></div>
                  </div>
                  <div v-if="user_role == 3">
                    <router-link to="/shop">Shop</router-link>
                    <router-link to="/product">Product</router-link>
                    <router-link to="/myorders">My Orders</router-link>
                    <div style="cursor: pointer" @click="vendorLogout()">Logout <i class="fa fa-sign-out"></i></div>
                  </div>
                </div>
            </div>
        </nav>
       
        <div class="container">
            <router-view />
        </div>

        <div class="footer">
            Privary Policy | Disclaimer
            <hr>
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br> 
            &copy;{{ new Date().getFullYear() }} {{ appTitle}} All rights reserved.
        </div>
    </div>
</template>
<script>
export default {

    name: "Dashboard",
    components: {

    },

    data(){
        return {
            appTitle: process.env.VUE_APP_COMPANY,
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }

        //console.log(this.user_role);
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
        logout(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
            this.$router.push("/login")
        },
        vendorLogout(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
            this.$router.push("/vendor-login")
        }
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.container{
    //background: #800080;
}
/* Style The Dropdown Button */
.dropbtn {
   // background-color: #4CAF50;
    color: white;
    //padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Mulish;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #800080c7;
    color: #fff;
    font-family: Mulish;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #fff !important;
        text-decoration: none;
        font-size: 15px !important;
        font-weight: normal !important;

        img{
            width: 60px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
    flex-shrink: 0;
    background: #800080c7;
    font-family: Mulish;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 4px;
    text-align: center;
    bottom: 0;
    height: 100px;
    width: 100%;
    font-size: 12px;
    margin-bottom: 0px;
    position:relative;
}

@media screen and (max-width: 768px) { 
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 12px;
        display: flex;
    }
    .navbar-bgcolor{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #800080c7;
        color: #fff;
        font-family: Mulish;
       box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    
        a{
            color: #fff !important;
            text-decoration: none;
            font-size: 13px !important;
            font-weight: normal !important;
    
            img{
                width: 50px;
            }
        }
        a:hover{
            color: #fff;
        }
    }
}

</style>