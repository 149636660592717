<template>
    <div>
      <div id="splash-message" class="splash-message"></div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

        <div class="product-container">
            <div class="column9" v-if="paymentPad">
                <div class="product-cart-number">
                  <div class="payment-method">
                    <div class="product-name payment-span">
                        <p class="cart-title">Select payment options</p>
                         <div class="form-check-label">
                            <div>
                              <input type="radio" name="optradio" value="wallet" v-model="selectedPaymentMethod" @change="selectPay()">
                            </div>
                            <div>&nbsp;&nbsp;Champ Pocket</div> 
                         </div>
                       
                    </div>
                    <div class="product-name payment-span">
                         <div class="form-check-label">
                            <div>
                              <input type="radio" name="optradio" value="bankTransfer" v-model="selectedPaymentMethod" @change="selectPay()">
                            </div>
                            <div>&nbsp;&nbsp;Paystack</div>
                         </div>
                     </div>
                     <div class="product-name payment-span" v-if="user_payment_card != ''">
                        <div class="form-check-label">
                          <div>
                            <input type="radio" name="optradio" value="saveCard" v-model="selectedPaymentMethod" @change="selectPay()">
                          </div>
                          <div>&nbsp;&nbsp;My Saved Card</div>
                        </div>
                    </div>
                </div>
                   
                </div>
                <div class="cart-0">
                    <div class="product">
                        <div class="product-detail">
                            <div class="product-name">
                                <p class="cart-title">total to pay</p>
                                <p class="brand">₦{{payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}}  </p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number">
                   <span>&nbsp;&nbsp;
                    you will pay with</span>
                </div>
                <div class="cart-0">
                    <div class="center-p">
                        <div class="center-p-0-0">
                            <div class="center-p-0">
                                <p class="text-center" v-if="selectedPaymentMethod == 'wallet' "><i class="fa fa-wallet"></i> Champ Pocket Balance<br> <span style="color: #000; font-weight: 550">₦{{ walletBal.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</span> </p>
                                <p class="text-center" v-if="selectedPaymentMethod == 'bankTransfer' ">Pay with card, transfer, ussd and more.</p>
                                <p class="text-center text-size" v-if="selectedPaymentMethod == 'saveCard' ">You're Paying with <strong><i class="fa fa-credit-card-alt" aria-hidden="true"></i> {{ bank_card }} {{ card_type }} card </strong><br>ending with <strong>{{ card_last4}}</strong></p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number" v-if="this.selectedPaymentMethod == 'wallet'">
                  <button id="submit4" class="btn btn-primary" @click="walletPay()" v-if="!isPay">pay now (₦{{ payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}})</button>
                  <button class="btn btn-primary" type="button" disabled v-if="isPay">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
                <div class="product-cart-number" v-if="this.selectedPaymentMethod == 'bankTransfer'">
                  <button id="submit4" class="btn btn-primary" @click="bankPay()" v-if="!isPay">pay now (₦{{ payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}})</button>
                  <button class="btn btn-primary" type="button" disabled v-if="isPay">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
                <div class="product-cart-number" v-if="this.selectedPaymentMethod == 'saveCard'">
                  <button id="submit4" class="btn btn-primary" @click="cardPay()" v-if="!isPay">pay now (₦{{ payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}})</button>
                  <button class="btn btn-primary" type="button" disabled v-if="isPay">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
                
            </div>

            <div class="column9" v-if="bankTransfer">
              <p class="payment-instruction">Please follow the instruction below carefully and do not refresh or leave this page.
                Payment confirmation may take up to 5 minutes
              </p>

              <div class="cart-0" v-if="bank_details != '' ">
                  <div class="product">
                    <div class="iframe-container">
                      <iframe :src="transaction_url"></iframe>
                    </div>
                      <!-- <div class="product-detail">
                          <div class="product-name">
                              <p class="cart-title-t"><u>Transfer to the following account</u></p>
                              <p class="brand-t">Bank Name: <span>{{payment_bank_name}}</span> </p>
                              <p class="brand-t">Account Number: <span>{{payment_account_number}}</span> </p>
                              <p class="brand-t">Beneficiary Name: <span>{{payment_account_name}}</span> </p>
                              <hr>
                              <p class="brand-t">USSD Code Transfer: <span>{{ussd_code}}</span> </p>
                              <hr>
                              <span style="font-size: 9px;">Transfer exact amount to avoid failure</span>
                              <p class="brand-t">Amount to pay: <span> ₦{{payment_amount_payment}}</span> </p>
                              <p class="brand-t">This account expires after {{ accountDurationSeconds }}mins.</p>
                          </div>
                          <div></div>
                          <div class="payment-made">
                            <button id="submit4" class="btn btn-default" @click="paymentMade()" v-if="processBtn">I have paid</button>
                            <div v-if="process">
                              <center><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></center>
                              <p style="text-align: center;">We are waiting for your payment</p>
                            </div>
                            <p style="text-align: center;" v-if="processBtn">Once transfer is successful, click the button above</p>
                          </div>
                      </div> -->
                  </div>  
              </div>
              <div class="cart-0" v-if="bank_details == '' ">
                <div class="generating-account">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generating payment details. Please wait...
                </div>
              </div>
            </div>
            <div class="column9" v-if="cardPayment">
              <p class="payment-instruction">Please follow the instruction below carefully and do not refresh or leave this page.
                Payment confirmation may take up to 5 minutes
              </p>

              <div class="cart-0" v-if="charge_details != '' ">
                  <div class="product">
                    <div class="iframe-container">
                      <!-- <iframe :src="transaction_url"></iframe> -->
                    </div>
                     
                  </div>  
              </div>
              <div class="cart-0" v-if="charge_details == '' ">
                <div class="generating-account">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Initiating payment. Please wait...
                </div>
              </div>
            </div>
        </div>
        <div class="terms" v-if="!bankTransfer">
            <p>By tapping on 'PAY NOW' you have accept our Terms & Conditions and Privacy Policy</p>
        </div>
       
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  export default {
    
    name: "SchedulePayment",
    components: {
        //quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          address: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          transaction_url: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL+'products/',

          payment_bank_name: '',
          payment_account_name: '',
          payment_account_number: '',
          payment_amount_payment: '',
          ussd_code: '',
          transaction_reference: '',
          transaction_details: '',
          isSending: false,
          //duration: 20 * 60 * 1000, // 20 minutes in milliseconds
          interval: 10000, // 30 seconds in milliseconds
          timer: null, 
          loading3: false,
          btnConfirmOrder: false,
          total_amount: '',
          selectedPaymentMethod: '',
          paymentPad: true,
          bankTransfer: false,
          cardPayment: false,
          walletBal: '',
          isPay: false,
          accountDurationSeconds: '',
          bank_details: '',
          processBtn: true,
          process: false,
          is_order_summary: false,
          payment_detail: [],
          payment_mode: '', // one off / installment payment
          checkout_amount: '',
          payment_installment: '',
          paymentid: '',
          order_reference: '',
          order_id: '',
          payment_scheduleid: '',
          payment_count: '',
          payment_amount: '',
          payment_duration: '',

          user_payment_card: '',
          card_details: '',
          bank_card: '',
          card_type: '',
          card_last4: '',
          authorization_code: '',
          charge_details: '',
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

       
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.address = localStorage.getItem("address");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
               
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        
        this.fetchPaymentInformation(this.$route.params.paymentid) 
        this.fetchUserCard(this.user_id)            
    },
  
    created(){
          
    },
  
    computed: {
   
    },
  
    methods: {

        fundAccount(){
          this.$router.push('/myaccounts')
        },

        selectPay(){
            if (this.selectedPaymentMethod == 'wallet') {
              this.get_wallet_balance()
            }
        },

        async fetchUserCard(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-card', {
                  userid: userid,
                })

                this.user_payment_card = response.data.card
                this.bank_card = this.user_payment_card[0].bank
                this.card_type = this.user_payment_card[0].card_type
                this.card_last4 = this.user_payment_card[0].last4
                this.authorization_code = this.user_payment_card[0].authorization_code
                    
            } catch (err) {
              console.log(err);
          }
        },

        async generate_payment_detail(){
                try{

                        let amount = this.payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })
                        let product_amount = amount.split(",")
                        let p_amount = product_amount[0]+''+product_amount[1];

                        const response = await this.$http.post('/generate-payment-account', {
                            userid: this.user_id,
                            firstname: this.firstname,
                            lastname:  this.lastname, 
                            email: this.email_address,
                            amount: p_amount,
                        });
                            
                        this.bank_details = response.data.transfer_data
                        this.transaction_reference = this.bank_details.reference
                        this.transaction_url = this.bank_details.authorization_url
                        window.location.href = `${this.transaction_url}`
                        return;              
                        
                }catch (err) {
                    console.log(err);
                }
        },

        paymentMade(){
                try{
                 
                    this.processBtn = false
                    this.process = true
                    this.timer = setInterval(() => {
                        this.checkTransactionStatus();
                    }, this.interval);
                   this.loading3 = false
                                 
                }catch (err) {
                    console.log(err);
                }
        },

        async checkTransactionStatus(){
            try{

                    this.response = await this.$http.post('/payment-made', {
                        transaction_reference: this.transaction_reference,
                    });

                    if (this.response.data.success) {
                                           
                        this.transaction_details = this.response.data.transaction_data
                        this.transaction_success = this.transaction_details['requestSuccessful']
                        this.transaction_success_code = this.transaction_details['responseCode']
                        this.transaction_payment_status = this.transaction_details['responseBody']['paymentStatus']
                        if (this.transaction_payment_status == 'PAID') {                         
                            // update order table
                            await this.$http.post('/make-schedule-payment', {
                              userid: this.user_id,
                              orderid: this.order_id,
                              selectedPaymentMethod: this.selectedPaymentMethod,
                              order_ref: this.order_reference,
                              payment_scheduleid: this.payment_scheduleid,
                              payment_count: this.payment_count,
                              payment_amount: this.payment_amount,
                              payment_duration: this.payment_duration 
                            })
                            
                            this.showSplashMessage("Payment successful!")    
                            
                            this.stopSendingRequests() 
                                                  
                            return;
                            
                        }
                        else {
                            // do nothing
                        }
                        return;              
                }            
            }catch (err) {
                console.log(err);
            }
        },

        stopSendingRequests() {
            clearInterval(this.timer);
            this.isSending = false;
        },

        async fetchPaymentInformation(payment_scheduleid) {
          try {

              const response = await this.$http.post('/fetch-paymentinfo', {
                payment_scheduleid: payment_scheduleid
              })

              this.payment_detail = response.data.payment_info;
              this.order_reference = this.payment_detail[0].orderref
              this.payment_mode = this.payment_detail[0].payment_mode
              this.order_id = this.payment_detail[0].orderid
              this.payment_scheduleid = this.payment_detail[0].payment_scheduleid
              this.payment_count = this.payment_detail[0].payment_count
              this.payment_amount = this.payment_detail[0].amount,
              this.payment_duration = this.payment_detail[0].duration,

              localStorage.setItem('order_reference', this.order_reference)
              localStorage.setItem('payment_mode', this.payment_mode)
              localStorage.setItem('order_id', this.order_id)
              localStorage.setItem('payment_scheduleid', this.payment_scheduleid)
              localStorage.setItem('payment_count', this.payment_count)
              localStorage.setItem('payment_amount', this.payment_amount)
              localStorage.setItem('payment_duration', this.payment_duration)
              localStorage.setItem('selectedPaymentMethod', this.selectedPaymentMethod)
              localStorage.setItem('schedule_pay', 1)
              //console.log(this.payment_detail)
              this.calculateOrder()

          } catch (err) {
              console.log(err);
          }
        },

        calculateOrder(){
            this.total_amount = this.payment_detail.reduce(
                (accumulator, item) => accumulator + item.unit_price * item.qty,
                0
            );

            if (this.payment_mode == 0) {
             
              this.checkout_amount = this.total_amount / this.payment_detail[0].duration
            }
            else if (this.payment_mode == 1) {
              this.checkout_amount = this.total_amount 
            }
        },
        
       async walletPay(){
        try {

                  let amount = this.payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })
                  let product_amount = amount.split(",")
                  let p_amount = product_amount[0]+''+product_amount[1];

                  this.isPay = true
                  const response = await this.$http.post('/check-wallet-balance1', {
                      userid: this.user_id,
                      amount: p_amount
                    })
                    this.isPay = false

                    if (!response.data.success) {
                      swal.fire({
                            icon: 'warning',
                            title: 'Low Balance',
                            width:450,
                            height:5,
                            text: response.data.message,
                        });
                        return;
                    } else {
                      
                      const response2 = await this.$http.post('/make-schedule-payment', {
                        userid: this.user_id,
                        orderid: this.order_id,
                        selectedPaymentMethod: this.selectedPaymentMethod,
                        order_ref: this.order_reference,
                        payment_scheduleid: this.payment_scheduleid,
                        payment_count: this.payment_count,
                        payment_amount: this.payment_amount,
                        payment_duration: this.payment_duration 
                      })

                      if (response2.data.success) {
                        
                        this.showSplashMessage("Payment successful!")                       
                        return;
                    }
                }
            
            } catch (err) {
            console.log(err);
            }
       },

       async bankPay(){
        try {
                this.isPay = true
                this.paymentPad = false
                this.bankTransfer = true
                this.generate_payment_detail()
                this.isPay = false
            
            } catch (err) {
            console.log(err);
            }
       },

       async cardPay(){
        try {
                this.isPay = true
                this.paymentPad = false
                this.bankTransfer = true
                this.cardCharge()
                this.isPay = false
            
            } catch (err) {
            console.log(err);
            }
       },

       async cardCharge(){
        try{
            let amount = this.payment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })
            let product_amount = amount.split(",")
            let p_amount = product_amount[0]+''+product_amount[1];
            const response = await this.$http.post('/charge-user-card', {
                email: this.email_address,
                amount: p_amount,
                authorization_code: this.authorization_code
            });
                
            this.charge_details = response.data.transfer_data
            if(response.data.status == 'failed'){
                swal.fire({
                    icon: "warning",
                    title: response.data.message,
                    width: 450,
                    height: 5,
                    text: response.data.gateway_message,
                  });
                  this.$router.push('/shopping')
                  return;
              }
              else{
                swal.fire({
                    icon: "success",
                    title: response.data.message,
                    width: 450,
                    height: 5,
                    text: response.data.gateway_message,
                  });

                  await this.$http.post('/make-schedule-payment', {
                    userid: this.user_id,
                    orderid: this.order_id,
                    selectedPaymentMethod: this.selectedPaymentMethod,
                    order_ref: this.order_reference,
                    payment_scheduleid: this.payment_scheduleid,
                    payment_count: this.payment_count,
                    payment_amount: this.payment_amount,
                    payment_duration: this.payment_duration 
                  })
                  
                  this.showSplashMessage("Payment successful!") 
                  
                  this.$router.push('/shopping')
                  return;
              }
                      
          }catch (err) {
            console.log(err);
          }
       },

       async get_wallet_balance(){
                try{
                        const response = await this.$http.post('/fetch-wallet-balance', {
                            userid: this.user_id,
                        });
                        this.walletBal = response.data.balance
                        return;              
                        
                }catch (err) {
                    console.log(err);
                }
       },

      showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
            this.$router.push('/myaccounts')
        }, 2000); // Hide the message after 2 seconds
    },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .iframe-container {
    position: relative;
    width: 100%;
    height: 500px; /* Set the height as needed */
    overflow: hidden;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    border-bottom: solid 1px #800080c7;
    color: #800080c7;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .terms{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-family: Mulish;
    font-size: 13px;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
    color: #626060;
    text-transform: uppercase;
    font-weight: normal;
  }
  .address{
    color: #800080c7;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.generating-account{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size:15px;
}
.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #800080c7;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.3rem;
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #800080c7;
        font-size: 12px;
      }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #800080c7;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    width: 100%;
  }
  .payment-span{
    margin: 8px;
    font-family: Mulish;
    font-size: 12px;
    text-transform: capitalize !important;
  }
  .form-check-label{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .payment-method{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 15px;
    font-size: 12px;
  }
  .product-name{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    margin-right: 10px;
    width: 100%;
    .cart-title{
        margin-top: -10px;
        font-size: 14px;
        font-weight: bold;
        color: #6e6c6c;
        text-transform: capitalize;
    }
    .cart-title-t{
      margin-top: -10px;
      font-size: 12px;
      font-weight: bold;
      color: #6e6c6c;
  }
    .brand{
        margin-top: 2px;
        font-size: 18px;
        font-weight: bold;
        span{
          font-weight: bold;
          font-size: 15px;
        }
    }
    .brand-t{
      margin-top: 2px;
      font-size: 10px;
      font-weight: 500;
      span{
        font-weight: bold;
        font-size: 13px;
      }
  }
   
  }
  .center-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .center-p-0{
    p{
      font-size: 12px;
      text-align: center;
      font-weight: normal;
    }
    .text-size {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .payment-made{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    font-size: 12px;
    border-radius: 4px;
    border: solid 10px #a5a3a3;
    .btn-default{
      color: #fff;
      background: rgb(42, 136, 230);
      font-size: 12px !important;
    }
    p{
      color: #a5a3a3;
    }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #800080c7;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .payment-instruction{
    color: #a5a3a3;
    font-size: 12px;
    text-align: center;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;

}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
    margin-bottom: 14px;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .product-detail{
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      width: 100%;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
     
      .column9{
        width: 100%;
      }
      .bank-transfer{
        padding: 1.9rem;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>