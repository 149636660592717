<template>
    <div>
        <div class="package-header">
            <p>&nbsp;</p>
            <p class="package-title">MY REFERRALS</p>
            <p>&nbsp;</p>
        </div>
        <p>&nbsp;</p>
        <div class="row package-container">
            <div v-if="referrals == ''" class="no-referral">
                <span><i class="fa fa-user not-available"></i></span>  <span>&nbsp;No referral available!</span>
             </div>
            <div class="col-sm-12" v-else>
                <div class="product table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <th>SN</th>
                            <th>Referrals</th>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in referrals" :key="index">
                                <td style="width: 20px;">
                                   <div class="counter"> {{ index +1 }}</div>
                                </td>
                                <td>
                                  <div class="user-profile">
                                     <div class="profile-name1">
                                        <img src="5.png" alt="" width="35px">
                                     </div> 
                                     <div class="profile-name">
                                        <span>Name: <strong>{{ data.fullname }}</strong></span>
                                        <span>Date Joined: <strong>{{ new Date(data.created_at).toDateString() }}</strong></span>
                                        <span>Status:  <span v-if="data.plan_active_status == 0" class="badge badge-danger">Not Active</span>
                                                    <span v-else class="badge badge-success">Active</span>
                                     </span>
                                     </div>
                                  </div>
                                </td>
                               </tr>
                        </tbody>
                    </table>
                </div>   
            </div>
        </div>   
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    //import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
        name: "ReferralsPage",
        components: {
            PageLoader
        },
      
        data(){
          return {
    
              isLoading: false,
              user_id: '',
              firstname: '',
              lastname: '',
              bank_name:'',
              account_name:'',
              account_number: '',
              ussd: '',
              exp_time: '',
              referral_code: '',
              loading: false,
              plans: [],      
              transfer_data: [], 
              current_plan: '',   
              referrals: '',
          };
        },
      
        beforeMount(){
         
        },
      
        mounted(){
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
            this.referral_code = localStorage.getItem("referral_code");
            this.fetchReferrals()
            //this.fetchCurrentPlan(this.user_id)
        },
      
        created(){
              
        },
      
        computed: {
        
        },
      
        methods: {
        
           generateRandomId() {
              return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
           },
    
            setShowBtn(index, value) {
              this.$set(this.showBtn, index, value);
            },
    
            async fetchReferrals() {
                try {
    
                   this.isLoading = true
                   const response =  await this.$http.post('/fetch-referrals', {
                        referral_code: this.referral_code
                    })
                    this.isLoading = false
                    this.referrals = response.data.referrals
                        
                } catch (err) {
                  console.log(err);
              }
            },
        }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    thead{
      text-align: left;
    }
    .package-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #ccc;
      }
      .package-title{
        font-size: 30px;
        font-weight: bold;
        font-family: Mulish;
        color: #800080;
      }
    .package-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.9rem;
        background: #fff;
        border-radius: 4px;
        
        a{
            text-decoration: none;
            color: #000;
        }
      }
      .product{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0.3rem;
        font-family: Mulish;
        font-size: 12px;
        border: solid 0px #ccc;
        margin-bottom: 40px;
        //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
      }
      .no-referral{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: Mulish;
      }
      .not-available{
        font-size: 80px !important;
        color: #ccc !important;
      }
      .user-profile{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .profile-name1{
        padding: 0.2rem;
        border: 1px solid #800080;
      }
      .profile-name{
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 0.2rem;
        font-family: Mulish;
        font-size: 10px;
      }
      .counter{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
      }
    
      @media screen and (max-width: 768px) {
        .package-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
          }
    
          .product{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.7rem;
            font-family: Mulish;
            margin-left: 10px;    
          }
          .profile-name{
            display: flex;
            flex-direction: column;
            justify-content: left;
            padding: 0.4rem;
            font-family: Mulish;
            font-size: 10px;
          }
      }
    </style>