<template>
    <div>
        
        <div class="row account-details">
          <div class="col-sm-3">  
          </div>
          <div class="col-sm-6">
            <div class="progress" style="height: 4px;">
              <div class="progress-bar bg-secondary" role="progressbar" :style="{ width: step_value }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="counter">
              <p class="step-title">{{ step_title }}</p>
              <p>{{ step_number }} <span>of 2 Steps</span></p>
            </div>
            <div class="inputs" v-if="step_1">
                <!-- <label for="">Bank *</label> -->
                <v-autocomplete
                  required
                  v-model="user_bank_name"
                  label="Bank *"
                  :items="banks"
                  variant="outlined"
                ></v-autocomplete>
               
                <label for="">Account Number *</label>
                <input type="text" class="form-control inputs-field" placeholder="Enter account number" v-model="user_account_number" required>
            </div>
            <div class="inputs" v-if="step_2">
              <label for="">Beneficiary</label>
              <input type="text" class="form-control inputs-field" v-model="beneficiary" readonly>
             
              <input type="hidden" class="form-control inputs-field" v-model="beneficiary_bank" required>
             
              <label for="">Account Number</label>
              <input type="text" class="form-control inputs-field" v-model="beneficiary_account_number" readonly>
          </div>
            <div class="inputs" v-if="submit_1">
              <button id="submit1" class="btn btn-danger btn-block" :disabled="isBankValid" @click="validateBank()" v-if="!loading">next step</button>
              <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
            <div class="inputs" v-if="submit_2">
                <button id="submit2" class="btn btn-danger btn-block"  @click="createBank()" v-if="!loading2">save</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading2">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="inputs table table-responsive">
              <h6>Bank List</h6>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Beneficiary</th>
                    <th>Bank</th>
                    <th>Account No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in user_bank_detail" :key="index">
                    <td>{{ data.account_name}}</td>
                    <td>{{ data.bank_name}}</td>
                    <td>{{ data.account_number}}</td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-3">
          </div>
        </div>
       
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  export default {
    
    name: "BankPage",
    components: {
  
    },
  
    data(){
      return {
          user_bank_name: '',
          user_account_number: '',
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          isAccountDetail: false,
          isAddfund: false,
          loading: false,
          loading2: false,

          beneficiary:'',
          beneficiary_bank: '',
          beneficiary_account_number: '',

          user_bank_detail: '',
          bname: '',

          banks: [],
          step_value: '',
          stepTitle: [
            {
                step1: "Input bank detail",
                step2: "Save record ",
            }
        ],
        stepValues: [
            {
                value1: "50%",
                value2: "100%",
            }
        ],
        step_1: true,
        step_2: false,
        step_number: 1,
        submit_1: true,
        submit_2: false,
        user_detail: '',
        verify_bank: '',
        b_name:'',
        b_code: '',
        baccount_name: '',
        baccount_number: '',

      };
    },
  
    beforeMount(){
      this.step_title = this.stepTitle[0].step1
      this.step_value = this.stepValues[0].value1
    },
  
    mounted(){

        this.fetchAllBanks()

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.fetchUserBank(this.user_id)
        this.fetchUserDetails(this.user_id)
    },
  
    created(){
          
    },
  
    computed: {
      isBankValid() {
        return (
          this.user_bank_name ==='' ||
          this.user_account_number === ''
        );
      },
    },
  
    methods: {
        async fetchUserDetails(userid){
          try {

            const response =  await this.$http.post('/fetch-user-byid', {
              userid: userid,
            })

            this.user_detail = response.data.users
            this.verify_bank = this.user_detail[0].verify_bank
                
            } catch (err) {
            console.log(err);
            }

        },
        async validateBank() {
            try {

               this.loading = true

               let arrData = this.user_bank_name.split('-')
               let bcode = arrData[0]
               this.bname = arrData[1]

               const response =  await this.$http.post('/validate-bank', {
                  account_number: this.user_account_number,
                  bank_code: bcode,
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Invalid data. Check your bank or account number',
                    })
                    return;
                }

                this.beneficiary = response.data.bank_detail.accountName
                this.beneficiary_bank = response.data.bank_detail.bankCode
                this.beneficiary_account_number = response.data.bank_detail.accountNumber

                this.submit_1 = false,
                this.submit_2 = true
                this.step_2 = true
                this.step_1 = false
                this.step_number = 2
                this.step_title = this.stepTitle[0].step2
                this.step_value = this.stepValues[0].value2
               
            } catch (err) {
              console.log(err);
        }
        },

        async fetchAllBanks() {
            try {

                this.loading2 = true

               const response =  await this.$http.get('/get-banks', {
                })

                this.loading2 = false
                let arrData = [];
                arrData = response.data.bank.responseBody 
                arrData.forEach(el => {
                  this.banks.push({
                    text: el.name,
                    value: el.code + '-' + el.name
                  })
                });                     
               
            } catch (err) {
              console.log(err);
          }
        },

        async createBank() {
            try {

                this.loading2 = true

                let verify_msg = confirm("   You'll be required to transfer the sum of NGN50.00 to verify your account");

                  if (verify_msg == true) {
                    if (this.verify_bank == 0) {
                    this.$router.push("/payment")

                    localStorage.setItem('bname', this.bname)
                    localStorage.setItem('bcode', this.beneficiary_bank)
                    localStorage.setItem('baccount_name', this.beneficiary)
                    localStorage.setItem('baccount_number', this.beneficiary_account_number)

                    return;
                  }

                  if (this.verify_bank == 1) {
                    swal.fire({
                          icon: 'warning',
                          title: 'Warning',
                          width: 450,
                          height: 5,
                          text: 'Bank already verified!',
                      })
                    return;
                  }
                }

                this.loading2 = false              
               
            } catch (err) {
              console.log(err);
        }
        },

        async fetchUserBank(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-bank', {
                  userid: userid,
                })

                this.user_bank_detail = response.data.bank
                    
            } catch (err) {
              console.log(err);
          }
        },

        async removeBank(bankid){
        let con = confirm("          You're about to remove this bank. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/remove-bank', {
                bankid: bankid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchUserBank(this.user_id)
                return;

              } catch (err) {
              console.log(err);
              }
          }
        },

        async select_package(planid, plan_name, amount){
          if(this.withdrawalCount < 3){
              alert("                      You have an existing package running!")
          }else{
            this.$router.push("/package/"+planid+'/'+plan_name+'/'+amount)
          }
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  th{
    font-weight: 400;
  }
  label{
    font-size: 13px;
    font-family: Mulish;
  }
  .counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 4px;
    height: 30px;
    border-radius: 3px;
    margin-bottom: 10px;

    span{
        color: #ccc;
    }
    .step-title{
        font-weight: 400;
    }
}

.inputs{
  margin-top: 20px;
  border-radius: 3px;
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: Mulish;
  background: #800080c7;
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.9rem;
    margin: 4px;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: Mulish;
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: Mulish;
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  
  @media screen and (max-width: 768px) {
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: Mulish;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //border: solid 1px #170e0fac;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: Mulish;
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: Mulish;
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 13px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>