<template>
    <div class="container-fluid">
        <nav class="breadcrumbs" v-if="products != ''">
            <ul>
              <li><router-link to="/shopping">Home</router-link></li>
              <li>
                <router-link  to="">{{ products[0].category_name }}</router-link>
              </li>
              <li>
                <router-link to="">{{ products[0].subcategory_name }}</router-link>
              </li>
            </ul>
        </nav>
        <div v-if="products != ''">
            <div class="row product-container">
                <div class="col-sm-2" v-for="(data, index) in products" :key="index">
                    <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                    <div class="product" >
                        <img class="img" :src="img_url + data.product_image" alt="">
                        <div class="product-name">
                            {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                        </div>
                        <div class="product-price">
                            NGN{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                        </div>
                    </div>   
                    </router-link>
                </div>
            </div> 
            <div class="product-container-mobile">
              <div class="mobile-item" v-for="(data, index) in products" :key="index">
                  <router-link :to="'/item/' + data.productid + '/' + data.product_name">
                      <div class="product-mobile" >
                          <img class="img" :src="img_url + data.product_image" alt="">
                          <div class="product-name">
                              {{ data.product_name.split(' ').slice(0, 3).join(' ') + '...' }}
                          </div>
                          <div class="product-price">
                              NGN{{ data.product_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                          </div>
                      </div>   
                  </router-link>
              </div>
            </div>  
        </div>
        <div v-else>
            <div class="cart-0">
                <div class="empty-cart">
                    <i class="fa fa-search"></i>
                    <p class="cart-e"></p>
                    <p class="cart-e2">No result found! </p>
                </div>
            </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
  
  //import 'quill/dist/quill.snow.css';
  //import { quillEditor } from 'vue-quill-editor';
  import PageLoader from '../components/PageLoader.vue'
  import swal from 'sweetalert2';
  export default {
    
    name: "ShoppingPage",
    components: {
        PageLoader,
    },
  
    props: {
          products: Array,
          isLoading: Boolean
    },
  
    data(){
      return {
  
          //isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
               
          shops: [],
          brands: [],
          //products: [],
          categorys: [],
          subcategorys:[],
          //img_url: process.env.VUE_APP_IMAGE_URL,
          img_url: process.env.VUE_APP_IMAGE_URL+'products/',
          cart_id: '',
          
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){
  
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.fetchcategorys()
        this.fetchshops()
        this.fetchbrands()
        //this.searchProducts(this.$route.params.criteria)
  
        if (!this.cart_id) {
          this.cart_id = this.generateRandomId();
          localStorage.setItem('cart_id', this.cart_id);
        }
    },
  
    created(){
          
    },
  
    computed: {
    
    },
  
    methods: {
  
       generateRandomId() {
          return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
       },
  
        setShowBtn(index, value) {
          this.$set(this.showBtn, index, value);
        },
  
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })
  
            this.categorys = response.data.categories;
                                   
            } catch (err) {
              console.log(err);
          }
        },
  
        async fetchshops() {
            try {
               const response =  await this.$http.get('/fetch-all-shops', {
            })
                response.data.shops.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },
  
        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
            this.brands = response.data.brands              
            } catch (err) {
              console.log(err);
          }
        },
  
        async fetchProducts() {
            try {
               this.isLoading = true
               const response =  await this.$http.get('/fetch-all-products', {
            })
                this.isLoading = false
                this.products = response.data.products
                    
            } catch (err) {
              console.log(err);
          }
        },
  
        async removeCategory(categoryid){
        let con = confirm("          You're about to remove this category. Are you sure?")
          if (con == true) {
            try {
  
              const response =  await this.$http.post('/delete-subcategory', {
                subcategory_id: categoryid,
              })
  
              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }
  
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchProducts()
                return;
  
              } catch (err) {
              console.log(err);
              }
          }
        },

        async searchByCategory(categoryName) {
            try {
                this.isLoading = true;
                const response = await this.$http.post('/search-all-products',{
                    searchTerm: categoryName
                });
                this.isLoading = false;
                this.products = response.data.products;
                
                // Initial load: display all products
                this.filteredProducts = this.products;

            } catch (err) {
                console.log(err);
            }
        },
        async searchBySubcategory(subcategoryName) {
            try {
                this.isLoading = true;
                const response = await this.$http.post('/search-all-products',{
                    searchTerm: subcategoryName
                });
                this.isLoading = false;
                this.products = response.data.products;
                
                // Initial load: display all products
                this.filteredProducts = this.products;

            } catch (err) {
                console.log(err);
            }
        }
  
    }
  
  }
</script>
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  
  .container-fluid{
  
  }
  .cart-0{
    height: 100vh;
  }
  .empty-cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    //background: #fff;
  }
   /* Breadcrumbs container */
 nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }
  
  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }
  
  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }
  
  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }
  
  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }
  
  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }
  .inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    //height: 100vh;
    
    a{
        text-decoration: none;
        color: #000;
    }
  }
  .product-container-mobile{
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    background: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    
    a{
        text-decoration: none;
        color: #000;
    }
  }
  
  .product{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 210px;
    padding: 0.3rem;
    font-family: Mulish;
    border: solid 1px #ccc;
    border-radius: 4px;
    margin-bottom: 4px;
    .product-name{
        margin-top: 10px;
        padding: 0.2rem;
        font-size: 10px;
        font-family: Mulish;
        text-align: center;
      }
      .product-price{
        padding: 0.2rem;
        font-size: 10px;
        font-weight: bold;
        font-family: Mulish;
        margin-top: -5px;
        text-align: center;
      }
  }
  .product:hover{
    border: solid 1px #80008070;
    box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
  }
  
  .product-mobile{
    display: none;
    flex-direction: column;
    width: 100%;
    height: 210px;
    padding: 0.3rem;
    font-family: Mulish;
    border: solid 1px #ccc;
    border-radius: 4px;
    margin-bottom: 4px;

    .product-name{
        margin-top: 10px;
        padding: 0.2rem;
        font-size: 10px;
        font-family: Mulish;
        text-align: center;
      }
      .product-price{
        padding: 0.2rem;
        font-size: 10px;
        font-weight: bold;
        font-family: Mulish;
        margin-top: -5px;
        text-align: center;
      }
  }
  .product-mobile:hover{
    border: solid 1px #80008070;
    box-shadow: 1px 1px 10px rgba(14, 112, 31, 0.2); 
  }
  .button-cart{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .add-cart{
    height: 40px;
    background: #071278;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    bottom: 0;
    position: absolute;
    width: 84%;
  
  }
  
  .img {
    cursor: pointer;  
    height:145%;
    border-radius: 4px;
  }
  
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
  }
  @media screen and (max-width: 768px) {
  
    .product-container{
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      
      .empty-cart{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10rem;
        font-family: Mulish;
        font-size: 12px;
     }
      .product-container-mobile{
        display: flex;
        flex-wrap:wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.4rem;
        background: #fff;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 4px;
        
        a{
            text-decoration: none;
            color: #000;
        }
      }
      .mobile-item{
        width: 50%;
        padding: 2px;
        box-sizing: border-box;
      }
  
      .product{
        display: none;
        flex-direction: column;
        align-items: center;
        padding: 0.8rem;
        font-family: Mulish;
      }
  
      .product-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.8rem;
        font-family: Mulish;
      }
  
      .img {
        cursor: pointer;  
        height:100%;
        width: 100%;
      }
    
    .inputs{
      font-size: 12px;
    }
  
  
  }
  
</style>