<template>
  <div>
    <div id="splash-message" class="splash-message"></div>
      <div class="product-container">
          <div class="column9">
              <div class="product-cart-number">
                  Cart({{ cart_length }})
              </div>
              <div v-if="cart_items == ''">
                <div class="cart-0">
                    <div class="empty-cart">
                        <i class="fa fa-shopping-bag"></i>
                        <p class="cart-e">Your cart is empty </p>
                        <p class="cart-e2">Shop from our collections </p>
                       <router-link to="shopping">
                          <button class="btn btn-shopping">start shopping</button>
                       </router-link>
                    </div>
                </div>
              </div>
              <div v-else>
                <div class="cart-0" v-for="(data, index) in cart_items" :key="index">
                  <div class="product">
                      <div class="product-img">
                          <img class="img" :src="img_url + data.product_image" alt="">
                      </div>
                      <div class="product-detail">
                          <div class="product-name">
                              <p class="cart-title">{{ data.product_name}}</p>
                              <div class="variation-style" v-if="data.selected_variation !=''">
                                <p class="brand">Variation: {{ data.selected_variation }}  </p>
                              </div>
                             
                              <p class="in-stock" v-if="data.in_stock == 1">In stock</p>
                              <p class="in-stock text-danger" v-if="data.in_stock == 0">Out of stock</p>
                          </div>
                      </div>
                      <div class="product-price">
                          <p class="price">₦{{ data.unit_price.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })}}</p>
                      </div>
                  </div>
                  <div class="product-counter-remove">
                     <button class="btn-remove" @click="removeItem(data.cartid)"><i class="fa fa-trash"></i> Remove</button>
                      <div class="counter">
                          <button class="btn-counter" @click="decrement(data)" > <span class="counter-action">-</span> </button> 
                          <input type="text" v-model="data.qty" class="login-modal-box2" readonly> 
                          <button class="btn-counter" id="btn-counter1" @click="increment(data)"> <span class="counter-action">+</span> </button>
                      </div>
                  </div> 
                </div>
                <div class="choose-payment-terms">
                  <div class="payment-term-title">
                   How do you want to pay for this item(s)?
                  </div>
                  <div class="payment-terms">
                    <div class="payonce"> <input type="checkbox" name="" id="" v-model="pay_once" @change="payOnce()">&nbsp; Pay once</div>
                    <div v-if="isInstallment" class="split-plan">
                        <v-autocomplete
                        required
                        v-model="duration_id"
                        label="-Split Payment-"
                        :items="durations"
                        variant="outlined"
                        dense
                        style="font-size: 10px;"
                        @change="fetchInstallments()" 
                      ></v-autocomplete>
                    </div>
                    <div v-if="isDuration" class="split-plan">
                      <v-autocomplete
                      required
                      v-model="installment_duration"
                      label="-Select duration-"
                      :items="installments"
                      variant="outlined"
                      dense
                      style="font-size: 10px"
                      @change="validateInput()" 
                    ></v-autocomplete>
                    </div>
                  </div>
                </div> 
                <div class="payment-schedule" v-if="isSchedule">
                  <div><span class="schedule" @click="payment_plan()"><i class="fa fa-file"></i> see payment schedule <i class="fa fa-arrow-right"></i></span> </div>
                </div> 
              </div>    
                                 
          </div>

          <div class="column3">
            <div class="delivery-status">
              <div class="delivery-return">
               cart summary
              </div>
              <div  class="d-info">
                <span v-if="cart_items == ''">
                  ₦{{ schedule_payment.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }}
                </span>

                <span v-else>
                  <span v-if="!isSchedule">
                    You will be paying ₦{{ schedule_payment.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }} out of the total amount of ₦{{ total_amount}}
                  </span>
                  <span v-if="isSchedule">
                    You've chosen to pay ₦{{ schedule_payment.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }}{{ payment_title }} {{ weekly_duration }} {{ weeks }} totaling ₦{{ total_amount}} <br>
                    Please ensure your {{ payment_title }} payments are made on time, as late payments incur charges.
                  </span>
                </span>
               
              </div>
              <div class="check-out">
                <button id="submit4" class="btn btn-primary" :disabled="isCartEmpty" @click="checkout()" v-if="!loading">check out (₦{{ schedule_payment.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })}})</button>
                <button class="btn btn-primary" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
      </div>  
      <div v-if="isPaymentPlans">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                              <div class="account-content-h">
                                 payment schedule
                              </div>
                              <div class="account-content-d">
                                <table class="table table-bordered table-striped">
                                  <thead>
                                    <th>
                                      Period
                                    </th>
                                    <th>
                                      Date
                                    </th>
                                    <th>
                                      Amount(NGN)
                                    </th>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(data, index) in installmentDates" :key="index" style="font-size: 10px !important;">
                                      <td>{{ period }} {{ index + 1 }}</td>
                                      <td>{{ data.period }}</td>
                                      <td>{{ data.amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 }) }}</td>
                                    </tr>
                                  </tbody>

                                </table>
                              </div>
                             
                              <button class="btn btn-default" @click="isPaymentPlans = false"><i class="fa fa-times"></i> Close</button>
                              <p>&nbsp;</p>
                          </div>
                      </div>
                      <div class="col-md-1">                         
                      </div>
                  </div>
              </div>
          </div>
        </transition>
      </div>
      <P>&nbsp;</P> 
      <page-loader :is-loading="isLoading"></page-loader>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import PageLoader from '../components/ShopLoader.vue'
  
export default {
  
  name: "CartPage",
  components: {
      //quillEditor,
      PageLoader
  },

  data(){
    return {

        isLoading: false,
        isPaymentPlans: false,
        user_id: '',
        firstname: '',
        lastname: '',
        bank_name:'',
        account_name:'',
        account_number: '',
        
        loading: false,
       
        shops: [],
        brands: [],
        products: [],
        categorys: [],
        subcategorys:[],
        //img_url: process.env.VUE_APP_IMAGE_URL,
        img_url: process.env.VUE_APP_IMAGE_URL+'products/',
        cart_items: '',
        cart_id: '',
        cart_length: '',
        total_amount: '',
        counter: 1,
        durations: [],
        installments: [],
        duration_id: '',
        isInstallment: true,
        isDuration: false,
        isSchedule: false,
        installment_duration: '',
        pay_once: false,
        isCounter: false,
        //isCounter1: true,
        installmentDates: [],
        period: '',
        schedule_payment: '',
        payment_title: '',
        weeks: '',
        weekly_duration: '',

    };
  },

  beforeMount(){

  },

  mounted(){

      this.firstname = localStorage.getItem("firstname");
      this.lastname = localStorage.getItem("lastname");
      this.phone_number = localStorage.getItem("phone");
      this.email_address = localStorage.getItem("email");
      this.user_id = localStorage.getItem("userid");
      this.cart_id = localStorage.getItem('cart_id');

      this.bank_name = localStorage.getItem("bank_name");
      this.account_name = localStorage.getItem("account_name");
      this.account_number = localStorage.getItem("account_number");

      this.fetchCartInformation(this.cart_id) 
      this.fetchDurations()
  },

  created(){
        
  },

  computed: {
    isCartEmpty() {
      return (
        this.cart_items == ''
      );
    },
  },

  methods: {
    calculateOrder(){
          this.total_amount = this.cart_items.reduce(
              (accumulator, item) => accumulator + item.unit_price * item.qty,
              0
          );

          this.schedule_payment = this.total_amount;
      },

      increment(data) {
          data.qty++; // Increment the quantity for the specific data item
          this.calculateOrder();
          this.payment_plan1();
      },

      decrement(data) {
          if (data.qty > 1) {
              data.qty--; // Decrement the quantity for the specific data item (if it's greater than 0)
              this.calculateOrder();
              this.payment_plan1();
          }
      },
      payOnce(){
        if (this.pay_once == true) {
            this.isInstallment = false,
            this.isSchedule = false
            this.isDuration = false
            this.isCounter = true
            //this.isCounter1 = false
            this.installment_duration = ''
            this.duration_id = ''

            this.schedule_payment = this.total_amount

        }
        else {
            this.isInstallment = true,
            this.isSchedule = false
            this.installment_duration = ''
            this.duration_id = ''
            this.isDuration = false
            this.isCounter = false
            //this.isCounter1 = true

          //   if (data.qty > 1) {
          //     data.qty = 1; // Decrement the quantity for the specific data item (if it's greater than 0)
          //     this.calculateOrder();
          //  }
        }
      },

    payment_plan() {

      // weekly computation
      if (this.duration_id == 1) {

          this.installmentDates = [];
          this.period = 'Week '
          this.weeks = 'week(s)'
          this.payment_title = ' weekly';
          this.weekly_duration = ' for ' + this.installment_duration; 
        
          const totalAmount = this.total_amount; // Total amount to be paid
          const numberOfWeeks = this.installment_duration; // Number of weeks for the installment plan

          // Calculate the weekly installment amount
          const weeklyInstallment = totalAmount / numberOfWeeks;
          this.schedule_payment = weeklyInstallment;

          // Get the current date
          const currentDate = new Date();

          // Calculate the end date of the installment plan (5 weeks from the current date)
          const endDate = new Date(currentDate);
          endDate.setDate(endDate.getDate() + (7 * numberOfWeeks));

          // Calculate the installment dates and amounts

          for (let i = 0; i < numberOfWeeks; i++) {
              const installmentDate = new Date(currentDate);
              installmentDate.setDate(installmentDate.getDate() + (7 * i));
              this.installmentDates.push({'period':installmentDate.toDateString(), 'amount' :  weeklyInstallment.toFixed(2)});
          }

          this.isPaymentPlans = true
      }

      // monthly computation
      else if (this.duration_id == 2) {

        this.installmentDates = [];
        this.period = 'Month '
        this.weeks = 'month(s)'
        this.payment_title = ' monthly'
        this.weekly_duration = ' for ' + this.installment_duration; 

        const totalAmount = this.total_amount; // Total amount to be paid
        const numberOfMonths = this.installment_duration; // Number of weeks for the installment plan

        // Calculate the monthly installment amount
        const monthlyInstallment = totalAmount / numberOfMonths;

        this.schedule_payment = monthlyInstallment;

        // Get the current date
        const currentDate = new Date();

        // Calculate the installment dates and amounts
        for (let i = 0; i < numberOfMonths; i++) {
            const installmentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, currentDate.getDate());
            this.installmentDates.push({
                'period': installmentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
                'amount':  monthlyInstallment.toFixed(2)
            });
        }


            this.isPaymentPlans = true
      }
      
    },

    payment_plan1() {

      // weekly computation
      if (this.duration_id == 1) {

          this.installmentDates = [];
          this.period = 'Week '
          this.weeks = 'week(s)'
          this.weekly_duration = ' for ' + this.installment_duration; 
          this.payment_title = ' weekly';
        
          const totalAmount = this.total_amount; // Total amount to be paid
          const numberOfWeeks = this.installment_duration; // Number of weeks for the installment plan

          // Calculate the weekly installment amount
          const weeklyInstallment = totalAmount / numberOfWeeks;
          this.schedule_payment = weeklyInstallment;

          // Get the current date
          const currentDate = new Date();

          // Calculate the end date of the installment plan (5 weeks from the current date)
          const endDate = new Date(currentDate);
          endDate.setDate(endDate.getDate() + (7 * numberOfWeeks));

          // Calculate the installment dates and amounts

          for (let i = 0; i < numberOfWeeks; i++) {
              const installmentDate = new Date(currentDate);
              installmentDate.setDate(installmentDate.getDate() + (7 * i));
              this.installmentDates.push({'period':installmentDate.toDateString(), 'amount' : weeklyInstallment.toFixed(2)});
          }
      }
      // monthly computation
      else if (this.duration_id == 2) {

        this.installmentDates = [];
        this.period = 'Month '
        this.weeks = 'month(s)'
        this.weekly_duration = ' for ' + this.installment_duration; 
        this.payment_title = ' monthly'

        const totalAmount = this.total_amount; // Total amount to be paid
        const numberOfMonths = this.installment_duration; // Number of weeks for the installment plan

        // Calculate the monthly installment amount
        const monthlyInstallment = totalAmount / numberOfMonths;

        this.schedule_payment = monthlyInstallment;

        // Get the current date
        const currentDate = new Date();

        // Calculate the installment dates and amounts

        for (let i = 0; i < numberOfMonths; i++) {
            const installmentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, currentDate.getDate());
            this.installmentDates.push({'period':installmentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }), 'amount' : monthlyInstallment.toFixed(2)});
        }

      }

      },
    
    async fetchDurations() {
        try {
              const response = await this.$http.get('/fetch-durations', {
            })

            response.data.durations.forEach(el => {
              this.durations.push({
                text: el.duration,
                value: el.durationid
              })
            });
        } catch (err) {
            console.log(err);
        }
    },

    async fetchInstallments() {
        try {

            this.installments = []
            this.installment_duration = []
            this.isLoading = true;
            this.isSchedule = false
            const response = await this.$http.post('/fetch-installments', {
              duration_id: this.duration_id
            })

            this.isLoading = false
            this.installment_duration = ''
            if(this.installments == []) {
              this.isDuration = false
            }
            else{
              this.isDuration = true
            }

            if (this.duration_id == 1) {
                this.schedule_payment = this.total_amount
            }
            else if (this.duration_id == 2) {
                this.schedule_payment = this.total_amount
            }          
            
            response.data.installments.forEach(el => {
              this.installments.push({
                text: el.installment,
                value: el.installment
              })
            });

        } catch (err) {
            console.log(err);
        }
    },

    validateInput(){

      this.payment_plan1()

      if (this.duration_id == '') {
            swal.fire({
              icon: "success",
              title: "Success",
              width: 450,
              height: 5,
              text: 'Please select installment',
            });
            this.isSchedule = false
            return;
      }
      else{
        this.isSchedule = true
      }

    },

    async fetchCartInformation(cart_id) {
        try {

            this.isLoading = true;
            const response = await this.$http.post('/fetch-cart-byunique_code', {
              cart_unique_code: cart_id
            })

            this.cart_items = response.data.cart;
            this.cart_length =  this.cart_items.length
            this.$store.dispatch('updateCartCount', this.cart_items.length);
            this.calculateOrder()
            this.isLoading = false;

        } catch (err) {
            console.log(err);
        }
    },

    async removeItem(cartid){
      let msg = confirm("     You are about to remove this item from the cart. Are you sure?");
        if(msg==true){
          
        try {
            const response = await this.$http.post('/delete-cart', {
              cartid: cartid
            })

            if (response.data.success) {
              this.showSplashMessage("Item successfully removed")
              this.fetchCartInformation(this.cart_id)  

              return;
          }

        } catch (err) {
            console.log(err);
        }
      }
    },

    async checkout(){
      try {
            
            if (this.pay_once == false) {
                this.pay_once = ''
                if (this.duration_id == '') {
                  swal.fire({
                    icon: "info",
                    title: "Info",
                    width: 450,
                    height: 5,
                    text: 'Please select payment mode',
                  });
                  return;
                }
                if (this.installment_duration == '') {
                  swal.fire({
                    icon: "info",
                    title: "Info",
                    width: 450,
                    height: 5,
                    text: 'Please select duration',
                  });
                  return;
                }

                const middleValues = this.getMiddleValues(this.installmentDates);

                this.loading = true
                const response = await this.$http.post('/update-cart', {
                  user_id: this.userid,
                  pay_once: this.pay_once,
                  schedule_payment: this.schedule_payment,
                  duration_id: this.duration_id,
                  installment_duration: this.installment_duration,
                  cart_items: this.cart_items,
                  date1: middleValues[0].period,
                  date2: middleValues[1].period
              })
              this.loading = false
              if (response.data.success) {
                this.$router.push('/checkout/summary')
              }

            }
          else {
            this.pay_once = 1
            this.loading = true
              const response = await this.$http.post('/update-cart', {
                  user_id: this.userid,
                  pay_once: this.pay_once,
                  schedule_payment: this.schedule_payment,
                  duration_id: this.duration_id,
                  installment_duration: this.installment_duration,
                  cart_items: this.cart_items,
              })
              this.loading = false
              if (response.data.success) {
                this.$router.push('/checkout/summary')
              }
          }
              
                         
        } catch (err) {
            console.log(err);
        }
    },

    getMiddleValues(array) {
      const middleIndex1 = (array.length / 2) - 1;
      const middleIndex2 = array.length / 2;
      return [array[middleIndex1], array[middleIndex2]];
    },

    showSplashMessage(message) {
      const splashMessage = document.getElementById('splash-message');
      splashMessage.textContent = message;
      splashMessage.style.display = 'block';
      setTimeout(() => {
          splashMessage.style.display = 'none';
      }, 2000); // Hide the message after 2 seconds
    },

  }

}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.splash-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  display: none;
}

.modal-mask {
  position: fixed;
  //z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;

  &>div {
      width: 80%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;;
      backdrop-filter: blur(13px);
      border-radius: 10px;
      box-sizing: border-box;
      font-family: Mulish;
  }

  h3 {
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      font-family: Mulish;
  }
}

.account-content{
  display: flex;
  flex-direction: column;
  justify-content: center;  
 // margin-right: 8px;
}
.account-content-h{
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  width: 100%;
  height: 50px;   
  margin: 4px;
  padding-top: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}
.account-content-d{
  padding-top: 1.3rem;
  font-size: 14px;
  margin-bottom: 10px;
  height: 220px;
  overflow-y: auto;

  thead{
    th{
    font-size:10px
  }
}
}

.cart-0{
  margin-bottom: 4px;
}
 /* Breadcrumbs container */
 nav.breadcrumbs {
  margin: 10px 0;
  font-size: 12px;
  padding-left: 2.5rem;
}

/* Breadcrumbs list */
nav.breadcrumbs ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

/* Breadcrumbs list items */
nav.breadcrumbs li {
  margin-right: 5px;
}

/* Breadcrumb links */
nav.breadcrumbs a {
  text-decoration: none;
  color: #4b4848;
  transition: color 0.3s ease;
}

/* Breadcrumb links on hover */
nav.breadcrumbs a:hover {
  color: #4b4848;
}

/* Separator between breadcrumb items */
nav.breadcrumbs li::after {
  content: '>';
  margin: 0 5px;
  color: #ccc;
}

/* Styling for the last breadcrumb item (current page) */
nav.breadcrumbs li:last-child::after {
  content: none; /* Remove separator for the last item */
  font-weight: 500;
  color: #333; /* You can adjust the color to make it stand out */
}

.inputs{
margin-top: 20px;
border-radius: 3px;
font-size: 12px;
}
.product-container{
  display: flex;
  flex-direction: row;
  padding: 0.9rem;

}
.column9{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-right: 8px;
}
.column3{
  display: flex;
  flex-direction: column;
  width: 20%;

}
.column12{
  width: 100%;
}
.product{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.9rem;
  font-family: Mulish;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background: #fff;
}
.empty-cart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  font-family: Mulish;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background: #fff;
}
.cart-e{
  padding: 0.9rem;
  font-family: Mulish;
  font-size: 14px;
}
.cart-e2{
  font-family: Mulish;
  font-size: 14px;
  margin-top: -20px;
}
.btn-shopping{
  background: #800080;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 50px;
  font-size: 14px;
  padding: 0.5rem;
  font-family: Mulish;
}
.fa-shopping-bag{
  font-size: 60px;
  color: #800080;
}
.price{
  display: flex;
  flex-direction: column;
}
.product-cart-number{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.9rem;
  font-family: Mulish;
  background: #fff;
  border-bottom: solid 1px #bdbaba;
}
.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.btn-counter{
  padding: 6px;
  font-weight: bold;
  font-family: Mulish;
  font-size: 15px;
  margin: 5px;
  width: 15%;
  background: #800080;
  color: #fff;
  border-radius: 4px;
}
.counter-action{
  font-size: 13px;
}
.login-modal-box2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 20%;
  height: 30px;
  padding: 10px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 0px;
  color: #000;
  font-weight: 500;
  font-family: Mulish;
}
.choose-payment-terms{
  border-top: solid 10px #800080;
  background: #80008030;
  display: flex;
  flex-direction: column;
}
.payment-term-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.9rem;
  font-family: Mulish;
  font-size: 14px;
  color: #800080;
}
.payonce{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-size: 16px;
}
.payment-terms{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding-left: 10rem;
  padding-right: 10rem;
  font-family: Mulish;
  font-size: 10px;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  color: #000;
  text-transform: capitalize;
  
 .split-plan{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
 }
 .a-text{
  font-family: Mulish;
  font-size: 10px;
 }
}
.payment-schedule{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 0.9rem;
  font-family: Mulish;
  font-size: 12px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background: #fff;
  text-transform: capitalize;
}
.schedule{
  border-radius: 2px;
  border: solid 1px #8000804c;
  padding: 0.4rem;
  color: #800080;
  cursor: pointer;
  font-family: Mulish;
  font-size: 10px;
}
.proceed{
  border-radius: 2px;
  padding: 0.4rem;
  background:#250325;
  color: #fff;
  cursor: pointer;
}
.product-counter-remove{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.6rem;
  font-family: Mulish;
  background: #fff;
  .btn-remove{
      text-transform: uppercase;
      color: #800080;
      font-size: 15px;
    }
  .btn-remove:hover{
    color: #a5a3a3;
  }
}

.order-number{
  background: #800080; 
  color: #fff; 
  border-radius: 2px; 
  padding: 0.2rem;
  a{
    text-decoration: none;
    color: #fff;
  }
}
.product:hover{
  //border: solid 1px #071278;
  //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
}
.product-img{
  display: flex;
  flex-direction: column;
}
.check-out{
  margin-top:10px; 
  a{
    text-decoration: none
  }
}
.img-slide{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}
.img {
  cursor: pointer; 
  width: 100px; 
  height: 100px;
  padding: 0.5rem;
  border: solid 1px #ccc;
}
.img1 {
  cursor: pointer; 
  padding: 0.5rem;
  border: solid 1px #ccc;
  margin: 4px;
}
.product-detail{
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
}
.product-name{

  .cart-title{
      font-size: 16px;
      font-weight: 450;
      font-family: Mulish;
      color: #2b2929;
  }
  .brand{
      margin-top: -10px;
      font-size: 13px;
  }
  .in-stock{
      font-size: 12px;
      line-height: 1;
      margin-top: -10px;
      color: #a5a3a3;
    }
}
.product-price{
  .price{
      padding: 16px 0;
      font-size: 17px;
      color: #2b2929;
      font-family: Mulish;
  }
 
}
.btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #800080;
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
  height: 45px;
  border: solid 0px;
}
.fa-shopping-cart{
  font-size: 24px;
}
.fa-cart-plus{
  font-size: 24px;
}

.product-disclaimer{
  .disclaimer{
    font-size: 14px;
    color: #2b2929;
  }
  .disclaimer-info{
    font-size: 13px;
    color: rgba(255, 0, 0, 0.586);
  }
}
.product-d{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: Mulish;
  border: solid 0px #ccc;
  margin-bottom: 6px;
  border-radius: 4px;
  background: #fff;
}
.p-detail{
  display: flex;
  flex-direction: column;
}
.p-header{
  font-size: 18px;
  padding: 0.9rem;
  border-bottom: solid 1px #a5a3a3;
}
.p-content{
  padding: 0.9rem;
  font-size: 13px;
  text-align: justify;
}
.specification{
  display: flex;
  flex-direction: row;
  padding: 0.9rem;
 
}
.content-header{
  font-size: 13px;
  padding: 0.9rem;
  border: solid 1px #a5a3a3;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  text-transform: uppercase;
}
.p-content-d{
  margin: 10px;
  font-size: 13px;
}

.inputs-field{
  font-size: 14px;
  font-family: Mulish;
  height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 80%;

}
.delivery-return{
  font-size: 14px;
  font-family: Mulish;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
  border-bottom: solid 1px #a5a3a37b;
}
.d-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  margin-top: 10px;
  border-bottom: solid 1px #a5a3a37b;
}
.location{
font-size: 12px;
font-family: Mulish;
padding: 0.3rem;
h5{
  font-size: 14px;
}
}

@media screen and (max-width: 768px) {

  .product-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .product{
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding: 0.7rem;
      font-family: Mulish;
      width: 100%;
    }
    
    .column9{
      width: 100%;
    }
    .column3{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .column12{
      width: 100%;
    }
    .delivery-status{
      padding: 0.7rem;
      width: 100%;
    }
  .inputs{
    font-size: 12px;
  }
  .product-price{
    display: flex;
    flex-direction:row;
    justify-content: left;   
    .price{
      font-size: 14px !important;
      font-family: Mulish;
      padding: 16px 10px;
    }
  }
  .payonce{
    display: flex;
    flex-direction: row;
    justify-content: left !important;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .split-plan{
    display: flex;
    flex-direction: row;
    justify-content: left !important;
    .a-text{
      font-family: Mulish;
      font-size: 10px;
     }
   }
  .payment-terms{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    font-family: Mulish;
    font-size: 10px;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
  
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #800080;
        font-size: 12px;
      }
    .btn-remove:hover{
      color: #a5a3a3;
    }
  }
  .btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 12px;
    margin: 2px;
    width: 12%;
    background: #800080;
    color: #fff;
    border-radius: 4px;
  }

  .product-img{
    display: flex;
    flex-direction: column;
    width: 100%; 
    padding: 0.6rem;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 220px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .specification{
    display: flex;
    flex-direction: column;
    padding: 0.9rem;
    text-align: justify;
  }

  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
    width: 106%;
    padding: 10px;
  }

}

</style>