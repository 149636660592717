<template>
    <div style="background: #cccccc3d;">
        <!-- top navbar -->
        <div class="top-bar">
            <div>
                <h6>Shop from our collections</h6>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light navbar-bgcolor">
            <div class="dropdown">
                <button class="dropbtn avater"><i class="fa fa-bars bars"></i></button>
                <div class="dropdown-content">
                    <div class="brand-category">Category</div>
                    <div v-for="(data, index) in categorys" :key="index" @click="searchByCategory(data.category_name)">
                        <router-link to="" style="font-size: 10px;">{{ data.category_name }}</router-link>
                    </div>
                </div>
            </div>
            <div>
                <a class="navbar-brand" href="/shopping"><img src="/logo1.png" alt="Logo"> &nbsp;</a>
            </div>
            <div class="search">
                <input type="text" class="form-control inputs-field" v-model="searchTerm" placeholder="Search products, brands and category">
                <button class="search-button" @click="searchProducts()"> <i class="fa fa-search"></i></button>
            </div>
            <div class="menu-cart">
                <div class="dropdown" v-if="user_role != null">
                    <button class="dropbtn avater">Hi,{{ firstname }} <span>{{ name_initial }}</span></button>
                    <div class="dropdown-content">
                        <div class="dropdown-content">
                            <div v-if="user_role == 1">
                                <router-link to="/shop">Shop</router-link>
                                <router-link to="/brand">Brand</router-link>
                                <router-link to="/category">Category</router-link>
                                <router-link to="/subcategory">SubCategory</router-link>
                                <router-link to="/product">Product</router-link>
                                <router-link to="/orders">Orders</router-link>
                                <router-link to="/commissions">Commissions</router-link>
                              </div>
                              <div v-if="user_role == 2">
                                <router-link to="/myaccounts"> <i class="fa fa-user-o"></i> My Account</router-link>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="cart">
                    <div>
                        <router-link to="/cart">
                            <div class="cart-view"><i class="fa fa-shopping-cart"></i> Cart <span v-if="cartCount == ''"></span> <span v-else class="badge badge-primary">{{ cartCount}}</span></div>
                        </router-link>
                    </div>
                    <div v-if="user_role != null">
                        <div v-if="user_role == 1 || user_role == 2" style="cursor: pointer" @click="logout()">&nbsp; <i class="fa fa-sign-out"></i></div>
                    </div>
                    <div v-else>
                        <div style="cursor: pointer;">&nbsp; <router-link to="/login"> <i class="fa fa-sign-in"></i> </router-link></div>
                    </div>
                </div>
            </div>  
        </nav>
       
        <div class="container">
            <router-view :products="filteredProducts" :isLoading="isLoading" />
        </div>

        <div class="footer">
           <span> Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i></span>
           
           <span> &copy;{{ new Date().getFullYear() }} {{ companyTitle}}. All rights reserved.</span>
        </div>
        <div id="menu-bottom" class="menu-bottom fixed-bottom">
            <router-link to="/shopping">
            <div>
                <span class="icon"><i class="fa fa-home"></i></span>
                <span class="text">Shop</span>
            </div>
            </router-link>
            <div>
                <router-link to="/cart">
                    <span class="icon"><i class="fa fa-shopping-cart"></i></span>
                    <div style="display: flex; flex-direction: row;"><span class="text">Cart</span> <span v-if="cartCount == ''"></span> <span v-else style="font-size: 10px">({{ cartCount}})</span></div>
                </router-link>
            </div>
            <router-link to="/mobile-orders">
                <div>
                    <span class="icon"><i class="fa fa-archive"></i></span>
                    <span class="text">Order history</span>
                </div>
            </router-link>
            <router-link to="/mobile-accounts">
            <div>
                <span class="icon"><i class="fa fa-user"></i></span>
                <span class="text">Account</span>
            </div>
            </router-link>
            <div v-if="user_role != null">
                <span class="icon"><i class="fa fa-sign-out"></i></span>
                <span class="text" @click="logout()">Logout</span>
            </div>
            <router-link to="/login" v-else>
            <div>
                <span class="icon"><i class="fa fa-sign-in"></i></span>
                <span class="text" >Sign In</span>
            </div>
            </router-link>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
//import EventBus from '../util/event-bus.js'
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "ShoppingDashboard",
    components: {
        PageLoader
    },

    data(){
        return {
            appTitle: 'Daily Champ | Online Shopping Site for Mobiles, Electronics, Clothing, Solar and More. Best Offers',
            companyTitle: process.env.VUE_APP_COMPANY,
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
            brands: [],
            categorys: [],
            searchTerm: '',
            products: [], // All products
            filteredProducts: [], // Products to display after filtering
            isLoading: false
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        // EventBus.$on('update-cart-count', (count) => {
        //     this.cartCount = count;
        // });

        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
      
        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }
        this.fetchcategorys()
        this.fetchbrands()
        this.fetchProducts();
    },
    created(){
        
    },
    computed: {
     cartCount() {
         return this.$store.getters.getCartCount;
     },
    },
    methods:{
        async searchProducts() {
            try {
                this.isLoading = true;
                const response = await this.$http.post('/search-all-products',{
                    searchTerm: this.searchTerm
                });
                this.isLoading = false;
                this.products = response.data.products;
                
                // Initial load: display all products
                this.filteredProducts = this.products;
                this.$router.push('/search')

            } catch (err) {
                console.log(err);
            }
        },

        async searchByBrand(brand) {
            try {
                this.isLoading = true;
                const response = await this.$http.post('/search-all-products',{
                    searchTerm: brand
                });
                this.isLoading = false;
                this.products = response.data.products;
                
                // Initial load: display all products
                this.filteredProducts = this.products;
                this.$router.push('/search')

            } catch (err) {
                console.log(err);
            }
        },

        async searchByCategory(category) {
            try {
                this.isLoading = true;
                const response = await this.$http.post('/search-all-products',{
                    searchTerm: category
                });
                this.isLoading = false;
                this.products = response.data.products;
                
                // Initial load: display all products
                this.filteredProducts = this.products;
                //document.location = "/search"
                this.$router.push('/search')

            } catch (err) {
                console.log(err);
            }
        },
        async fetchProducts() {
            try {
                this.isLoading = true;
                const response = await this.$http.get('/fetch-all-products');
                this.isLoading = false;
                this.products = response.data.products;

                // Initial load: display all products
                this.filteredProducts = this.products;
            } catch (err) {
                console.log(err);
            }
        },

        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })

            this.categorys = response.data.categories;
                                   
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
            this.brands = response.data.brands              
            } catch (err) {
              console.log(err);
          }
        },

        logout(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
            localStorage.removeItem("user_role")
            document.location.href = '/shopping';
            //this.$router.push("/shopping")
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
/* Style The Dropdown Button */
.container{

}
.cart-view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.badge-primary{
    background: #800080;
}
.top-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1.9rem;
    font-family: Mulish !important;
    font-size: 18px;
    height: 40px;
    padding: 1.2rem;
    background: #800080;
    opacity: 2px;
    color: #fff;
}
.search{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;   
    padding-right: 90px; 
}
.inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
    border-radius: 0px;
    position: relative;
}
.search-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;
    background: #800080;
    color: #fff;
    border-radius: 0px;
    font-family: Mulish;
    font-size: 18px;
    text-transform: capitalize;
    height: 45px;
}

.menu-cart{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Mulish !important;
    font-size: 14px;
    color:#312F33;
    width: 40%;
}
.dropbtn {
    color: #4f4d4d;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
    border-radius: 4px;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Mulish;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}

.cart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px !important;

    a{
        color: #474646 !important;
    }
}
.bars{
    padding-left: 1.9rem;
    font-size: 20px;
}
.brand-category {
    padding: 0.4rem;
    font-size:10px; 
    font-family: Mulish;
    text-align: left; 
    text-transform: uppercase;
    div{
        font-size:10px !important; 
        font-family: Mulish;
    }
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    color: #3c3a3a;
    font-family: Mulish;
    border-bottom: solid 1px #ccc;
    box-shadow: 0 3px 4px -3px rgba(0, 0, 0, 0.3);
    
    a{
        color: #ccc !important;
        text-decoration: none;
        font-size: 13px;

        img{
            padding-left: 15px;
            width: 25%;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
    padding: 15px 0px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px !important;
        font-weight: 800;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
    flex-shrink: 0;
    background: #800080;
    font-family: Mulish;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position:relative;
    bottom: 0;
    height: 50px;
    width: 100%;
    font-size: 12px;
}

.menu-bottom{
    display: none;
}

@media screen and (max-width: 768px) { 
    .container{
       margin-left: 0px;
       margin-right: 0px;
    }
    .bars{
        padding-left: 0.5rem;
    }
    .navbar-brand{
        display: none;
    }
    .search{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        padding-right: 0px; 
    }
    .inputs-field{
        font-size: 11px;
        height: 30px;
        font-family: Mulish !important;
    }
    .search-button{
        font-size: 14px;
        height: 30px;
    }
    .menu-cart{
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 2.9rem;
        font-family: Mulish !important;
        font-size: 12px;
        color:#312F33;
        width: 100%;
    }
    .cart{
       width: 100%;
       font-size: 13px !important;
    }
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span{
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            //width: 100%;
            //height: 35px;
            background: #312F33;
            border-radius: 47px;
            margin: 10px;
        }
    }
    .footer{
        display: none;
    }
    .menu-bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: auto;
        background: #800080;
        color: #fff;
        bottom: 0;
        padding: 0.2rem;

        a{
            text-decoration: none;
            color: #fff;
        }

        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            cursor: pointer;

            .icon{
                font-size: 13px;
            }

            .text{
                font-size: 9px;
            }
            a{
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

</style>