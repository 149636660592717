<template>
    <div>
      <div id="splash-message" class="splash-message"></div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
        <div class="product-container">
            <div class="column9">
                <div class="cart-0">
                    <div class="product">
                        <div class="product-detail">
                            <div class="product-name">
                                
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number">
                   <span>&nbsp;&nbsp;<i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="cart-0">
                    <div class="center-p">
                        <div class="center-p-0-0">
                            <div class="center-p-0">
                                <p class="text-center"><i class="fa fa-spinner"></i> Creating order...</p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number" >
                 
                </div>                
            </div>
           
        </div>
        
        <p>&nbsp;</p>
        <p>&nbsp;</p>
       
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  export default {
    
    name: "PaymentPage",
    components: {
        //quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          address: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL+'products/',

          payment_bank_name: '',
          payment_account_name: '',
          payment_account_number: '',
          payment_amount_payment: '',
          ussd_code: '',
          transaction_reference: '',
          transaction_url: '',
          transaction_details: '',
          isSending: false,
          //duration: 20 * 60 * 1000, // 20 minutes in milliseconds
          interval: 10000, // 30 seconds in milliseconds
          timer: null, 
          loading3: false,
          btnConfirmOrder: false,
          cart_detail: [],
          total_amount: '',
          selectedPaymentMethod: '',
          selectedPaymentMethod1: '',
          paymentPad: true,
          bankTransfer: false,
          walletBal: '',
          isPay: false,
          accountDurationSeconds: '',
          bank_details: '',
          processBtn: true,
          process: false,
          is_order_summary: false,
          cart_length: '',
          cart_items: [],
          payment_mode: '', // one off / installment payment
          checkout_amount: '',
          payment_installment: '',
          verify_phone: '',
          verify_email: '',
          verify_address: '',
          verify_bank: '',

          schedule_pay: '',
          order_reference: '',
          order_id: '',
          payment_scheduleid: '',
          payment_count: '',
          payment_amount: '',
          payment_duration: '',
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){
       
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.address = localStorage.getItem("address");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
               
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");

        this.order_reference = localStorage.getItem('order_reference')
        this.payment_mode = localStorage.getItem('payment_mode')
        this.order_id = localStorage.getItem('order_id')
        this.payment_scheduleid = localStorage.getItem('payment_scheduleid')
        this.payment_count = localStorage.getItem('payment_count')
        this.payment_amount = localStorage.getItem('payment_amount')
        this.payment_duration = localStorage.getItem('payment_duration')
        this.selectedPaymentMethod1 = localStorage.getItem('selectedPaymentMethod')
        this.schedule_pay = localStorage.getItem('schedule_pay')
        this.fetchUserDetails(this.user_id)

        if(this.schedule_pay == 1 ){
          this.paymentSchedule()
        }
        else{
          this.fetchCartInformation(this.cart_id) 
        }            
    },
  
    created(){
          
    },
  
    computed: {
    
    },
  
    methods: {

      async generate_payment_detail(){
              try{
                      let amount = this.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })
                      let product_amount = amount.split(",")
                      let p_amount = product_amount[0]+''+product_amount[1];

                      const response = await this.$http.post('/generate-payment-account', {
                          userid: this.user_id,
                          firstname: this.firstname,
                          lastname:  this.lastname, 
                          email: this.email_address,
                          amount: p_amount,
                      });
                          
                      this.bank_details = response.data.transfer_data
                      this.transaction_reference = this.bank_details.reference
                      this.transaction_url = this.bank_details.authorization_url
                      window.location.href = `${this.transaction_url}`
                      //console.log(this.bank_details.reference)
                      // this.payment_bank_name = this.bank_details['responseBody']['bankName']
                      // this.payment_account_name = this.bank_details['responseBody']['accountName']
                      // this.payment_account_number = this.bank_details['responseBody']['accountNumber']
                      // this.ussd_code = this.bank_details['responseBody']['ussdPayment']
                      // this.payment_amount_payment = this.bank_details['responseBody']['amount']
                      // this.transaction_reference = this.bank_details['responseBody']['transactionReference']
                      // let time = this.bank_details['responseBody']['accountDurationSeconds']
                      // this.accountDurationSeconds = time / 60;
                      return              
                      
              }catch (err) {
                  console.log(err);
              }
      },

      paymentMade(){
              try{
                
                  this.processBtn = false
                  this.process = true
                  this.timer = setInterval(() => {
                      this.checkTransactionStatus();
                  }, this.interval);
                  this.loading3 = false
                                
              }catch (err) {
                  console.log(err);
              }
      },

      async checkTransactionStatus(){
          try{

                  this.response = await this.$http.post('/payment-made', {
                      transaction_reference: this.transaction_reference,
                  });

                  if (this.response.data.status) {
                                          
                      this.transaction_details = this.response.data.transfer_data
                      this.transaction_payment_status = this.transaction_details.status
                      
                      if (this.transaction_payment_status == 'success') {                         
                          // update order table
                          await this.$http.post('/create-order', {
                            userid: this.user_id,
                            cart_items: this.cart_items,
                            selectedPaymentMethod: this.selectedPaymentMethod,
                            order_ref: Date.now()
                          })

                          this.deletCartByUniqueId(this.cart_id)
                          this.fetchCartInformation(this.cart_id) 

                          this.showSplashMessage("Payment successful!")    
                          
                          this.stopSendingRequests() 
                                                
                          return;
                          
                      }
                      else {
                          // console.log(this.transaction_details)
                      }
                      return;              
              }            
          }catch (err) {
              console.log(err);
          }
      },

      stopSendingRequests() {
          clearInterval(this.timer);
          this.isSending = false;
      },

      async fetchCartInformation(cart_id) {
        try {

            const response = await this.$http.post('/fetch-cart-byunique_code', {
              cart_unique_code: cart_id
            })

            this.cart_items = response.data.cart;
            this.cart_length =  this.cart_items.length
            this.selectedPaymentMethod = this.cart_items[0].payment_method
            this.payment_mode = this.cart_items[0].payment_mode
            if (this.selectedPaymentMethod == "wallet") {
              this.get_wallet_balance()
            }
            this.$store.dispatch('updateCartCount', this.cart_items.length);
            this.calculateOrder()

            const response1 = await this.$http.post('/create-order', {
                  userid: this.user_id,
                  cart_items: this.cart_items,
                  selectedPaymentMethod: this.selectedPaymentMethod,
                  order_ref: Date.now()
            })
          if(response1.data.success){
            this.deletCartByUniqueId(this.cart_id)
            this.showSplashMessage("Payment successful!")    
          }
          else{
            this.showSplashMessage("Could not create order. Something went wrong!")  
          }
          
        } catch (err) {
            console.log(err);
        }
      },

      async paymentSchedule(){
          try{               
                  // update order table
                  await this.$http.post('/make-schedule-payment', {
                    userid: this.user_id,
                    orderid: this.order_id,
                    selectedPaymentMethod: this.selectedPaymentMethod1,
                    order_ref: this.order_reference,
                    payment_scheduleid: parseInt(this.payment_scheduleid),
                    payment_count: parseInt(this.payment_count),
                    payment_amount: parseInt(this.payment_amount),
                    payment_duration: parseInt(this.payment_duration) 
                  })
                  
                  this.showSplashMessage("Payment successful!")    
                  
                  this.stopSendingRequests() 
                                        
                  return;                   
          }catch (err) {
              console.log(err);
          }
      },

      calculateOrder(){
          this.total_amount = this.cart_items.reduce(
              (accumulator, item) => accumulator + item.unit_price * item.qty,
              0
          );

          if (this.payment_mode == 0) {
            this.payment_installment = 'Split payment'
            this.checkout_amount = this.total_amount / this.cart_items[0].duration
          }
          else if (this.payment_mode == 1) {
            this.payment_installment = 'One-Off payment'
            this.checkout_amount = this.total_amount 
          }
      },
        
      async walletPay(){
      try {

                let amount = this.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2, maximumFractionDigits: 2 })
                let product_amount = amount.split(",")
                let p_amount = product_amount[0]+''+product_amount[1];

                this.isPay = true
                const response = await this.$http.post('/check-wallet-balance1', {
                    userid: this.user_id,
                    amount: p_amount
                  })
                  this.isPay = false

                  if (!response.data.success) {
                    swal.fire({
                          icon: 'warning',
                          title: 'Low Balance',
                          width:450,
                          height:5,
                          text: response.data.message,
                      });
                      return;
                  } else {
                    
                    const response2 = await this.$http.post('/create-order', {
                      userid: this.user_id,
                      cart_items: this.cart_items,
                      selectedPaymentMethod: this.selectedPaymentMethod,
                      order_ref: Date.now()
                    })

                    if (response2.data.success) {
                      
                      this.showSplashMessage("Payment successful!")
                      this.deletCartByUniqueId(this.cart_id)
                      this.fetchCartInformation(this.cart_id) 
                      
                      return;
                  }
              }
          
          } catch (err) {
          console.log(err);
          }
      },

      async bankPay(){
      try {
              this.isPay = true
              this.paymentPad = false
              this.bankTransfer = true
              this.generate_payment_detail()
              //this.paymentMade()
              this.isPay = false
          
          } catch (err) {
          console.log(err);
          }
      },

      async get_wallet_balance(){
              try{
                      const response = await this.$http.post('/fetch-wallet-balance', {
                          userid: this.user_id,
                      });
                      this.walletBal = response.data.balance
                      return;              
                      
              }catch (err) {
                  console.log(err);
              }
      },
      
      async deletCartByUniqueId(cart_id) {
        try {
              await this.$http.post('/delete-cart-byunique_code', {
              cart_unique_code: cart_id
            })

        } catch (err) {
            console.log(err);
        }
      },

      showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
            localStorage.removeItem('order_reference')
            localStorage.removeItem('payment_mode')
            localStorage.removeItem('order_id')
            localStorage.removeItem('payment_scheduleid')
            localStorage.removeItem('payment_count')
            localStorage.removeItem('payment_amount')
            localStorage.removeItem('payment_duration')
            localStorage.removeItem('schedule_pay')
            localStorage.removeItem('selectedPaymentMethod1')
            this.$router.push('/shopping')
        }, 2000); // Hide the message after 2 seconds
      },
      
      async fetchUserDetails(userid){
          try {

            const response =  await this.$http.post('/fetch-user-byid', {
              userid: userid,
            })

            this.user_detail = response.data.users
            this.verify_phone = this.user_detail[0].verify_phone
            this.verify_email = this.user_detail[0].verify_email
            this.verify_address = this.user_detail[0].verify_address
            this.verify_bank = this.user_detail[0].verify_bank
                
            } catch (err) {
            console.log(err);
            }
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

  .iframe-container {
            position: relative;
            width: 100%;
            height: 500px; /* Set the height as needed */
            overflow: hidden;
        }

        .iframe-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    border-bottom: solid 1px #800080c7;
    color: #800080c7;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .terms{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-family: Mulish;
    font-size: 13px;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
    color: #626060;
    text-transform: uppercase;
    font-weight: normal;
  }
  .address{
    color: #800080c7;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.generating-account{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size:15px;
}
.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #800080c7;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.3rem;
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #800080c7;
        font-size: 12px;
      }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #800080c7;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    width: 100%;
  }
  .payment-span{
    margin: 15px;
  }
  .payment-method{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 15px;
    font-size: 12px;
  }
  .product-name{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    margin-right: 10px;
    width: 100%;
    .cart-title{
        margin-top: -10px;
        font-size: 14px;
        font-weight: bold;
        color: #6e6c6c;
        text-transform: uppercase;
    }
    .cart-title-t{
      margin-top: -10px;
      font-size: 12px;
      font-weight: bold;
      color: #6e6c6c;
  }
    .brand{
        margin-top: 2px;
        font-size: 18px;
        font-weight: bold;
        span{
          font-weight: bold;
          font-size: 15px;
        }
    }
    .brand-t{
      margin-top: 2px;
      font-size: 10px;
      font-weight: 500;
      span{
        font-weight: bold;
        font-size: 13px;
      }
  }
   
  }
  .center-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .center-p-0{
    p{
      text-align: center;
      font-weight: 550;
    }
  }
  .payment-made{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    font-size: 12px;
    border-radius: 4px;
    border: solid 10px #a5a3a3;
    .btn-default{
      color: #fff;
      background: rgb(42, 136, 230);
      font-size: 12px !important;
    }
    p{
      color: #a5a3a3;
    }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #800080c7;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .payment-instruction{
    color: #a5a3a3;
    font-size: 12px;
    text-align: center;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;

}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
    margin-bottom: 14px;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .product-detail{
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      width: 100%;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
     
      .column9{
        width: 100%;
      }
      .bank-transfer{
        padding: 1.9rem;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>