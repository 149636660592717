<template>
    <div class="container-fluid">
      <div id="splash-message" class="splash-message"></div>
            <div class="account-menu">
                <div class="menu"  @click="myAccount()"><span><i class="fa fa-user-o"></i></span> <span class="icon-text"> &nbsp;&nbsp;&nbsp;My Account</span></div>
                <div class="menu" @click="myOrders()"><span><i class="fa fa-archive"></i></span> <span class="icon-text"> &nbsp;&nbsp;&nbsp;My Orders</span></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <div class="menu1"></div>
                <a href="/shopping"><div class="menu2"><span><i class="fa fa-sign-in"></i></span> <span class="icon-text"> &nbsp;&nbsp;&nbsp;Logout</span> </div></a>
            </div>
            <div class="menu-content" v-if="is_myaccount">
                <div class="account-head">
                   <div>Account Overview</div> 
                   <div style="cursor:pointer"><router-link to="/profile"><i class="fa fa-edit"></i> Change password</router-link></div>
                </div>
                <div class="account-overview">
                    <div class="account-col1">
                        <div class="account-col2">
                            ACCOUNT DETAILS
                        </div>
                        <div class="account-col3">
                            <span class="name"> {{ first_name }} {{ last_name}} </span>
                            <span class="email" v-if="verify_email == 0"><i class="fa fa-envelope"></i> {{ email }} 
                                <strong v-if="!isLoading2" class="cursor-verify badge badge-light text-white" @click="verifyEmail()">Verify</strong>
                                <strong v-if="isLoading2"> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></strong>
                            </span>
                            <span class="email" v-else-if="verify_email == 1"><i class="fa fa-envelope"></i> {{ email }} <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                            <span class="phone"><i class="fa fa-phone"></i> {{ phone }}
                            </span>
                            <span class="phone"  v-if="verify_nin == 0"> <i class="fa fa-user" aria-hidden="true"></i> NIN <strong class="cursor-verify badge badge-light text-white" @click="verify_identity()">Verify</strong>
                            </span>
                            <span class="phone" v-if="verify_nin == 1"><i class="fa fa-user" aria-hidden="true"></i> NIN <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                            <span class="phone" v-if="verify_bvn == 0"> <i class="fa fa-home" aria-hidden="true"></i> BVN <strong class="cursor-verify badge badge-light text-white" @click="verify_bank()">Verify</strong>
                            </span>
                            <span class="phone" v-if="verify_bvn == 1"><i class="fa fa-home" aria-hidden="true"></i> BVN <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                    <div class="account-col1">
                        <div class="account-col2">
                            ADDRESS BOOK
                        </div>
                        <div class="account-col3">
                            <span class="shipping">Your shipping address:</span>
                            <span class="address" v-if="verify_address == 0">N/A</span>
                            <span class="address" v-if="verify_address == 1"><i class="fa fa-map-marker"></i> {{ address}} <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="account-overview">
                    <div class="account-col1">
                        <div class="account-col2">
                            CHAMP POCKET CREDIT
                        </div>
                        <div class="account-col3">
                            <span class="name"></span>
                            <span class="email"><i class="fa fa-credit-card" aria-hidden="true"></i> Balance: ₦{{ walletBal.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} <strong  class="cursor" @click="Addfund()">(Topup) </strong></span>
                        </div>
                    </div>
                    <div class="account-col1">
                        <div class="account-col2">
                            BANK DETAIL & PAYMENT CARD
                        </div>
                      <div class="account-col22">
                        <div class="bank-card1">
                          <div class="account-col3" v-if="user_bank_detail == ''">
                            <span class="shipping cursor" @click="verify_bank()"> <i class="fa fa-university" aria-hidden="true"></i>
                                Verify your bank: <i class="fa fa-edit" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div class="account-col3" v-else>
                              <span class="shipping"> <i class="fa fa-university" aria-hidden="true"></i>
                                  Your bank details: <i class="fa fa-check-circle" aria-hidden="true"></i></span>
                              <span class="address"> {{ beneficiary }} </span>
                              <span class="address"> {{ beneficiary_bank }}</span>
                              <span class="address"> {{ beneficiary_account_number}} </span>
                          </div>
                        </div>
                        <div class="bank-card1">
                          <div class="account-col3" v-if="user_payment_card == ''">
                            <span class="shipping cursor" @click="add_payment_card()"> <i class="fa fa-credit-card" aria-hidden="true"></i>
                                Add your card: <i class="fa fa-edit" aria-hidden="true"></i>
                            </span>
                          </div>
                          <div class="account-col3" v-else>
                              <span class="shipping"> <i class="fa fa-credit-card" aria-hidden="true"></i>
                                  Your card details: </span>
                              <span class="address text-size"> <strong>{{ bank_card }} {{ card_type }} card </strong><br>ending with <strong>{{ card_last4}}</strong></span>
                              <span class="address"> </span>
                              <span class="address"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="menu-content1" v-if="is_myorder">
                <div class="account-head1">
                    My Orders
                </div>
                <div class="product-container">
                    <div class="column3">
                    </div>
                    <div class="column9">
                        <div class="product-cart-number">
                            Order({{ cart_length }})
                        </div>
                        <div v-if="ordered_items == ''">
                          <div class="cart-0">
                              <div class="empty-cart">
                                  <i class="fa fa-shopping-bag"></i>
                                  <p class="cart-e">Your order is empty </p>
                                  <p class="cart-e2">Shop from our collections </p>
                                 <router-link to="shopping">
                                    <button class="btn btn-shopping">start shopping</button>
                                 </router-link>
                              </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="cart-0" v-for="(data, index) in ordered_items" :key="index">
                            <div class="product">
                                <div class="product-img">
                                    <img class="img" :src="img_url + data.product_image" alt="">
                                </div>
                                <div class="product-detail">
                                    <div class="product-name">
                                        <p class="cart-title">Order No.: {{ data.orderref}}</p>
                                        <div class="variation-style" >
                                          <p class="brand"> {{ data.totalQty }} Item(s) <span class="text-success" style="cursor: pointer" @click="vieworder(data.orderref)"> view</span> </p>
                                          <p class="brand">Total Amount: ₦{{ data.totalAmount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}  </p>
                                          <p class="brand1"><span v-if="data.installment == 1">Weekly</span><span v-else-if="data.installment == 2">Monthly</span> Installment: ₦{{ data.checkout_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}  </p>
                                          <p class="brand1">Duration: {{ data.duration }}<span v-if="data.installment == 1">Weeks</span><span v-else-if="data.installment == 2">Months</span> </p>
                                          <p class="brand1 text-primary" v-if="data.order_status == 0"> Ongoing</p>
                                          <p class="brand1 text-success" v-else-if="data.order_status == 1"> Delivered</p>
                                          <p class="brand1 text-warning" v-else-if="data.order_status == 2"> Returned</p>
                                          <p class="brand1 text-danger" v-else-if="data.order_status == 3"> Cancelled</p>
                                        </div>
                                       <div v-if="data.payment_confirm == 1">
                                        <div class="progress" style="height: 15px;font-size: 9px ">
                                          <div class="progress-bar bg-success" role="progressbar" :style="{ width: data.payment_count/data.duration * 100 +'%' }" aria-valuenow="10" aria-valuemin="0" :aria-valuemax="data.duration">
                                             Payment Completed
                                          </div>
                                        </div>
                                       </div>
                                        <div v-else-if="data.payment_confirm == 0" class="progress" style="height: 15px;font-size: 9px ">
                                            <div class="progress-bar bg-danger" role="progressbar" :style="{ width: data.payment_count/data.duration * 100 +'%' }" aria-valuenow="10" aria-valuemin="0" :aria-valuemax="data.duration">
                                              {{ Math.round((data.payment_count/data.duration) * 100) +'%' }} Paid
                                            </div>
                                        </div>
                                        <div v-if="data.request_delivery == 0">
                                          <div class="payment-schedule btn btn-success btn-block btn-sm text-white" @click="request_delivery(data.orderref)" v-if="Math.round((data.payment_count/data.duration) * 100) >= 50 && Math.round((data.payment_count/data.duration) * 100) <= 100 && request_deliverys == 0"><i class="fa fa-address-card-o" aria-hidden="true"></i>
                                            Request Delivery
                                          </div>
                                        </div>
                                        <div v-else-if="data.request_delivery == 1 && data.orderref">
                                          <div class="payment-schedule btn btn-info btn-block btn-sm text-white"><i class="fa fa-address-card-o" aria-hidden="true"></i>
                                            Request Initiated
                                          </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div class="product-price">
                                    <div></div>
                                    <div class="payment-schedule text-success" @click="viewpayment_plan(data.orderref)"><i class="fa fa-file"></i> payment plan</div>
                                    <div class="cancel-order text-danger" v-if="Math.round((data.payment_count/data.duration) * 100) < 50"><i class="fa fa-stop"></i> cancel order</div>
                                    <div class="cancel-order text-info" v-if="Math.round((data.payment_count/data.duration) * 100) >= 50 && Math.round((data.payment_count/data.duration) * 100) <= 100" @click="track_item(data.orderref)"><i class="fa fa-binoculars" aria-hidden="true"></i> Track Item</div>
                                </div>
                            </div>
                          </div>
                        </div>                         
                    </div>
                    <div class="column3">
                    </div>
                </div>  
            </div>
            <div v-if="isPaymentPlans">
                <transition name="modal">
                  <div class="modal-mask">
                      <div class="modal-wrapper col-md-5" style="margin:auto">
                          <div class="row">
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-10">
                                  <div class="row account-content">
                                      <div class="account-content-h">
                                         payment schedule
                                      </div>
                                      <div class="account-content-d table-responsive">
                                        <table class="table table-bordered">
                                          <thead>
                                            <th>
                                              Period
                                            </th>
                                            <th>
                                              Date
                                            </th>
                                            <th>
                                              Amount(NGN)
                                            </th>
                                            <th>
                                              Status
                                            </th>
                                            <th>
                                              Action
                                            </th>
                                          </thead>
                                          <tbody>
                                            <tr v-for="(data, index) in payment_plan" :key="index" style="font-size: 10px !important;">
                                              <td>{{ data.period }}</td>
                                              <td>{{ data.payment_date }}</td>
                                              <td>{{ data.amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</td>
                                              <td> 
                                                <span v-if=" data.payment_status == 1" class="text-success">Paid</span>
                                                <span v-else-if="data.payment_status == 0" class="text-danger">Not-Paid</span>
                                              </td>
                                              <td> 
                                                <div v-if="data.next_payment == 0">
                                                  <span v-if="data.payment_status == 1" class="" style="cursor: default">
                                                    <i class="fa fa-check"></i>
                                                  </span>
                                                  <span v-else-if="data.payment_status == 0" class="text-secondary" style="cursor: default" disabled>
                                                    Up coming payment
                                                  </span>
                                                </div>
                                                <span v-else-if=" data.next_payment == 1" class="btn btn-success btn-sm text-white" style="cursor: pointer" @click="makePayment(data.payment_scheduleid)">
                                                  Pay Now
                                                </span>
                                              </td>
                                            </tr>
                                          </tbody>
        
                                        </table>
                                      </div>
                                     
                                      <button class="btn btn-secondary" @click="isPaymentPlans = false"><i class="fa fa-times"></i> </button>
                                      <p>&nbsp;</p>
                                  </div>
                              </div>
                              <div class="col-md-1">                         
                              </div>
                          </div>
                      </div>
                  </div>
                </transition>
            </div>
            <div v-if="isTrackItem">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-6" style="margin:auto">
                        <div class="row">
                            <div class="col-md-0">
                            </div>
                            <div class="col-md-12">
                                <div class="row account-content">
                                    <div class="account-content-h">
                                        shipping history
                                    </div>
                                    <div class="account-content-d1 table-responsive">
                                    <div class="progress-track">
                                        <div class="progress bar-width">
                                            <div class="progress-bar bg-success  progress-bar-striped" role="progressbar" :style="{ width: pgress }"
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" >
                                            {{ track_status }}
                                            </div>
                                        </div>
                                        <ul id="progressbar" v-if="is_step_1">
                                            <li class="step0 active" id="step1">order placed <br> {{ order_date }}</li>
                                            <li class="step0 text-center" id="step2">shipped <br> {{ shipped_date }}</li>
                                            <li class="step0 text-right" id="step3">out for delivery <br>{{ out_for_delivery_date }}</li>
                                            <li class="step0 text-right" id="step4">delivered<br> {{ delivered_date }}</li>
                                        </ul>
                                        <ul id="progressbar" v-if="is_step_2">
                                            <li class="step0 active" id="step1">order placed<br> {{ order_date }}</li>
                                            <li class="step0 text-center active" id="step2">shipped<br> {{ shipped_date }}</li>
                                            <li class="step0 text-right" id="step3">out for delivery <br>{{ out_for_delivery_date }}</li>
                                            <li class="step0 text-right" id="step4">delivered <br>{{ delivered_date }}</li>
                                        </ul>
                                        <ul id="progressbar" v-if="is_step_3">
                                            <li class="step0 active" id="step1">order placed<br> {{ order_date }}</li>
                                            <li class="step0 text-center active" id="step2">shipped<br> {{ shipped_date }}</li>
                                            <li class="step0 text-right active" id="step3">out for delivery<br>{{ out_for_delivery_date }}</li>
                                            <li class="step0 text-right" id="step4">delivered <br>{{ delivered_date }}</li>
                                        </ul>
                                        <ul id="progressbar" v-if="is_step_4">
                                            <li class="step0 active" id="step1">order placed<br> {{ order_date }}</li>
                                            <li class="step0 text-center active" id="step2">shipped<br> {{ shipped_date }}</li>
                                            <li class="step0 text-right active" id="step3">out for delivery<br>{{ out_for_delivery_date }}</li>
                                            <li class="step0 text-right active" id="step4">delivered<br>{{ delivered_date }}</li>
                                        </ul>
                                    </div>
                                    </div>
                                    
                                    <button class="" @click="isTrackItem = false"><i class="fa fa-times"></i> </button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-0">                         
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            </div>
            <div v-if="isAddfund">
                <transition name="modal">
                  <div class="modal-mask">
                      <div class="modal-wrapper col-md-5" style="margin:auto">
                          <div class="row">
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-10">
                                  <div class="row account-content">
                                    
                                      <div class="account-content-h">
                                          account details
                                          <span class="add-fund">To add money to your wallet, make a transfer to the account below.</span>
                                      </div>
                                      <div class="account-content-d">
                                        <div class="acct-d">
                                          <i class="fa fa-home"></i> <span>Bank</span><br>{{ bank_name}}
                                        </div>
                                        <div class="acct-d">
                                          <i class="fa fa-bank"></i> <span>Account number</span><br> {{ account_number }}
                                        </div>
                                        <div class="acct-d">
                                          <i class="fa fa-user"></i> <span>Beneficiary</span><br>{{ account_name }}
                                        </div>
                                      </div>
                                      <button class="btn btn-secondary" @click="isAddfund = false"><i class="fa fa-times"></i> </button>
                                      <p>&nbsp;</p>
                                  </div>
                              </div>
                              <div class="col-md-1">                         
                              </div>
                          </div>
                      </div>
                  </div>
                </transition>
            </div>
            <div v-if="isSendSms">
                <transition name="modal">
                  <div class="modal-mask3">
                      <div class="modal-wrapper col-md-5" style="margin:auto">
                          <div class="row">
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-10">
                                  <div class="row account-content3">
                                      <div class="account-content-h3">
                                         <span>phone verification</span>
                                         <span>{{ countdown_Timer }}</span>
                                      </div>
                                      <div class="account-content-d3">
                                        <span>Enter verification code sent to your phone</span><br>
                                        <input type="text" class="form-control" placeholder="Enter code" v-model="verification_code">
                                      </div>
                                      <div class="account-content-d3-1">
                                      <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="verifyCode()" v-if="!loading">Verify</button>
                                      <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      </button>
                                      <button class="btn btn-secondary" @click="stopTimer()"><i class="fa fa-times"></i> </button>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-1">                         
                              </div>
                          </div>
                      </div>
                  </div>
                </transition>
            </div>
            <div v-if="isVerifyIdentity">
              <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-h">
                                       verify your Identity
                                       <span>please provide your nin and nin slip</span>
                                    </div>
                                    <div class="account-content-nin table-responsive">
                                      <div style="width:100%; margin-right: 10px">
                                        <span>NIN</span><br>
                                        <input type="number" class="form-control " placeholder="Enter your nin here" v-model="user_nin" >
                                      </div>
                                      <div class="inputs"> 
                                          <div class="add-nin">
                                            <input type="file" ref="fileInput" id="image_input"  class="file-input"  @change="pickFile"/> 
                                          </div>
                                          <span style="font-size:10px">Attach slip</span>
                                      </div>
                                    </div>
                                    <button class="btn btn-success" :disabled="isButtonDisabled" @click="verifyIdentity()" v-if="!loadingd">Submit</button>
                                    <button class="btn btn-success btn-block" type="button" disabled v-if="loadingd">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button class="btn btn-secondary" @click="isVerifyIdentity = false"><i class="fa fa-times"></i> </button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">                         
                            </div>
                        </div>
                    </div>
                </div>
              </transition>
            </div>

          <!-- <div v-if="isVerifyBvn">
              <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-h">
                                       verify Bvn
                                       <span>please verify your bvn</span>
                                    </div>
                                    <div class="account-content-d table-responsive">
                                      <span>Bvn</span><br>
                                      <input type="number" class="form-control bvn-input" placeholder="Enter bvn" v-model="user_bvn" >
                                    </div>
                                    <button class="btn btn-success" :disabled="isButtonDisabled" @click="verifyIdentity()" v-if="!loading1">Verify</button>
                                    <button class="btn btn-success btn-block" type="button" disabled v-if="loading1">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button class="btn btn-secondary" @click="isVerifyIdentity = false"><i class="fa fa-times"></i> </button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">                         
                            </div>
                        </div>
                    </div>
                </div>
              </transition>
            </div> -->
            
            <div v-if="is_order_summary">
              <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-h">
                                      order summary
                                    </div>
                                    <div class="account-content-d table">
                                      <table class="table table-bordered table-responsive" style="font-size: 12px;font-family: Mulish;">
                                        <thead>
                                          <th></th>
                                          <th>Order No</th>
                                          <th>Item</th>
                                          <th>Variation</th>
                                          <th>Qty</th>
                                          <th>Price</th>
                                          <th>Total</th>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(data, index) in cart_items" :key="index">
                                            <td>
                                              <img width="40" height="40" :src="img_url + data.product_image" alt="">
                                            </td>
                                            <td>
                                              {{ data.orderref }}
                                            </td>
                                            <td>
                                              {{ data.product_name }}
                                            </td>
                                            <td>
                                             <div v-if="data.selected_variation !=''">
                                              {{ data.selected_variation }}
                                             </div> 
                                             <div v-else-if="data.selected_variation ==''">
                                              N/A
                                             </div> 
                                            </td>
                                            <td>
                                              {{ data.qty }}
                                            </td>
                                            <td>
                                              ₦{{ data.unit_price}}
                                            </td>
                                            <td>
                                              ₦{{ data.qty * data.unit_price }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <button class="btn-bottom" @click="is_order_summary = false"><i class="fa fa-times"></i> </button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">                         
                            </div>
                        </div>
                    </div>
                </div>
              </transition>
            </div> 

            <div v-if="isRequestDelivery">
              <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-10">
                                <div class="row account-content">
                                    <div class="account-content-h">
                                       request delivery
                                       <span>please provide proof of your delivery address</span>
                                    </div>
                                    <div class="account-content-nin table-responsive">
                                      <div style="width:100%; margin-right: 10px">
                                        <i class="fa fa-map-marker"></i> {{address}}
                                        <div class="add-nin">
                                          <input type="file" ref="fileInput1" id="image_input"  class="file-input"  @change="pickFile2"/> 
                                        </div>
                                        <span style="font-size:10px">Attach utility bill</span>
                                      </div>
                                     
                                    </div>
                                    <button class="btn btn-success" :disabled="isButtonDisabled1" @click="requestDelivery()" v-if="!loadingr">Submit</button>
                                    <button class="btn btn-success btn-block" type="button" disabled v-if="loadingr">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                    <button class="btn btn-secondary" @click="isRequestDelivery = false"><i class="fa fa-times"></i> </button>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="col-md-1">                         
                            </div>
                        </div>
                    </div>
                </div>
              </transition>
            </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
    
  export default {
    
    name: "MyAccounts",
    components: {
        //quillEditor,
        PageLoader
    },
  
    data(){
      return {
  
          isLoading: false,
          isLoading1: false,
          isLoading2: false,
          isSendSms: false,
          is_myaccount: true,
          is_myorder: false,
          isPaymentPlans: false,
          isTrackItem: false,
          isAddfund: false,
          isVerifyIdentity: false,
          //isVerifyBvn: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',         

          loading: false,
          loading1: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL+'products/',
          ordered_items: '',
          cart_id: '',
          cart_length: '',
          total_amount: '',
          counter: 1,
          durations: [],
          installments: [],
          duration_id: '',
          isInstallment: true,
          isDuration: false,
          isSchedule: false,
          pay_once: false,
          isCounter: false,
          isCounter1: true,
          is_order_summary: false,
          isRequestDelivery: false,
          loadingr: false,
          installmentDates: [],
          period: '',
          schedule_payment: 0,
          payment_title: 0,
          installment_duration: '',
          payment_count: '',
          step_value: '',
          stepValues: [
              {
                  value1: "25%",
                  value2: "50%",
                  value3: "75%",
                  value4: "100%",
              }
          ],
          payment_plan: [],
          currentDate: '',
          is_step_1: true,
          is_step_2: false,
          is_step_3: false,
          is_step_4: false,
          tracking_details: [],
          track_status: '',
          pgress: '',
          order_date: '',
          shipped_date: '',
          out_for_delivery_date: '',
          delivered_date: '',
          user_bank_detail: '',
          user_payment_card: '',
          beneficiary:'',
          beneficiary_bank: '',
          beneficiary_account_number: '',
          user_detail: '',
          verify_email: '',
          verify_phone: '',
          verify_address: '',
          verify_bvn: '',
          verify_nin: '',
          verify_ubank: '',
          request_deliverys: '',
          walletBal: '',
          timer: null, 
          card_timer: null,
          phone: '',
          email: '',
          address: '',
          verification_code: '',
          countdown_Timer: '',
          interval: '',
          total_paid: 0,
          first_name: '',
          last_name: '',

          user_bvn: '',
          user_nin: '',
          user_name: '',
          user_dob: '',
          user_phone: '',
          selectedFile: '',
          selectedFile1: '',
          loadingd: '',
          order_reference: '',
          transaction_reference: '',
          card_details: '',
          bank_card: '',
          card_type: '',
          card_last4: '',

      };
    },
  
    beforeMount(){
       
    },
  
    mounted(){
  
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');

        this.fetchOrderInformation() 
        this.fetchUserBank(this.user_id)
        this.fetchUserCard(this.user_id)
        this.fetchUserDetails(this.user_id)
        this.reloadWallet()
        this.currentDate = new Date();
         
    },
  
    created(){
          
    },
  
    computed: {
        isSubmitButtonDisabled() {
            return (
              this.verification_code === ''
            );
        },

        totalCheckoutAmount() {
            // Use reduce to sum the checkout_amount of all items
            return this.ordered_items.reduce((total, item) => total + item.unit_price, 0);
        },

        isButtonDisabled() {
            return (
              this.user_nin === '' ||
              this.selectedFile === '' 
            );
        },
        isButtonDisabled1() {
            return (
              this.selectedFile1 === '' 
            );
        },
    },
  
    methods: {
        Addfund(){
            this.isAddfund = true
        },
        myAccount(){
            this.is_myaccount = true
            this.is_myorder = false
        },

        myOrders(){
            this.is_myorder = true
            this.is_myaccount = false
        },

        async verifyEmail(){
            try {
            this.isLoading2 = true
            const response =  await this.$http.post('/verify-email', {
                email: this.email,
            })
            this.isLoading2 = false
            if(response.data.success){
                swal.fire({
                    icon: "success",
                    title: "Success",
                    width: 450,
                    height: 5,
                    text: 'Please follow the verification link sent to your email address',
                  });
                  return;
            }
                
            } catch (err) {
            console.log(err);
            }
        },

        async verify_identity(){
          this.isVerifyIdentity = true
        },

        request_delivery(order_ref){

          // if (this.verify_phone != 1) {
          //     swal.fire({
          //       icon: 'info',
          //       title: 'Warning',
          //       width: 450,
          //       height: 5,
          //       text: 'Please verify your phone',
          //     })
          //     this.redirecttoAccount("Redirecting to verification page...")
          //     return;
          // }
          if (this.verify_address != 1){
              swal.fire({
                icon: 'info',
                title: 'Warning',
                width: 450,
                height: 5,
                text: 'Please verify your address',
              })
              this.redirecttoAccount("Redirecting to verification page...")
              return;
          }
          if (this.verify_ubank != 1) {
              swal.fire({
                icon: 'info',
                title: 'Warning',
                width: 450,
                height: 5,
                text: 'Please verify your bank & BVN',
              })
              this.redirecttoAccount("Redirecting to verification page...")
              return;
          }
          if ( this.verify_email != 1 ){
              swal.fire({
                icon: 'info',
                title: 'Warning',
                width: 450,
                height: 5,
                text: 'Please verify your email',
              })
              this.redirecttoAccount("Redirecting to verification page...")
              return;
          }
          if ( this.verify_nin != 1 ){
              swal.fire({
                icon: 'info',
                title: 'Warning',
                width: 450,
                height: 5,
                text: 'Please verify your nin',
              })
              this.redirecttoAccount("Redirecting to verification page...")
              return;
          }
          this.order_reference = order_ref
          this.isRequestDelivery = true
        },
        async requestDelivery(){
          try {

            this.loadingr = true

              const formData = new FormData();
              formData.append('images', this.selectedFile1),
              formData.append('order_reference', this.order_reference)
              formData.append('userid', this.user_id)
              const response = await this.$http.post('/request-delivery', formData

            );
           
            this.loadingr = false
            if(!response.data.success){
                swal.fire({
                    icon: "warning",
                    title: "Warning",
                    width: 450,
                    height: 5,
                    text: response.data.message,
                  });
                  return;
             }
              swal.fire({
                  icon: "success",
                  title: "Sucess",
                  width: 450,
                  height: 5,
                  text: response.data.message,
                });
                
                this.isVerifyIdentity = false
                window.location.reload()
                return;

            } catch (err) {
            console.log(err);
            }
        },
        pickFile() {
            this.selectedFile = this.$refs.fileInput.files[0];            
        },
        pickFile2() {
            this.selectedFile1 = this.$refs.fileInput1.files[0];            
        },

        async verifyIdentity(){
          try {

            if (this.user_nin == '') {
              swal.fire({
                    icon: 'info',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please input nin',
                })
                return;
            }
            if(this.selectedFile == ''){
              swal.fire({
                    icon: 'info',
                    title: 'Warning',
                    width: 450,
                    height: 5,
                    text: 'Please attach nin document',
                })
                return;
            }
            this.loadingd = true

              const formData = new FormData();
              formData.append('images', this.selectedFile),
              formData.append('nin', this.user_nin),
              formData.append('userid', this.user_id)
              const response = await this.$http.post('/validate-nin', formData

            );
           
            this.loadingd = false
            if(!response.data.success){
                swal.fire({
                    icon: "warning",
                    title: "Warning",
                    width: 450,
                    height: 5,
                    text: response.data.message,
                  });
                  return;
             }
              swal.fire({
                  icon: "success",
                  title: "Sucess",
                  width: 450,
                  height: 5,
                  text: response.data.message,
                });
                
                this.user_nin = ""
                this.selectedFile = ""
                this.isVerifyIdentity = false
                window.location.reload()
                return;

            } catch (err) {
            console.log(err);
            }
        },
        

        async verifyPhone(){

            let verify_msg = confirm("             Verification code will be send to your phone number.")
            if (verify_msg == true) {
                this.countDownTimer()
                this.isSendSms = true
            }
        },

        async countDownTimer() {
            // Set the countdown time in seconds
            var countdownTime = 1200; // 5 minutes = 5 * 60 seconds

            // Update the countdown timer every second
            this.interval = setInterval(() => {
                // Calculate minutes and seconds
                var minutes = Math.floor(countdownTime / 60);
                var seconds = countdownTime % 60;

                // Display the time in the HTML element
                this.countdown_Timer = minutes + ":" + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2});

                // Check if the countdown has finished
                if (countdownTime == 0) {
                    clearInterval(this.interval); // Stop the interval
                    this.countdown_Timer = "00:00";
                }

                // Decrease the countdown time by 1 second
                countdownTime--;
            }, 1000);
        },

        stopTimer() {
            clearInterval(this.interval); // Stop the interval
            this.countdown_Timer = "00:00"; // Reset the timer display
            this.isSendSms = false
        },

        async fetchOrderInformation() {
            try {
    
                this.isLoading = true;
                const response = await this.$http.post('/fetch-order-byuserid', {
                    user_id: this.user_id
                })
    
                this.ordered_items = response.data.orders;
                this.cart_length =  this.ordered_items.length
                
                this.isLoading = false;
    
            } catch (err) {
                console.log(err);
            }
        },

        async vieworder(order_no){
        try {
  
            this.isLoading = true;
            const response = await this.$http.post('/fetch-orders-orderno', {
               order_no: order_no
            })
            
            this.cart_items = response.data.orders;

          this.isLoading = false;
          this.is_order_summary = true
        } catch (err) {
            console.log(err);
        }
      },

        async viewpayment_plan(orderref){
            try {
            this.isLoading1 = true;
            const response = await this.$http.post('/fetch-paymentplan', {
                user_id: this.user_id,
                orderref: orderref
            })

            this.payment_plan = response.data.payment_plan;        
            this.isLoading1 = false;

            this.isPaymentPlans = true

            } catch (err) {
            console.log(err);
            }
        },
        
        makePayment(paymentid){
            this.$router.push('/make-payment/'+paymentid)
        },

        async track_item(orderref){
            try {

            this.isLoading1 = true;
            const response = await this.$http.post('/track-order', {
                order_id: orderref
            })

            this.tracking_details = response.data.tracking_details;  
            this.order_date = this.tracking_details[0].order_placed
            this.shipped_date = this.tracking_details[0].shipped
            this.out_for_delivery_date = this.tracking_details[0].out_for_delivery
            this.delivered_date = this.tracking_details[0].delivered

            if(this.tracking_details[0].track_status == 1){
                this.track_status = "order placed"
                this.pgress = '25%'
                this.is_step_1 = true
                this.is_step_2 = false
                this.is_step_3 = false
                this.is_step_4 = false
            
            }
            if(this.tracking_details[0].track_status == 2){
                this.track_status = "shipped"
                this.pgress = '50%'
                this.is_step_2 = true
                this.is_step_1 = false
                this.is_step_3 = false
                this.is_step_4 = false
            }
            if(this.tracking_details[0].track_status == 3){
                this.track_status = "out for delivery"
                this.pgress = '75%'
                this.is_step_3 = true
                this.is_step_2 = false
                this.is_step_1 = false
                this.is_step_4 = false
            }
            if(this.tracking_details[0].track_status == 4){
                this.track_status = "delivered"
                this.pgress = '100%'
                this.is_step_4 = true
                this.is_step_2 = false
                this.is_step_3 = false
                this.is_step_1 = false
            }
            this.isLoading1 = false;

            this.isTrackItem = true

            } catch (err) {
            console.log(err);
            }
        },

        async fetchUserBank(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-bank', {
                  userid: userid,
                })

                this.user_bank_detail = response.data.bank
                if (this.user_bank_detail == '') {
                   return;
                }
                this.beneficiary = this.user_bank_detail[0].account_name
                this.beneficiary_bank = this.user_bank_detail[0].bank_name
                this.beneficiary_account_number = this.user_bank_detail[0].account_number
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchUserCard(userid) {
            try {

               const response =  await this.$http.post('/fetch-user-card', {
                  userid: userid,
                })

                this.user_payment_card = response.data.card
                this.bank_card = this.user_payment_card[0].bank
                this.card_type = this.user_payment_card[0].card_type
                this.card_last4 = this.user_payment_card[0].last4
                    
            } catch (err) {
              console.log(err);
          }
        },
        
        verify_bank(){
            this.$router.push('/add-bank-bvn')
        },

        async fetchUserDetails(userid){
          try {

            const response =  await this.$http.post('/fetch-user-byid', {
              userid: userid,
            })

            this.user_detail = response.data.users
            this.verify_phone = this.user_detail[0].verify_phone
            this.verify_email = this.user_detail[0].verify_email
            this.verify_address = this.user_detail[0].verify_address
            this.verify_bvn = this.user_detail[0].verify_bvn
            this.verify_nin = this.user_detail[0].verify_nin
            this.verify_ubank = this.user_detail[0].verify_bank
            this.request_deliverys = this.user_detail[0].request_delivery
            this.bank_name = this.user_detail[0].bank_name
            this.account_name = this.user_detail[0].account_name
            this.account_number = this.user_detail[0].account_number
            this.phone = this.user_detail[0].phone_number
            this.email = this.user_detail[0].email_address
            this.address = this.user_detail[0].address
            this.first_name = this.user_detail[0].firstname
            this.last_name = this.user_detail[0].lastname
                
            } catch (err) {
            console.log(err);
            }
        },

        async get_wallet_balance(){
          try{
                  const response = await this.$http.post('/fetch-wallet-balance', {
                      userid: this.user_id,
                  });
                  this.walletBal = response.data.balance
                  return;              
                        
            }catch (err) {
                console.log(err);
            }
        },

        reloadWallet(){
          try{

              this.get_wallet_balance();

              setInterval(() => {
                  this.get_wallet_balance();
              }, 30 * 60 * 1000);                  
          }catch (err) {
              console.log(err);
          }
        },

        async verifyCode(){
            try {

                  this.loading = true
                  const response =  await this.$http.post('/authorise-transaction', {
                      user_details: this.selectedUsers,
                      batch_ref: this.transaction_batch_ref,
                      authorise_code: this.authorise_code,
                  })
                  this.loading = false
                  if(response.data.success){

                      this.authorise_success = response.data.authoriseTransfer
                      this.transaction_success = this.authorise_success['requestSuccessful']
                      if (this.transaction_success) {
                        let authorise_status = this.authorise_success['responseBody']['batchStatus']
                      
                        swal.fire({
                        icon: 'success',
                        title: 'Batch Disbursement',
                        width: 450,
                        height: 5,
                        text: authorise_status,
                      })
                      this.fetchWithdrawal()
                      this.isAutoriseCode = false
                      return
                  }
                }
                      
              } catch (err) {
                console.log(err);
              }
        },

        redirecttoAccount(message) {
          const splashMessage = document.getElementById('splash-message');
          splashMessage.textContent = message;
          splashMessage.style.display = 'block';
          setTimeout(() => {
              splashMessage.style.display = 'none';
              this.$router.push('/myaccounts')
          }, 2000); // Hide the message after 2 seconds
        },

        async add_payment_card(){
          try{
            let verify_msg = confirm("    We will charge your card to ensure its a valid card");

            if (verify_msg == true) {
                  const response = await this.$http.post('/add-payment-card', {
                      userid: this.user_id,
                      firstname: this.firstname,
                      lastname:  this.lastname, 
                      email: this.email_address,
                  });
                      
                  this.card_details = response.data.transfer_data
                  this.transaction_reference = this.card_details.reference
                  const transaction_url = this.card_details.authorization_url
                  window.location.href = `${transaction_url}`
                  this.checkPaymentStatus()
                  return;
                }            
                        
              }catch (err) {
                    console.log(err);
              }
        },

        checkPaymentStatus(){
            try{
                this.card_timer = setInterval(() => {
                    this.checkTransactionStatus();
                }, 10000);                                 
            }catch (err) {
                console.log(err);
            }
        },

        async checkTransactionStatus(){
            try{

                    this.response = await this.$http.post('/verify-charge-payment', {
                        transaction_reference: this.transaction_reference,
                    });

                    if (this.response.data.status) {                     
                          this.showSplashMessage("Payment successful!")    
                            
                          this.stopSendingRequests()                
                          return;            
                }            
            }catch (err) {
                console.log(err);
            }
        },

        showSplashMessage(message) {
          const splashMessage = document.getElementById('splash-message');
          splashMessage.textContent = message;
          splashMessage.style.display = 'block';
          setTimeout(() => {
              splashMessage.style.display = 'none';
              this.$router.push('/shopping')
          }, 2000); // Hide the message after 2 seconds
        },

        stopSendingRequests() {
            clearInterval(this.card_timer);
        },
       
    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  .file-input{
    opacity: 0;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .add-nin{
    background: url('../assets/attachment.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .counter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  .modal-mask3 {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content3{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    //margin-right: 8px;
  }
  .account-content-h3{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 4px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  .acct-d{
    margin: 4px;
    border-bottom: solid 1px;
  }
  .account-content-d3{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .account-content-d3-1{
    display: flex;
    flex-direction: column;
    padding: 0.9rem;
    padding-left: 0.3rem;
  }
  .btn-success{
    background: #800080c7;   
    border: solid 0px;
    margin-bottom: 10px;
  }
  .cursor-verify{
    cursor: pointer;
    font-size: 9px;
    font-style: italic;
  }
  .add-fund{
    font-size: 10px
  }
  .container-fluid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 0.9rem;
    height: 80vh;
  }
  .account-menu{
    display: flex;
    flex-direction: column;
    height: 70vh;
    background: #fff;
    width: 20%;
    border-radius: 4px;
    margin: 5px;
  }
  .menu{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
    cursor: pointer;
    font-weight: 900;
    color: #000000b5;
  }
  .menu:hover{
    background: #cccccc98;
  }
  .menu1{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
  }
  
  .menu2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    border-top:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 12px;
    padding: 0.8rem;
    cursor: pointer;
    font-weight: 900;
    color: #000000b5;
  }
  .menu2:hover{
    background: #cccccc98;
  }
  .menu-content{
    display: flex;
    flex-direction: column;
    height: 70vh;
    background: #fff;
    width: 80%;
    border-radius: 4px;
    margin: 5px;
  }
  .menu-content1{
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    width: 100%;
    border-radius: 4px;
    margin: 5px;
  }
  .account-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 15px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .account-head1{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    border-bottom:  solid 1px #ccc;
    font-family: Mulish;
    font-size: 15px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  thead{
    font-size: 10px;
  }
  .account-overview{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .account-col1{
    display: flex;
    flex-direction: column;
    background: #fff;
    border: solid 1px #ccc;
    width: 80%;
    border-radius: 4px;
    margin: 5px;
  }
  .account-col2{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    border-bottom:  solid 1px #ccc;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .account-col22{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
  }
  .account-col3{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-content: center;
    box-sizing: border-box;
    height: auto;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 500;
    padding: 0.8rem;
    color: #000000b5;
  }
  .text-size{
    font-family: Mulish;
    font-size: 10px;
  }
  .name{
    font-size: 14px;
    font-family: Mulish;
  }
  .email{
    font-size: 13px;
    font-weight: 500;
    font-family: Mulish;
    color: #00000076;
  }
  .phone{
    font-size: 11px;
    font-weight: 500;
    font-family: Mulish;
    color: #00000076;
  }
  .shipping{
    font-size: 14px;
    font-family: Mulish;
  }
  .address{
    font-size: 12px;
    font-family: Mulish;
    color: #00000076;
  }
  .fa-check-circle{
    color: #27963d;
  }
  .cursor{
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    font-family: Mulish;
  }
  #progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: rgb(252, 103, 49);
    padding-left: 0px;
    margin-top: 0vh
}

#progressbar li {
    list-style-type: none;
    font-size: x-small;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: rgb(160, 159, 159)
}

#progressbar #step1:before {
    content: "";
    color: rgb(252, 103, 49);
    width: 5px;
    height: 5px;
    margin-left: 0px !important
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-left: 32%
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 32%
}

#progressbar #step4:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 0px !important
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #ddd;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh
}

#progressbar li:after {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 1px;
    z-index: 1
}
.progress-track {
    padding: 0 8%;
    width:100%;
    text-transform: capitalize;
    font-size: 8px !important;
}
.bar-width{
    width:100%;
    height: 10px;
    font-size: 9px;
    font-weight: bold;
}

#progressbar li:nth-child(2):after {
    margin-right: auto
}

#progressbar li:nth-child(1):after {
    margin: auto
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%
}

#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%
}

#progressbar li.active {
    color: black
}
#progressbar li.active:before,
#progressbar li.active:after {
    background: rgb(252, 103, 49)
}

  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    //margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 4px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;

    span{
        font-size: 10px;
        font-family: Mulish;
        font-weight: normal;
        text-transform:capitalize;
    }
  }
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    height: 200px;
    overflow-y: auto;

    span{
      font-size: 12px;
      font-family: Mulish;
    }

    .bvn-input{
      font-size: 11px;
      font-family: Mulish;
      width: 100%;
    }
  }

  .account-content-nin{
    display: flex;
    flex-direction: row;
    justify-content: left; 
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    height: auto;
    overflow-y: auto;

    span{
      font-size: 12px;
      font-family: Mulish;
    }

    .bvn-input{
      font-size: 11px;
      font-family: Mulish;
      width: 100%;
    }
  }

  .account-content-d1{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    height: 100px;
    overflow-y: auto
  }
  
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }
  
  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }
  
  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }
  
  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }
  
  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }
  
  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }
  
  .inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    padding: 0.4rem;
    overflow-y: auto;
  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 10%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
    border: solid 1px #ccc;
  }
  .empty-cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .cart-e{
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 14px;
  }
  .cart-e2{
    font-family: Mulish;
    font-size: 14px;
    margin-top: -20px;
  }
  .btn-shopping{
    background: #800080;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 14px;
    padding: 0.5rem;
    font-family: Mulish;
  }
  .fa-shopping-bag{
    font-size: 60px;
    color: #800080;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 12px;
    background: #fff;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
  }
  
  .btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #800080;
    color: #fff;
    border-radius: 4px;
  }
  .counter-action{
    font-size: 13px;
  }
  .login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .payment-terms{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 12px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
    text-transform: capitalize;
    span{
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .cancel-order{
    font-size: 9px;
    font-family: Mulish;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    //color: #800200;
  }
  .payment-schedule{
    cursor: pointer;
    width: 120px !important;
    border-radius: 4px;
    text-align: left;
    font-size: 9px;
    font-family: Mulish;
    font-weight: 500;
  }
  .payment-schedule:hover{
    color: #044427f8;
  }
  .cancel-order:hover{
    color: #4d1606fe;
  }
  .schedule{
    border-radius: 2px;
    border: solid 1px #8000804c;
    padding: 0.4rem;
    color: #800080;
    cursor: pointer;
  }
  .proceed{
    border-radius: 2px;
    padding: 0.4rem;
    background:#250325;
    color: #fff;
    cursor: pointer;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #800080;
        font-size: 15px;
      }
    .btn-remove:hover{
      color: #a5a3a3;
    }
  }
  
  .order-number{
    background: #800080; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .check-out{
    margin-top:60px; 
    a{
      text-decoration: none
    }
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100px; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
  }
  .product-name{
    margin-top:-10px;
    .cart-title{
        font-size: 14px;
        font-family: Mulish;
        font-weight: 500;
        color: #2b2929;
    }
    .brand{
        font-weight: 500;
        font-size: 11px;
        line-height: 0.1px;
    }
    .brand1{
        font-size: 10px;
        line-height: 0.1px;
    }
    .in-stock{
        font-size: 12px;
        line-height: 1;
        margin-top: -10px;
        color: #a5a3a3;
      }
  }
  .product-price{
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    div{
      margin: 5px;
    }
  }

  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #800080;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }
  
  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }
  
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
  }
  .delivery-status{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;
  
  }
  .delivery-return{
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
  }
  .d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
    padding: 0.3rem;
    margin-top: 30px;
    border-bottom: solid 1px #a5a3a37b;
  }
  .location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
  }
  
  @media screen and (max-width: 768px) {
    .container-fluid{
      display: none;
    }
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
      
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
      
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
      }
      .account-content3{
        display: flex;
        flex-direction: column;
        justify-content: center;  
        //margin-right: 8px;
      }
      .account-content-h3{
        display: flex;
        flex-direction: column;
        justify-content: center;  
        align-items: center;
        width: 100%;
        height: 50px;   
        margin: 4px;
        padding-top: 1.3rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
      }
      .account-content-d3{
        padding-top: 1.3rem;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 0.9rem;
        padding-left: 0.3rem;
      }
      .account-content-d3-1{
        display: flex;
        flex-direction: column;
        padding: 0.9rem;
        padding-left: 0.3rem;
      }
      .account-head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        box-sizing: border-box;
        height: auto;
        border-bottom:  solid 1px #ccc;
        font-family: Mulish;
        font-size: 10px;
        font-weight: 500;
        padding: 0.8rem;
        color: #000000b5;
      }
    .account-menu{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 20%;
        border-radius: 4px;
        margin: 5px;
    }
    .menu{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        box-sizing: border-box;
        height: auto;
        border-bottom:  solid 1px #ccc;
        font-family: Mulish;
        font-size: 12px;
        padding: 0.8rem;
        cursor: pointer;
        font-weight: bold;
        color: #000000b5;
      }
      .menu2{
        display: none;
      }
    .icon-text{
        display: none;
    }
    .brand1{
      font-size: 10px;
      //line-height: 0px;
  }
    .menu-content{
        display: flex;
        flex-direction: column;
        height: 67vh;
        background: #fff;
        width: 100%;
        border-radius: 4px;
        margin: 5px;
      }
      .menu-content1{
        display: flex;
        flex-direction: column;
        height: 67vh;
        background: #fff;
        width: 100%;
        border-radius: 4px;
        margin: 5px;
      }
    .account-overview{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      .account-col1{
        display: flex;
        flex-direction: column;
        background: #fff;
        border: solid 1px #ccc;
        width: 95%;
        border-radius: 4px;
        margin: 5px;
      }

    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
      .product-name{
        margin-top: 0px;
      }
      
      .column9{
        width: 100%;
        margin-top: 350%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-price{
      display: flex;
      flex-direction:row;
      justify-content: left;  
      font-family: Mulish;
      font-size: 10px; 
      text-transform: capitalize;
    }
    .payment-schedule{
      font-size: 10px;
    }
    .cancel-order{
      font-size: 10px;
    }
    .product-counter-remove{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0.6rem;
      
      font-family: Mulish;
      background: #fff;
      .btn-remove{
          text-transform: uppercase;
          color: #800080;
          font-size: 12px;
        }
      .btn-remove:hover{
        color: #a5a3a3;
      }
    }
    .btn-counter{
      padding: 6px;
      font-weight: bold;
      font-family: Mulish;
      font-size: 12px;
      margin: 2px;
      width: 12%;
      background: #800080;
      color: #fff;
      border-radius: 4px;
    }
  
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      height: 220px;
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }
  
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      margin-right: 8px;
      width: 106%;
      padding: 10px;
    }
  
  }
    

  
  </style>