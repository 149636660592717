<template>
    <div>
        <div class="package-header">
            <p>&nbsp;</p>
            <p class="package-title">PROFILE</p>
            <p>&nbsp;</p>
        </div>
        <div class="package-container">
            <div class="product">
                <div class="product-0">
                    <div class="profile-info">
                        <div>
                          <img src="../assets/profile_img.png" alt="" class="p-img">
                        </div> 
                        
                    </div>
                    <div class="product-1">
                      <div class="product-1-info">
                        <span>Firstname:</span>
                        <span class="badge badge-secondary">{{vfirstname}}</span>
                      </div>
                      <div class="product-1-info">
                        <span>Lastname:</span>
                        <span class="badge badge-secondary">{{vlastname}}</span>
                      </div>
                      <div class="product-1-info">
                        <span>Email:</span>
                        <span class="badge badge-secondary">{{vemail}}</span>
                      </div>
                      <div class="product-1-info">
                        <span>Phone:</span>
                        <span class="badge badge-secondary"> {{vphone}}</span>
                      </div>
                      <div class="product-1-info">
                        <span>Date Joined:</span>
                        <span class="badge badge-secondary">{{ new Date(date_joined).toDateString()}}</span>
                      </div>
                    </div>                   
                </div>
                <div class="product-2">
                  <div><strong>Update Password</strong></div>
                  <div class="product-2-info">
                    <span>Username:</span>
                    <span class="badge badge-secondary">{{vusername}}</span>
                  </div>
                  <div class="product-2-info">
                    <span>********</span>
                    <span class="edit-password" @click="editPassword()"><i class="fa fa-edit"></i></span>
                  </div>
                </div>
            </div>   
        </div>   
        <div v-if="isEditPassword">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   change password
                                </div>
                                <div class="account-content-d">
                                  <span>Password</span><br>
                                  <input type="password" class="form-control" placeholder="Password" v-model="password">
                                  <span>Confirm password</span><br>
                                  <input type="password" class="form-control" placeholder="Confirm Password" v-model="confirm_password">
                                </div>
                                <button class="btn btn-success" :disabled="isSubmitButtonDisabled" @click="submit()" v-if="!loading">Submit</button>
                                <button class="btn btn-success btn-block" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="btn btn-secondary" @click="isEditPassword = false"><i class="fa fa-times"></i> Close</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    import swal from 'sweetalert2';
    import PageLoader from '../components/PageLoader.vue'
    export default {
        name: "ProfilePage",
        components: {
            PageLoader
        },
      
        data(){
          return {
    
              isLoading: false,
              isEditPassword: false,
              user_id: '',
              firstname: '',
              lastname: '',
              bank_name:'',
              account_name:'',
              account_number: '',
              ussd: '',
              exp_time: '',
              referral_code: '',
              loading: false,
              plans: [],      
              transfer_data: [], 
              current_plan: '',   
              profile: '',

              vfirstname: '',
              vlastname: '',
              vemail: '',
              vphone: '',
              vusername: '',
              vplan:'',
              vamount: '0.00',
              vaccount_name: '',
              vbank: '',
              vaccount: '',
              date_joined: '',
              plan_active_status: '',

              password: '',
              confirm_password: '',
          };
        },
      
        beforeMount(){
         
        },
      
        mounted(){
    
            this.firstname = localStorage.getItem("firstname");
            this.lastname = localStorage.getItem("lastname");
            this.phone_number = localStorage.getItem("phone");
            this.email_address = localStorage.getItem("email");
            this.user_id = localStorage.getItem("userid");
            this.referral_code = localStorage.getItem("referral_code");
            this.fetchProfile()
        },
      
        created(){
              
        },
      
        computed: {
          isSubmitButtonDisabled() {
            return (
              this.password === '' ||
              this.confirm_password === ''
            );
          }
        },
      
        methods: {
        
           generateRandomId() {
              return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
           },
    
            setShowBtn(index, value) {
              this.$set(this.showBtn, index, value);
            },
    
            async fetchProfile() {
                try {
    
                   this.isLoading = true
                   const response =  await this.$http.post('/fetch-current-plan', {
                        userid: this.user_id
                    })
                    this.isLoading = false
                    this.profile = response.data.users
                    this.vfirstname = this.profile[0].firstname
                    this.vlastname = this.profile[0].lastname
                    this.vusername = this.profile[0].username
                    this.vemail = this.profile[0].email_address
                    this.vphone = this.profile[0].phone_number

                    this.vplan = this.profile[0].plan_name
                    this.vamount = this.profile[0].amount 
                    this.vaccount_name= this.profile[0].account_name
                    this.vbank = this.profile[0].bank_name
                    this.vaccount = this.profile[0].account_number
                    this.date_joined = this.profile[0].created_at
                    this.plan_active_status = this.profile[0].plan_active_status
                        
                } catch (err) {
                  console.log(err);
              }
            },

            editPassword(){
              this.isEditPassword = true
            },

            async submit(){
              try {
  
                    this.loading = true
                    const response =  await this.$http.post('/update-password', {
                        userid: this.user_id,
                        password: this.password,
                        confirm_password: this.confirm_password
                    })
                    this.loading = false
                    if(response.data.success){
                        swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                      })
                      this.isEditPassword = false
                      return;
                    }
                        
                } catch (err) {
                  console.log(err);
                }
            }
        }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
      position: fixed;
      //z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      display: table;
      transition: opacity .4s ease;
    
      &>div {
          width: 80%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;;
          backdrop-filter: blur(13px);
          border-radius: 10px;
          box-sizing: border-box;
          font-family: Mulish;
      }
    
      h3 {
          margin-top: 10px;
          font-size: 18px;
          color: #000;
          font-weight: bold;
          font-family: Mulish;
      }
    }
    .account-content{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      margin-right: 8px;
    }
    .account-content-h{
      display: flex;
      flex-direction: column;
      justify-content: center;  
      align-items: center;
      width: 100%;
      height: 50px;   
      margin: 4px;
      padding-top: 1.3rem;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    .account-content-d{
      padding-top: 1.3rem;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .btn-success{
      background: #800080c7;   
      border: solid 0px;
      margin-bottom: 10px;
    }
    .p-img{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 80px;
      height: 80px;
      background: #312F33;
      border-radius: 47px;
      margin: 10px;
    }
    .active-status{
      background: #312f334b;
      border-radius: 4px;
      margin: 10px;
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .package-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #ccc;
      }
      .package-title{
        font-size: 30px;
        font-weight: bold;
        font-family: Mulish;
        color: #800080;
      }
    .profile-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 15px; 
        font-weight: bold; 
    }
    .package-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.9rem;
        background: #fff;
        border-radius: 4px;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Mulish;
        font-size: 12px;
        border: solid 0px #ccc;
        margin-bottom: 40px;
        border-radius: 6px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
        padding: 1.2rem;
        width: 50%;
      }
      .product-0{
        width: 100%;
      }
      .product-1{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
      }
      .product-1-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .product-2{
        display: flex;
        flex-direction: column;
        font-family: Mulish;
        font-size: 12px;
        border: solid 1px #ccc;
        margin-bottom: 30px;
        border-radius: 6px;
        box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.1); 
        padding: 0.5rem;
        width: 100%;
      }
      .product-2-info{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 8px;
        padding: 0.2rem;
        border-bottom: solid 1px #ccc;
      }
      .edit-password{
        cursor: pointer;
      }
      .form-control{
        height: 45px;
        margin-bottom: 4px;
      }
    
      .fa-user{
        font-size: 120px;
        color: #800080;
      }
    
      @media screen and (max-width: 768px) {
        .package-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .product-0{
            width: 100%;
          }
          .product{
            display: flex;
            flex-direction: column;
            padding: 1.7rem;
            font-family: Mulish;
            width: 100%;
    
          }
          .product-1-info{
           span{
            font-family: Mulish;
            font-size: 9px !important;
           }
          }
          .account-content{
            display: flex;
            flex-direction: column;
            justify-content: center;  
            margin-right: 8px;
            width: 106%;
            padding: 10px;
          }
      }
    </style>