<template>
    <div>
      <div id="splash-message" class="splash-message"></div>
        <div class="product-container">
            <div class="column9" v-if="paymentPad">
                <div class="cart-0">
                    <div class="product">
                        <div class="product-detail">
                            <div class="product-name">
                                <p class="cart-title"> you're paying for</p>
                                <p class="brand">BVN & Bank Verification</p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number">
                   
                </div>
                <div class="cart-0">
                    <div class="center-p">
                        <div class="center-p-0-0">
                            <div class="center-p-0">
                                <p class="text-center">You'll be required to pay with USSD or Bank Transfer</p>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="product-cart-number">
                  <button id="submit4" class="btn btn-primary" @click="bankPay()" v-if="!isPay">pay now (₦{{ plan_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}})</button>
                  <button class="btn btn-primary" type="button" disabled v-if="isPay">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
            </div>
            <div class="column9" v-if="bankTransfer">
              <p class="payment-instruction">Please follow the instruction below carefully and do not refresh or leave this page.
                Payment confirmation may take up to 2 minutes
              </p>

              <div class="cart-0" v-if="bank_details != '' ">
                  <div class="product">
                      <div class="product-detail">
                          <div class="product-name">
                              <p class="cart-title-t"><u>Transfer to the following account</u></p>
                              <p class="brand-t">Bank Name: <span>{{payment_bank_name}}</span> </p>
                              <p class="brand-t">Account Number: <span>{{payment_account_number}}</span> </p>
                              <p class="brand-t">Beneficiary Name: <span>{{payment_account_name}}</span> </p>
                              <hr>
                              <p class="brand-t">USSD Code Transfer: <span>{{ussd_code}}</span> </p>
                              <hr>
                              <span style="font-size: 9px;">Transfer exact amount to avoid failure</span>
                              <p class="brand-t">Amount to pay: <span> ₦{{payment_amount_payment}}</span> </p>
                              <p class="brand-t">This account expires after {{ accountDurationSeconds }}mins.</p>
                          </div>
                          <div></div>
                          <div class="payment-made">
                            <button id="submit4" class="btn btn-default" @click="paymentMade()" v-if="processBtn">I have paid</button>
                            <div v-if="process">
                              <center><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></center>
                              <p style="text-align: center;">We are waiting for your payment</p>
                            </div>
                            <p style="text-align: center;" v-if="processBtn">Once transfer is successful, click the button above</p>
                          </div>
                      </div>
                  </div>  
              </div>
              <div class="cart-0" v-if="bank_details == '' ">
                <div class="generating-account">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generating payment details. Please wait...
                </div>
              </div>
            </div>
        </div>
        <div class="terms" v-if="!bankTransfer">
            <p>By tapping on 'PAY NOW' you have accept our Terms & Conditions and Privacy Policy</p>
            <p><router-link to="/bank"><i class="fa fa-arrow-left"></i> Back</router-link></p>
        </div>
        
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../components/PageLoader.vue'
  export default {
    
    name: "PaymentPage",
    components: {
        PageLoader,
    },
  
    data(){
      return {

          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          address: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL+'products/',
          payment_bank_name: '',
          payment_account_name: '',
          payment_account_number: '',
          payment_amount_payment: '',
          ussd_code: '',
          transaction_reference: '',
          transaction_details: '',
          isSending: false,
          //duration: 20 * 60 * 1000, // 20 minutes in milliseconds
          interval: 10000, // 30 seconds in milliseconds
          timer: null, 
          loading3: false,
          btnConfirmOrder: false,
          cart_detail: [],
          total_amount: '',
          selectedPaymentMethod: '',
          paymentPad: true,
          bankTransfer: false,
          walletBal: '',
          isPay: false,
          accountDurationSeconds: '',
          bank_details: '',
          processBtn: true,
          process: false,
          is_order_summary: false,
          cart_length: '',
          cart_items: [],
          planid: '',
          plan_name: '',
          plan_amount: '',

          b_name:'',
          b_code: '',
          baccount_name: '',
          baccount_number: '',
          beneficiary_bvn: '',
      };
    },
  
    beforeMount(){
      this.isLoading = true
    },
  
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.address = localStorage.getItem("address");
        this.user_id = localStorage.getItem("userid");
        this.planid = this.$route.params.planid
        this.plan_name = this.$route.params.plan
        this.plan_amount = 100   
       
        this.isLoading = false                 
    },
  
    created(){
          
    },
  
    computed: {

    },
  
    methods: {

        async generate_payment_detail(){
                try{
                        const response = await this.$http.post('/subscribe-plan', {
                            userid: this.user_id,
                            firstname: this.firstname,
                            lastname:  this.lastname, 
                            email: this.email_address,
                            amount: this.plan_amount,
                        });
                            
                        this.bank_details = response.data.transfer_data
                        this.payment_bank_name = this.bank_details.responseBody.bankName
                        this.payment_account_name = this.bank_details.responseBody.accountName
                        this.payment_account_number = this.bank_details.responseBody.accountNumber
                        this.ussd_code = this.bank_details.responseBody.ussdPayment
                        this.payment_amount_payment = this.bank_details.responseBody.amount
                        this.transaction_reference = this.bank_details.responseBody.transactionReference
                        let time = this.bank_details.responseBody.accountDurationSeconds
                        this.accountDurationSeconds = time / 60;
                        return;              
                        
                }catch (err) {
                    console.log(err);
                }
        },

        paymentMade(){
                try{
                 
                    this.processBtn = false
                    this.process = true
                    this.timer = setInterval(() => {
                        this.checkTransactionStatus();
                    }, this.interval);
                   this.loading3 = false
                                 
                }catch (err) {
                    console.log(err);
                }
        },

        async checkTransactionStatus(){
            try{

                    this.response = await this.$http.post('/payment-made', {
                        transaction_reference: this.transaction_reference,
                    });

                    if (this.response.data.success) {
                                            
                        this.transaction_details = this.response.data.transaction_data
                        this.transaction_success = this.transaction_details['requestSuccessful']
                        this.transaction_success_code = this.transaction_details['responseCode']
                        this.transaction_payment_status = this.transaction_details['responseBody']['paymentStatus']
                      
                        if (this.transaction_payment_status == 'PAID') {                         
                            // update plan_active_status & current_plan table in users table
                            await this.$http.post('/create-payment', {
                              userid: this.user_id,
                              planid: this.planid,
                              payment_ref: this.transaction_reference,
                              amount: this.plan_amount
                            })

                            // save bank details
                            this.createBank() // invoke create bank function

                            // remove items from local storage
                            localStorage.removeItem('bname')
                            localStorage.removeItem('bcode')
                            localStorage.removeItem('baccount_name')
                            localStorage.removeItem('baccount_number')
                            localStorage.removeItem('bbeneficiary_bvn')

                            this.showSplashMessage("Payment successful. Validating Bank Verification Number...");                            
                            this.stopSendingRequests() 
                                                  
                            return;
                            
                        }
                        else {
                           // do nothing
                        }
                        return;              
                }            
            }catch (err) {
                console.log(err);
            }
        },

        stopSendingRequests() {
            clearInterval(this.timer);
            this.isSending = false;
        },

       async bankPay(){
        try {
                this.isPay = true
                this.paymentPad = false
                this.bankTransfer = true
                this.generate_payment_detail()
                this.paymentMade()
                this.isPay = false
            
            } catch (err) {
            console.log(err);
            }
       },

       showSplashMessage(message) {
        const splashMessage = document.getElementById('splash-message');
        splashMessage.textContent = message;
        splashMessage.style.display = 'block';
        setTimeout(() => {
            splashMessage.style.display = 'none';
        }, 20000); // Hide the message after 10 seconds
      },

      // create bank details
      async createBank() {
            try {

                this.b_name = localStorage.getItem('bname')
                this.beneficiary_bank =   localStorage.getItem('bcode')
                this.beneficiary =  localStorage.getItem('baccount_name')
                this.beneficiary_account_number =  localStorage.getItem('baccount_number')
                this.beneficiary_bvn =  localStorage.getItem('bbeneficiary_bvn')
                              
                const response =  await this.$http.post('/create-bank', {
                    userid: this.user_id,
                    bank_name: this.b_name,
                    bank_code: this.beneficiary_bank,
                    account_name: this.beneficiary,
                    account_number: this.beneficiary_account_number,
                    beneficiary_bvn: this.beneficiary_bvn,
                })  
                
                if(!response.data.success){
                swal.fire({
                    icon: "warning",
                    title: "Warning",
                    width: 450,
                    height: 5,
                    text: response.data.message,
                  });
                  this.$router.push('/myaccounts')
                  return;
                }
                
                swal.fire({
                    icon: "success",
                    title: "Bank/BVN Validation",
                    width: 450,
                    height: 5,
                    text: 'Bank Verification Successful!',
                  });

                  this.verifyBank()
                  this.$router.push('/myaccounts')
                
               
            } catch (err) {
              console.log(err);
        }
      },

      async verifyBank() {
            try {
                                                 
                await this.$http.post('/verify-bank', {
                    userid: this.user_id,
                })           
               
            } catch (err) {
            console.log(err);
        }
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .splash-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: none;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    border-bottom: solid 1px #071278;
    color: #071278;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .terms{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
    text-align: center;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-family: Mulish;
    font-size: 13px;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
    color: #626060;
    text-transform: uppercase;
    font-weight: normal;
  }
  .address{
    color: #071278;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.generating-account{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size:15px;
}
.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #071278;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.3rem;
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #071278;
        font-size: 12px;
      }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }

  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    width: 100%;
  }
  .payment-span{
    margin: 15px;
  }
  .payment-method{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 15px;
    font-size: 12px;
  }
  .product-name{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    margin-right: 10px;
    width: 100%;
    .cart-title{
        margin-top: -10px;
        font-size: 14px;
        font-weight: bold;
        color: #6e6c6c;
        text-transform: uppercase;
        text-align: center;
    }
    .cart-title-t{
      margin-top: -10px;
      font-size: 12px;
      font-weight: bold;
      color: #6e6c6c;
  }
    .brand{
        margin-top: 2px;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        span{
          font-weight: bold;
          font-size: 15px;
        }
    }
    .brand-t{
      margin-top: 2px;
      font-size: 10px;
      font-weight: 500;
      span{
        font-weight: bold;
        font-size: 13px;
      }
  }
   
  }
  .center-p{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .center-p-0{
    p{
      text-align: center;
      font-weight: 450;
    }
  }
  .payment-made{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    font-size: 12px;
    border-radius: 4px;
    border: solid 10px #a5a3a3;
    .btn-default{
      color: #fff;
      background: rgb(42, 136, 230);
      font-size: 12px !important;
    }
    p{
      color: #a5a3a3;
    }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #800080c7;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .payment-instruction{
    color: #a5a3a3;
    font-size: 12px;
    text-align: center;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;

}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
    margin-bottom: 14px;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .product-detail{
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      width: 100%;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
     
      .column9{
        width: 100%;
      }
      .bank-transfer{
        padding: 1.9rem;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>