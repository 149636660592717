<template>
<div class="backgound">
    <div class="container1">
        <div class="col-lg-12 mt-0 p-4 header">
            <div class="logo"><img src="logo1.png" alt="logo"></div>
            <div class="login"><span class="account">Already have an account?</span> <router-link to="/login">Login</router-link></div>
        </div>
        <div class="col-lg-12 col-sm-12 login-container">
            <div class="col-lg-8 col-sm-8 app-title">
                <h2>Create Account</h2> 
                <p>Buy & Pay Small Small</p>
            </div>
            <div class="col-lg-4 col-sm-4 login-form">
                <div class="login-form-inner">
                    <p class="text-black text-uppercase">Lets Get You Started</p>
                    <div class="inputs"> 
                        <div>
                            <label for="">Firstname *</label>
                            <input type="text" id="frstname" class="form-control inputs-field" placeholder="Enter Firstname" v-model="firstname" required>
                        </div>
                        <div>
                            <label for="">Lastname *</label>
                            <input type="text" id="lastname" class="form-control inputs-field" placeholder="Enter Lastname" v-model="lastname" required>
                        </div>
                    </div>
                    <div class="inputs"> 
                        <div>
                            <label for="">Username *</label>
                            <input type="text" id="username" class="form-control inputs-field" placeholder="Enter Username" v-model="username" required>
                        </div>
                        <div>
                            <label for="">Email *</label>
                            <input type="email" id="email" class="form-control inputs-field" placeholder="Enter Email address" v-model="email_address" required>
                        </div>
                    </div>
                    <div class="inputs"> 
                        <div>
                            <label for="">Phone *</label>
                            <div style="display: flex; flex-direction: row">
                                <vue-country-code
                                    @onSelect="onSelect"
                                >
                                </vue-country-code> &nbsp;
                                <input type="text" id="phone" class="form-control inputs-field" placeholder="Enter Phone number" v-model="phone_number" required>
                            </div>
                        </div>
                    </div>
                    <div class="inputs"> 
                        <div>
                            <label for="">Password *</label>
                            <input type="password" id="show_text" class="form-control inputs-field" placeholder="Enter password" v-model="password" required >
                            <span class="p-viewer" @click="showText">
                                <i class="fa fa-eye" aria-hidden="true" id="eye_close"></i>
                                <i class="fa fa-eye-slash" aria-hidden="true" id="eye_open" style="display:none"></i>
                            </span>
                        </div>
                        <div>
                            <label for="">Confirm Password *</label>
                            <input type="password" id="show_text2" class="form-control inputs-field" placeholder="Confirm password" v-model="confirm_password" required>
                            <span class="p-viewer2" @click="showText2">
                                <i class="fa fa-eye" aria-hidden="true" id="eye_close2"></i>
                                <i class="fa fa-eye-slash" aria-hidden="true" id="eye_open2" style="display:none"></i>
                            </span>
                        </div>
                    </div>
                    <div class="btn-inputs">
                        <div>
                            <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled" @click="submit_form()" v-if="!loading3">Submit</button>
                        <button class="btn btn-danger btn-block" type="button" disabled v-if="loading3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        <div class="terms-conditions">
                            By creating an account you accept our<br> <span><a href="https://dailychamp.ng/terms-and-conditions">Terms & Conditions</a><br><a href="https://dailychamp.ng">Daily Champ Website</a> | <a href="/shopping">Shop</a></span>
                        </div>
                        </div>
                    </div>               
                </div>
                <p>&nbsp;</p> 
            </div>
        </div>
    </div>
   
</div>
</template>
<script>
import Vue from 'vue'
import VueCountryCode from "vue-country-code";
import swal from 'sweetalert2';

Vue.use(VueCountryCode);

export default {
    
    name: 'SignupPage',
    components: {

    },
    
    data() {
    return {
        appTitle: "Daily Champ",
     
        submit_3: false,
        firstname: '',
        lastname: '',
        username: '',
        email_address: '',
        phone_number: '',
        password: '',
        confirm_password: '',
        phone_number_code: '',

        loading3: false,

        // TIMER SETTING
        totalSeconds: 0, // Initialize the total seconds
        timer: null,
        tokenValid: true,
      
    };
  },

  beforeMount(){
    document.title = this.appTitle
  },
  mounted() {
   
  },
  created(){
    
  },
  computed: {
    formattedTime() {
        //const displayHours = Math.floor(this.totalSeconds / 3600);
        const displayMinutes = Math.floor((this.totalSeconds % 3600) / 60);
        const displaySeconds = this.totalSeconds % 60;
        return `${displayMinutes.toString().padStart(2, '0')} : ${displaySeconds.toString().padStart(2, '0')}`;
        //return `${displayHours.toString().padStart(2, '0')} : ${displayMinutes.toString().padStart(2, '0')} : ${displaySeconds.toString().padStart(2, '0')}`;
    },

    isPhoneNumberValid() {
    return (
      this.phone_number === ''
    );
  },
  isTokenValid(){
    return (
      this.validate_token === ''
    );
  },
  isSubmitButtonDisabled() {
    return (
      this.firstname === '' ||
      this.lastname === '' ||
      this.username === '' ||
      this.email_address === '' ||
      this.phone_number === '' ||
      this.password === '' ||
      this.confirm_password === ''
    );
  }
  },

  methods:{

    showText(){
            const input =  document.getElementById('show_text');
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
                  document.getElementById('eye_close').style.display="none";
                  document.getElementById('eye_open').style.display="block";
                } else {
                input.setAttribute("type", "password");
                  document.getElementById('eye_close').style.display="block";
                  document.getElementById('eye_open').style.display="none";
                }
        },
        showText2(){
            const input =  document.getElementById('show_text2');
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
                  document.getElementById('eye_close2').style.display="none";
                  document.getElementById('eye_open2').style.display="block";
                } else {
                input.setAttribute("type", "password");
                  document.getElementById('eye_close2').style.display="block";
                  document.getElementById('eye_open2').style.display="none";
                }
        },

    onSelect({name, iso2, dialCode}) {
       console.log(name, iso2, dialCode);
       this.phone_number_code = dialCode
     },

    select1(){
        if (this.education_level == "") {
            document.getElementById("submit1").disabled = false
            return;
        }

        document.getElementById("submit1").disabled = true
    },

    select2(){
        if (this.work_experience == "") {
            document.getElementById("select_field").disabled = false
            return;
        }
        document.getElementById("select_field").disabled = true
    },

    select3(){
        if (this.interests == "") {
            document.getElementById("submit3").disabled = false
            return;
        }
        document.getElementById("submit3").disabled = true
    },

    select4(){
        if (this.work_field == "") {
            document.getElementById("submit2").disabled = false
            return;
        }
        document.getElementById("submit2").disabled = true
    },

    async submit_form() {
            try {

                if (this.password != this.confirm_password) {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: 'Password does not match',
                    })
                    return;
                }

                this.loading3 = true

                const response = await this.$http.post('/create-buyer', {
                  firstname: this.firstname,
                  lastname: this.lastname,
                  username: this.username,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  phone_number_code: this.phone_number_code,
                  password: this.password,
                  confirm_password: this.confirm_password
                })

                this.loading3 = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                this.$router.push('/login')

                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                return;
               
            } catch (err) {
              console.log(err);
        }
    },

    validatePassword() {
    // Get the password and confirm_password values
    var password = document.getElementById("password").value;
    var confirm_password = document.getElementById("confirm_password").value;

    // Define regular expressions for each requirement
    var digitRegex = /\d/;
    var letterRegex = /[a-zA-Z]/;
    var specialCharRegex = /[@#$%^&*!]/;

    // Check if the passwords match
    if (password !== confirm_password) {
        document.getElementById("password-feedback").style.color = "red";
        document.getElementById("password-feedback").textContent = "Passwords do not match!";
        return;
    }

    // Check each requirement individually
    var missingRequirements = [];
    if (!password.match(digitRegex)) {
        missingRequirements.push("a digit");
    }
    if (!password.match(letterRegex)) {
        missingRequirements.push("a letter");
    }
    if (!password.match(specialCharRegex)) {
        missingRequirements.push("a special character (@#$%^&*!)");
    }

    // Provide feedback based on the missing requirements
    if (missingRequirements.length > 0) {
        var errorMessage = "Password is not strong. Missing: " + missingRequirements.join(', ');
        document.getElementById("password-feedback").style.color = "red";
        document.getElementById("password-feedback").textContent = errorMessage;
        // You can further customize the error message or take other actions as needed
    } else {
        document.getElementById("password-feedback").style.color = "green";
        document.getElementById("password-feedback").textContent = "Password is strong and matches!";
        // You can proceed with the signup process here
    }
    },

    startCountdown(hours, minutes) {
            this.totalSeconds = (hours * 3600) + (minutes * 60);
            this.updateTimer();
    },
    updateTimer() {
        if (this.totalSeconds > 0) {
            this.totalSeconds--;
            this.timer = setTimeout(this.updateTimer, 1000);
        }
    },
    stopTimer() {
        if (this.totalSeconds > 0) {
            this.totalSeconds = 0;
        }
    },
    stopCountdown(hours, minutes) {
            this.totalSeconds = (hours * 3600) + (minutes * 60);
            this.stopTimer();
    },
    checkInput() {
      this.tokenValid = this.validateFiveDigitInput(this.validate_token);
    },
    validateFiveDigitInput(input) {
      // Regular expression to match exactly five digits
      var regex = /^\d{5}$/;

      // Test the input against the regular expression
      return regex.test(input);
    },

  }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

.terms-conditions{
    padding: 0.3rem;
    text-align: center;
    span{
        color: #800080;
    }
    a {
        text-decoration: none;
        font-size: 10px;
    }
}
.p-viewer, .p-viewer2{
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    cursor:pointer;
}
.backgound{
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    background-image: linear-gradient(to right,#cccccc8a, #8000800a, #cccccc8a);
}
  
    .container{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        width: 100%;
    }
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        font-size: 12px;
        color: #fff;
        font-family: Mulish;

        .logo{
            img{
                height: 50px;
                width: auto;
            }
        }

        a{
            color: #800080;
            text-decoration: none;
            font-weight: 500;
            font-size: 13px;
            padding-right: 20px;
        }
        .login{
            font-weight: 500;
            color: #000 !important;
        }
        .account{
            font-size: 12px;
            color: #000;
            font-family: Mulish;
        }
    }
    .login-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .get-started{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 20px;
        font-family: Mulish;
        font-size: 30px;
        font-weight: 600;
        word-wrap: break-word;
        text-align: center;
        color: #fff;
        text-transform: capitalize;
    }
    .app-title{
        display: flex;
        flex-direction: column;
        font-size: 30px;
        color: #fff;
    

        h2{
            font-family: Mulish;
            font-size: 30px;
            font-weight: bold;
            color: white;
            text-shadow: 2px 2px 4px #000000;
            text-transform: uppercase;
            text-align: center;
        }
        p{
            font-family: Mulish;
            font-size: 14px;
            color: white;
            text-shadow: 2px 2px 4px #000000;
            text-transform: capitalize;
            text-align: center;
        }
    }
    .login-form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .login-form-inner{
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        padding: 2rem;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: Mulish;
        font-size: 13px;
        font-weight: 500;
        color: #000 !important;
    }

    .counter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        padding: 4px;
        height: 30px;
        border-radius: 3px;

        span{
            color: #000;
        }
        .step-title{
            font-weight: 500;
            font-size: 12px !important;
        }
    }
    .inputs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        border-radius: 3px;
        font-size:11px;
        font-weight: 400;

       div{
        margin: 2px;
        .inputs-field{
            font-size: 12px;
            font-family: Mulish;
            height: 40px;
        }
       }
    }
    .btn-inputs{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-top: 10px;
        border-radius: 3px;
    }
    
    .btn-danger{
        text-transform: uppercase;
        font-size: 13px;
        font-family: Mulish;
        background: #800080;
        border: 0px;
        height: 40px;
    }
    .inputs-field{
        font-size: 14px;
        font-family: Mulish;
        height: 50px;
        border: #800080 solid 1px;
    }
    .footer{
        display: flex;
        flex-direction: column;
    }
    .copyright{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: Mulish;
        font-size: 13px;
        color: #171616ec;
        background: #cccccc3b;
        padding: 0.3rem;
        width: 100%;
        //margin-top: 40px;
        bottom: 0;
        position: absolute;
        flex-shrink: 0;
    }
    .login{
        font-weight: 800;
        color: #fff !important;
        font-size: 15px

    }

    .timer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        padding: 0.4rem;
        margin-top: -10px;
        margin-bottom: -10px;
        font-size: 20px;
       
    }

    @media screen and (max-width: 768px) {

        .backgound{
            font-family: Mulish !important;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 0px;
            background-image: linear-gradient(to right,#cccccc8a, #cccccc8a, #cccccc8a);
        }
        .container{
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            width: 100%;
    
            .logo{
                img{
                    height: 35px;
                    width: auto;
                }
            }
        }
        .login{
            font-weight: 800;
            text-align: right;
            color: #000 !important;
        }
        .account{
            display: none;
        }
        .get-started{
            font-size: 14px;
        }
        .login-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .app-title{
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            padding: 0rem;
            text-align: center;
            h2{
                font-size: 14px;
                
            }
            p{
                display: none;
            }
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        .login-form-inner{
            width: 100%;
        }
    }
</style>