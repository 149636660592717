<template>
    <div class="backgound">
        <div class="container">
            <div class="header">
                <div class="logo"><img src="logoz.png" alt="Logo"></div>
                <div class="login"></div>
            </div>
            <div class="login-container">
                <div class="app-title">
                    buy & pay small small
                </div>
                <div class="login-form">
                    <p class="text-white text-uppercase">Create Password</p>
                    <div class="login-form-inner">
                        <div class="inputs"> 
                            <label for="">Password *</label>
                            <input type="password" class="form-control inputs-field" placeholder="Enter password" v-model="password">
                            <label for="">Confirm Password *</label>
                            <input type="password" class="form-control inputs-field" placeholder="Confirm password" v-model="cpassword">
                        </div>
                        
                        <div class="inputs">
                            <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled" @click="resetPassword()" v-if="!loading">Create</button>
                            <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        
                    </div>
                    <div class="footer">
                        <div class="copyright">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import Vue from 'vue'
    import VueCountryCode from "vue-country-code";
    import swal from 'sweetalert2';
    
    Vue.use(VueCountryCode);
    
    export default {
        
        name: 'CreatePassword',
        components: {
    
        },
        
        data() {
        return {
          appTitle: "Daily Champ",
         
          submit_4: false,
          password: "",
          cpassword: "",
          loading: false,
        };
      },
    
      beforeMount(){
        document.title = this.appTitle
        this.token = this.$route.params.token
      },
      mounted() {
       
      },
      created(){
        
      },
      computed: {
        isSubmitButtonDisabled() {
        return (
          this.password === '' ||
          this.cpassword === ''
        );
      }
      },
    
      methods:{
    
        async resetPassword() {

        if (!this.password) {

            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 4,
                text: 'Please enter your password',
            })
            return;
        }
        if (!this.cpassword) {

            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 4,
                text: 'Please confirm password',
            })
            return;
        }
        if (this.password != this.cpassword) {

            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 4,
                text: 'Password does not match!',
            })
            return;
        }

        try {

            this.loading = true;

            this.response = await this.$http.post('/reset-password', {
                token: this.token,
                password: this.password,
                cpassword: this.cpassword,
            });

            this.loading = false;

            if (!this.response.data.success) {

                swal.fire({
                    icon: "error",
                    title: "Error",
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                });

                return;

            } else {

                swal.fire({
                    icon: "success",
                    title: "Success",
                    width: 450,
                    height: 5,
                    text: this.response.data.message,
                });

                this.$router.push('/login')
                return;
            }

        } catch (err) {
            console.log(err);
        }


        },
    
      }
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
        .backgound{
            height: 100vh;
            width: 100%;
            background: #8000808b;
            background-position: center;
            background-size: cover;
            background-repeat: none;
        }
      
        .container{
            display: flex;
            flex-direction: column;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        .login-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding: 10px;
            text-transform:uppercase;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            font-family: Mulish;
    
            .logo{
                img{
                    height: 50px;
                    width: auto;
                }
            }
    
            a{
                color: #504e4e;
            }
        }
        .get-started{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            font-family: Mulish;
            font-size: 25px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            color: #fff;
        }
        .app-title{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content: center;
            font-size: 40px;
            font-weight: bold;
            text-align: right;
            color: #fff;
            padding: 4rem;
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 100%;
        }
    
        .forgot-password{
            a {
                color: #800080;
                text-decoration: none;
            }
        }
        .login-form-inner{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            font-family: Mulish;
            font-size: 13px;
            font-weight: 500;
            color: #000 !important;
            width: 100%;
        }
    
        .counter{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 4px;
            height: 30px;
            border-radius: 3px;
    
            span{
                color: #000;
            }
            .step-title{
                font-weight: 700;
            }
        }
        .inputs{
            margin-top: 20px;
            border-radius: 3px;
        }
        .inputs-field{
            font-size: 14px;
            font-family: Mulish;
            height: 50px;
            border: #800080 solid 1px;
        }
        .btn-danger{
            text-transform: uppercase;
            font-size: 13px;
            font-family: Mulish;
            background: #800080;
            border: 0px;
            height: 45px;
        }
        .footer{
            display: flex;
            flex-direction: column;
        }
        .copyright{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            font-size: 13px;
            color: #fff;
            //width: 100%;
    
            span{
                a {
                    color: #800080;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            .backgound{
                //background-image: linear-gradient(to bottom, #071278, rgba(237, 221, 221, 0.044));
                font-family: Mulish !important;
                background: #8000808b;
                height: 100vh;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-bottom: 0px;
            }
            .container{
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
            }
            .header{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
        
                .logo{
                    img{
                        height: 50px;
                        width: auto;
                    }
                }
            }
            .login-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .app-title{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                padding: 0rem;
                text-align: center;
            }
            .login-form{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
            .login-form-inner{
                width: 100%;
            }
    
            .get-started{
                font-size: 20px;
            }
    
    
        }
    </style>